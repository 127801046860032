import CheckedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Box, Checkbox, FormControlLabel, FormGroup, styled } from '@mui/material';
import React from 'react';
import { EColors, getColor } from 'theme';
import { Body2, Caption } from '../Typography/Typography';

const Grid = styled(Box)(({ theme: { spacing } }) => ({
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'auto',
    gap: spacing(1.25),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme: { getColor, EColors, spacing } }) => ({
    padding: 0,
    marginLeft: 0,
    '& .MuiCheckbox-root': {
        padding: 0,
        marginRight: spacing(1),
    },
    '& .MuiSvgIcon-root': {
        fontSize: spacing(2.25),
    },
    '& > .MuiFormControlLabel-label': {
        fontSize: '14px',
        color: getColor(EColors.pureBlack),
    },
}));

type InputFieldProps = {
    options: { value: number; label: string }[];
    label: string;
    placeholder?: string;
    error: string | undefined;
    onChange: (values: number[]) => void;
    onBlur?: () => void;
    values: number[];
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
};

const CheckboxGroup = ({ label, error = '', values, required = false, options, onChange }: InputFieldProps) => {
    const onChangeCheckbox = (value: number, isSelected: boolean) => {
        let newValues = values;
        if (isSelected) {
            newValues = values.filter(v => v !== value);
        } else {
            newValues = [...values, value];
        }
        onChange(newValues);
    };
    return (
        <Box>
            <Box display="flex" gap={0.5} marginBottom={1}>
                <Body2 fontWeight={500}>{label}</Body2>
                {required && (
                    <Body2 color={EColors.bizlyOSPrimary} fontWeight={500}>
                        *
                    </Body2>
                )}
            </Box>
            <FormGroup>
                <Grid>
                    {options.map(option => {
                        const isSelected = values?.includes(option.value);
                        return (
                            <StyledFormControlLabel
                                key={option.value}
                                control={
                                    <Checkbox
                                        checkedIcon={<CheckedIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />}
                                        name={option.label}
                                    />
                                }
                                checked={isSelected}
                                label={option.label}
                                onChange={() => onChangeCheckbox(option.value, isSelected)}
                            />
                        );
                    })}
                </Grid>
            </FormGroup>
            {!!error && (
                <Caption color="red" fontWeight={500} marginBottom={1} size="large">
                    {error}
                </Caption>
            )}
        </Box>
    );
};

export default CheckboxGroup;
