import HowToPopper from 'components/HowToPopper/HowToPopper';
import { SpinnerOverlay } from 'components/Spinner';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { PageHeadline } from 'components/ui/Headline';
import VenueProposalRenderer from 'components/VenueProposalRenderer';
import EmptyPageImage from 'images/empty-pages-assets/empty_contracts.png';
import { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { i18n } from 'translation';
import { Row } from 'ui';
import { hasContract } from 'utils/venue';

const Contracting = ({ viewVenueListing }: { viewVenueListing: (venueId: number) => void }) => {
    const { venues, loading, loaded } = useCurrentInquiry();

    const proposalId = useMemo(() => {
        if (loaded && venues.length > 0) {
            const acceptedVenue = venues.find(venue => hasContract(venue));
            return acceptedVenue?.proposalId;
        }
    }, [loaded, venues]);

    if (loading) {
        return <SpinnerOverlay />;
    }

    const renderCommonVenueProps = {
        proposalId: String(proposalId),
        viewVenueListing,
    };

    return (
        <main>
            {proposalId ? (
                <Routes>
                    <Route
                        path={'messages'}
                        element={<VenueProposalRenderer {...renderCommonVenueProps} withMessagesOpen />}
                    />
                    <Route path="*" element={<VenueProposalRenderer {...renderCommonVenueProps} />} />
                </Routes>
            ) : (
                <>
                    <Row alignItems="center">
                        <PageHeadline withDescription>{i18n.venue.proposal.contracting}</PageHeadline>
                        <HowToPopper sectionId="contracting" />
                    </Row>
                    <Spacer largest />
                    <EmptyVisual
                        image={<img src={EmptyPageImage} alt="Empty Contracts" />}
                        title={i18n.venue.proposal.emptyContractTitle}
                        description={i18n.venue.proposal.emptyContractDescription}
                    />
                </>
            )}
        </main>
    );
};

export default Contracting;
