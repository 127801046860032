import { Dialog, DialogContent, TextField as MuiTextField, Snackbar, styled } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import { H2Headline } from 'components/ui/Headline';
import { PageDescription } from 'pages/BuildInquiry';
import React, { useState } from 'react';
import { i18n } from 'translation';
import { Column, Row } from 'ui';
import WebsiteModalContent from './WebsiteModalContent';

const WideDialogContent = styled(DialogContent)({
    width: '30rem',
    padding: '1.5rem',
});

const MenuActionButtons = styled(Button, {
    shouldForwardProp: prop => prop !== 'cancelButton',
})<{ cancelButton?: boolean }>(({ theme: { getColor, EColors }, cancelButton }) => ({
    flex: 1,
    ...(cancelButton && {
        border: `1px solid ${getColor(EColors.lighterGrey)}`,
        color: getColor(EColors.darkGrey),
    }),
    '&.MuiButton-outlinedPrimary': {
        border: `0.0625rem solid ${getColor(EColors.lighterGrey)}`,
        backgroundColor: getColor(EColors.pureWhite),
        color: getColor(EColors.darkGrey),
        '&:hover': {
            backgroundColor: getColor(EColors.lighterGrey),
            color: getColor(EColors.darkerGrey),
            border: `0.0625rem solid ${getColor(EColors.lighterGrey)}`,
        },
    },
    '&.MuiButton-containedPrimary': {
        marginRight: 0,
    },
}));

export type ModalContentType = {
    modalTitle: string;
    description?: string;
    onClose: () => void;
    handleSubmit: (text: string) => void;
};

const ModalContent = ({ modalTitle, description = '', onClose, handleSubmit }: ModalContentType) => {
    const [title, setTitle] = useState('');

    return (
        <Column itemSpacing="small">
            <H2Headline>{modalTitle}</H2Headline>
            <PageDescription large>{description}</PageDescription>
            <MuiTextField
                fullWidth
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Example: Senior Leadership Invite"
                variant="outlined"
            />
            <Row itemSpacing="smallish" style={{ marginTop: '1rem' }}>
                <MenuActionButtons variant="outlined" onClick={onClose} cancelButton>
                    {i18n.button.back}
                </MenuActionButtons>
                <MenuActionButtons onClick={() => handleSubmit(title)} disabled={!title.trim()}>
                    {i18n.button.continue}
                </MenuActionButtons>
            </Row>
        </Column>
    );
};

interface TitleInputModalProps {
    type: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (title: string) => void;
}

const TitleInputModal: React.FC<TitleInputModalProps> = ({ type, open, onClose, onSubmit }) => {
    const [error, setError] = useState<string | null>(null);

    const handleCloseSnackbar = () => {
        setError(null);
    };

    const handleSubmit = (title: string) => {
        if (title.trim()) {
            onSubmit(title);
            onClose();
        } else {
            setError('Title cannot be empty.');
        }
    };

    const getModalContent = () => {
        switch (type) {
            case 'invite':
                return (
                    <ModalContent
                        modalTitle="Add Invite Title"
                        description="Give your invite a title"
                        onClose={onClose}
                        handleSubmit={handleSubmit}
                    />
                );
            case 'registration_page':
                return (
                    <WebsiteModalContent
                        modalTitle="Create Event Website"
                        description="Give your registration a title"
                        onClose={onClose}
                        handleSubmit={handleSubmit}
                    />
                );
            case 'note':
                return (
                    <ModalContent
                        modalTitle="Add Survey/Note Title"
                        description="Give your survey/note a title"
                        onClose={onClose}
                        handleSubmit={handleSubmit}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <WideDialogContent>{getModalContent()}</WideDialogContent>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar} message={error} />
        </Dialog>
    );
};

export default TitleInputModal;
