import Box from '@mui/material/Box';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { getEventPlannerData, getPlace } from 'api';
import { Button } from 'components/Ui-V2/Button/Button';
import { PageHeadline } from 'components/Ui-V2/Headline/Headline';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import VenueDetailDialog from 'components/VenueDetailDialog';
import InfiniteVenues from 'components/VenueSearch/InfiniteVenues';
import PaginatedVenues from 'components/VenueSearch/PaginatedVenues';
import { parseEventSearchParams } from 'components/VenueSearch/SearchUtils';
import VenueSearchField from 'components/VenueSearch/VenueSearchField';
import { TFilterValue } from 'components/VenueSearch/VenueSearchFilters';
import FiltersPanel from 'components/VenueSearch/VenueSearchFiltersRow';
import { useVenueDialog } from 'hooks/useVenueDialog';
import { useManageUrlParams } from 'hooks/venueSearchHooks';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';
import { hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { LoadPlaybookOptions } from 'stores/playbooks';
import { i18n } from 'translation';
import { miToKm } from 'utils';
import { fixedForwardRef } from 'utils/fixForwardRef';
import { Copy, Line } from '../ui';

const SearchHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 2em;
    margin-bottom: 1em;
`;

const StyledVenueSearchField = styled(VenueSearchField)`
    flex: 0 0 auto;
    justify-content: center;
`;

const StyledDrawerButton = styled(Button)`
    &.MuiButton-root {
        height: 36.5px;
        padding: 0.5em 1em;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }
    &.MuiButton-outlinedPrimary {
        text-transform: none;
    }
`;

type TVenueSearchPage<THideActions extends boolean = false> = {
    event?: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    setShowRightDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
    setHasInquiryData?: React.Dispatch<React.SetStateAction<boolean>>;
    hideActions: THideActions;
} & (true extends THideActions
    ? {
          hideActions: true;
          selectedVenues?: { [venueId: number]: Bizly.Venue };
      }
    : {
          hideActions: false;
          onSelect?: (venue: Bizly.Venue) => void;
          onDeselect?: (venue: Bizly.Venue) => void;
          selectedVenues: { [venueId: number]: Bizly.Venue };
      });

const VenueSearchPage = <THideActions extends boolean = false>(props: TVenueSearchPage<THideActions>) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const isInitParamsLoaded = useRef<boolean>(false);

    const { event, viewVenueListing, setHasInquiryData, setShowRightDrawer, ...restProps } = props;

    const { openVenueDialog, handleClose } = useVenueDialog();

    const eventSearchParams = useMemo(() => (event ? parseEventSearchParams(event) : undefined), [event]);
    const brandRestrictions = useMemo(
        () =>
            event?.venueSearchParameters &&
            event.venueSearchParameters.isLocked &&
            event.venueSearchParameters.brandIds &&
            event.venueSearchParameters.brandIds.length > 0
                ? new Set(event.venueSearchParameters.brandIds)
                : undefined,
        [event]
    );

    const { filters, showMap, latLng, setFilters, setPlaceQuery, setMapVisibility, updateUrl } =
        useManageUrlParams(event);

    const noFilterParams = useMemo(() => {
        const validEntries = Object.entries(filters).filter(entry => !!entry[1]);
        return validEntries.length === 0 || isEqual(Object.fromEntries(validEntries), { radius: 15, radiusKm: 25 });
    }, [filters]);

    const searchFilters: TFilterValue = useMemo(
        () => ({
            ...filters,
            ...(brandRestrictions ? { brandIds: eventSearchParams?.brandIds } : {}),
            // to be clear, these are the default search params:
            dinovaOnly: filters.dinovaOnly ?? false,
            radius: filters.radius ?? (15 as Distance.Mile),
            radiusKm: filters.radiusKm ?? miToKm(15 as Distance.Mile),
        }),
        [filters, brandRestrictions, eventSearchParams?.brandIds]
    );

    const handleSearch = useCallback(
        async (locationName: string, placeId: string) => {
            setSearchQuery(locationName);
            setPlaceQuery(locationName, placeId);
        },
        [setSearchQuery, setPlaceQuery]
    );

    useEffect(() => {
        async function loadPlanner() {
            if (event) {
                const { planner = {} } = await getEventPlannerData(event.id);
                if (
                    (planner.eventSpaces && planner.eventSpaces.length > 0) ||
                    (planner.accommodations && planner.accommodations.length > 0)
                ) {
                    setHasInquiryData?.(true);
                } else {
                    setHasInquiryData?.(false);
                }
            }
        }
        loadPlanner();
    }, [event, setHasInquiryData]);

    const { venues: inquiryVenues } = useCurrentInquiry();
    const toggleViewAddedListingsFlag = useFeatureFlagEnabled(PostHogFeatureFlags.toggleViewAddedListings);
    const venuePaginationFlag = useFeatureFlagEnabled(PostHogFeatureFlags.venuePagination);
    const hasAnAcceptedProposal = hasAcceptedProposal(inquiryVenues ?? []);

    const onToggleMap = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setMapVisibility(e.target.checked);
        },
        [setMapVisibility]
    );

    useEffect(() => {
        async function setInitialFilters() {
            if (event && eventSearchParams) {
                const { isLocked, ...rest } = eventSearchParams;
                if (isLocked || (!isInitParamsLoaded.current && noFilterParams)) {
                    isInitParamsLoaded.current = true;
                    if (event.city?.name && event.googlePlaceId) {
                        // get lat, lng to get the desired result
                        const res = await getPlace(event.googlePlaceId);
                        const loc = res.result?.geometry.location;
                        const placeQuery = { q: event.city.name, place_id: event.googlePlaceId, ...loc };
                        updateUrl({ ...rest }, placeQuery, showMap);
                    } else {
                        setFilters({ ...rest });
                    }
                }
            }
        }
        setInitialFilters();
    }, [event, noFilterParams, eventSearchParams, showMap, updateUrl, setFilters]);

    const getSelectedVenuesText = (): string => {
        if (hasAnAcceptedProposal) {
            return 'View 1 selected venue';
        }
        const selectedCount = restProps.selectedVenues ? Object.keys(restProps.selectedVenues).length : 0;
        return `View ${selectedCount} selected venue${selectedCount !== 1 ? 's' : ''}`;
    };

    return (
        <>
            <SearchHeader>
                <PageHeadline withDescription>{i18n.venues.title}</PageHeadline>
                {/* Hide for now but will bring back later */}
                {/* <VenueTypeFilter filters={searchFilters.types} onChange={onVenueTypesUpdate} /> */}
                {!restProps.hideActions && toggleViewAddedListingsFlag && (
                    <StyledDrawerButton
                        onClick={() => setShowRightDrawer?.((show: boolean) => !show)}
                        variant="outlined"
                    >
                        {getSelectedVenuesText()}
                    </StyledDrawerButton>
                )}
            </SearchHeader>
            <Box sx={{ width: '100%' }}>
                <LoadPlaybookOptions />
                <Box>
                    <Box display="flex" sx={{ mb: 2 }}>
                        <StyledVenueSearchField onSearch={handleSearch} value={searchQuery} disabled={false} />
                    </Box>
                    <FiltersPanel
                        event={event}
                        filters={searchFilters}
                        setFilters={setFilters}
                        showMap={showMap}
                        onToggleMap={onToggleMap}
                        latLng={latLng}
                    />
                    <Box>
                        <Line />
                    </Box>
                    {event?.venueSearchParameters?.isLocked && (
                        <>
                            <Spacer xsmall />
                            <Copy small faded>
                                {i18n.venue.lockedFiltersCopy}
                            </Copy>
                        </>
                    )}
                    {!venuePaginationFlag ? (
                        <InfiniteVenues
                            event={event}
                            viewVenueListing={viewVenueListing}
                            searchFilters={searchFilters}
                            latLng={latLng}
                            showMap={showMap}
                            {...restProps}
                        />
                    ) : (
                        <PaginatedVenues
                            event={event}
                            viewVenueListing={viewVenueListing}
                            searchFilters={searchFilters}
                            latLng={latLng}
                            showMap={showMap}
                            {...restProps}
                        />
                    )}
                </Box>
            </Box>

            <VenueDetailDialog
                setShowRightDrawer={setShowRightDrawer}
                open={openVenueDialog}
                handleClose={handleClose}
            />
        </>
    );
};

const VenueSearch = fixedForwardRef(VenueSearchPage);

export default VenueSearch;
