import GalleryArrowIcon from 'images/icons/gallery_arrow.svg?react';
import Dots from 'material-ui-dots';
import { useCallback, useMemo, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled, { css, keyframes } from 'styled-components';
import { Column } from 'ui';

const fadeIn = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

const GalleryImage = styled('div')<{ src: string }>`
    width: auto;
    height: 100%;
    flex-grow: 1;
    background-image: url(${props => props.src || 'none'});
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${fadeIn} 0.05s ease-in;
`;

const ArrowCSS = css`
    position: absolute;
    height: 3rem;
    width: 3rem;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    cursor: pointer;
`;
const BackArrow = styled(GalleryArrowIcon)`
    ${ArrowCSS};
    left: 36px;
`;
const ForwardArrow = styled(GalleryArrowIcon)`
    ${ArrowCSS};
    transform: rotateZ(180deg);
    right: 36px;
`;

const ProgressDotsClassNames = {
    dots: 'dots',
    dot: 'dot',
    dotOuter: 'dotOuter',
};

const SwipeableViewsStyles = {
    style: {
        width: '100%',
        minWidth: 1200,
        height: 'calc(100vh - 400px)',
        minHeight: '600px',
    },
    containerStyle: {
        width: '100%',
        minWidth: 1200,
        height: 'calc(100vh - 400px)',
        minHeight: '600px',
        lineHeight: 0,
    },
    slideStyle: {
        width: '100%',
        minWidth: 1200,
        height: 'calc(100vh - 400px)',
        minHeight: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
};

type TGalleryProps = {
    images: Bizly.VenueImage[];
    startingIndex?: number;
    onChangeIndex?: (idx: number) => void;
    pageOffset?: number;
};

// Dots normally are 8px*8px, to match designs we want 6px*6px and 4px*4px, these styles adjust for that
const ProgressDots = styled(Dots)`
    position: absolute;
    bottom: 2rem;
    transform: translateY(-100%);

    .${ProgressDotsClassNames.dots} {
        padding: 0;
    }

    .${ProgressDotsClassNames.dot} {
        margin-top: 0 !important;
        margin-left: -2px;
        transform: translateY(25%) scale(0.75);
    }

    .${ProgressDotsClassNames.dotOuter} {
        .${ProgressDotsClassNames.dot} {
            height: 4px;
            width: 4px;
            margin: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};

            transform: unset;
        }
    }
`;

export const SwipeableImageGallery = ({ images, startingIndex = 0 }: TGalleryProps) => {
    const imagesCount = useMemo(() => (images || []).length, [images]);
    const [imgIndex, setImgIndex] = useState(startingIndex);
    const prevImg = useCallback(
        () => setImgIndex(imgIndex => (imgIndex - 1 + imagesCount) % imagesCount),
        [imagesCount]
    );
    const nextImg = useCallback(
        () => setImgIndex(imgIndex => (imgIndex + 1 + imagesCount) % imagesCount),
        [imagesCount]
    );
    const onChangeIndex = useCallback((idx: number) => setImgIndex(idx), []);

    return (
        <Column alignItems={'center'} justifyContent={'center'}>
            <SwipeableViews enableMouseEvents {...SwipeableViewsStyles} index={imgIndex} onChangeIndex={onChangeIndex}>
                {(images || []).map(i => (
                    <GalleryImage key={i.id} src={i.srcUrl} />
                ))}
            </SwipeableViews>
            {imagesCount > 1 && (
                <>
                    <ProgressDots
                        index={imgIndex}
                        count={imagesCount}
                        onDotClick={(i: number) => setImgIndex(i)}
                        classes={ProgressDotsClassNames}
                    />
                    <BackArrow onClick={prevImg} />
                    <ForwardArrow onClick={nextImg} />
                </>
            )}
        </Column>
    );
};
