import { Box, Grid, Link, Skeleton, lighten } from '@mui/material';
import {
    exportProposals,
    getEventPlannerData,
    getInquiry,
    getVenueInquiries,
    getVenueProposal,
    nudge,
    serializeDay,
} from 'api';
import CompareModal from 'components/CompareModal/CompareModal';
import { EventHeaderIconsFill } from 'components/EventHeader';
import InquiryDetailsModal from 'components/InquiryDetailsModal';
import ProposalAcceptModal from 'components/ProposalAcceptModal';
import ProposalRejectModal from 'components/ProposalRejectModal';
import ThrustCarbonScore from 'components/ThrustCarbonScore';
import { Button } from 'components/Ui-V2/Button/Button';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { Tab, TabContainer } from 'components/Ui-V2/TabGroup/TabGroup';
import VenueCancelModal from 'components/VenueCancelModal';
import { BookedState, REJECTED_STATUSES } from 'components/VenueCard';
import InquiryVenueTile from 'components/VenueTile/InquiryVenueTile';
import { VenueTileAdd } from 'components/VenueTile/VenueTile';
import { CANCELLED_OR_EXPIRED_STATUS, PROPOSAL_INQUIRY_STATUS, TabLabels, VenueStatus } from 'constants/venueStatus';
import EmptyProposals from 'images/empty-pages-assets/empty_proposals.svg';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { useSnackbar } from 'notistack';
import { PageDescription } from 'pages/BuildInquiry';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { EventContext } from 'providers/event';
import { useUser } from 'providers/user';
import QueryString from 'qs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostHogFeatureFlags } from 'shared';
import { NEW_INQUIRY_ID, currentInquiryActions, hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { useMeasurementUnits } from 'stores/measurement-units';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, Spacer } from 'ui';
import { MAX_INQUIRY_VENUE_SLOTS } from 'utils/venue';
import { downloadFromUrl, padArray } from '../../util';
import { ProposalSummary, hasProposal, proposalActive, proposalExpired } from './ProposalSummary';
import { inquiryToSchedule } from './utils';

const INQUIRY_VENUE_TILE_FIXED_RATIO = 'calc(172 / 266 * 100%)';
const INQUIRY_EMPTY_VENUE_TILE_FIXED_RATIO = 'calc(172 / 266 * 100% + 115px)'; // An additional 115px is added to the fixed image ratio to accommodate the button height when empty tile

const GridContainer = styled(Grid)`
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
`;

const ProposalContainer = styled(Column)<{ padding?: boolean }>`
    width: 100%;
    ${({ padding }) => padding && `padding-bottom: 20px;`}
`;

const StyledLinkButton = styled(Link)`
    text-transform: none;
    background-color: ${({ theme }) => theme.getColor(theme.EColors.primaryAction)};
    color: ${({ theme }) => theme.getColor(theme.EColors.pureWhite)};
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => lighten(theme.getColor(theme.EColors.primaryAction), 0.1)};
    }
`;

const StyledGridItem = styled(Grid)`
    & .MuiSkeleton-root {
        width: 100%;
        height: 238px;
    }
`;

const StyledTabs = styled(TabContainer)`
    margin-bottom: 32px;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.getColor(theme.EColors.lightGrey)};
`;

const VenueInquiry = ({
    viewProposal,
    submitKey,
    setVenueBooked,
    readonly,
}: {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    submitKey: number;
    setVenueBooked: (booked: boolean) => void;
    readonly: boolean;
}) => {
    const toggleNewCompareProposals = useFeatureFlagEnabled(PostHogFeatureFlags.toggleNewCompareProposals);

    const { event, setCurrency } = useContext(EventContext);
    const { loading: inquiryLoading, inquiry, venues: inquiryVenues } = useCurrentInquiry();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const { user } = useUser();
    const { area: areaUnit } = useMeasurementUnits();
    const [inquiredVenues, setInquiredVenues] = useState<Bizly.Venue[]>([]);
    const onUpdateVenues = (venues: Bizly.Venue[]) => {
        setInquiredVenues(venues);
        currentInquiryActions.mergeVenues(event.id, venues);
    };
    const [acceptModalOpen, setAcceptModalOpen] = useState<Bizly.VenueProposal | undefined>();
    const [rejectModalOpen, setRejectModalOpen] = useState<number | undefined>();
    const [openCompareModal, setOpenCompareModal] = useState(false);

    const [inquiryDetails, setInquiryDetails] = useState<
        | {
              schedule: BizlyAPI.ScheduleFragment[];
              submittedAt?: string;
              notes?: string;
              datesFlexible?: boolean;
              rejectedAt?: string;
              rejectionReason?: string;
              rejectionNotes?: string;
              venueId: number;
              venueInquiryId: number;
              status: Bizly.Venue['status'];
          }
        | undefined
    >();
    const [showInquiryDetails, setShowInquiryDetails] = useState(false);
    const [venueDetails, setVenueDetails] = useState<{
        id: number;
        inquiryId: number;
    }>();

    const [venueProposals, setVenueProposals] = useState<{
        [id: number]: Bizly.VenueProposal;
    }>({});

    const [compareVenues, setCompareVenues] = useState(false);

    const [hasInquiryData, setHasInquiryData] = useState(false);
    const [loadingInquiryData, setLoadingInquiryData] = useState(false);

    const [activeTab, setActiveTab] = useState<TabLabels>(TabLabels.ACTIVE);

    const handleEditInquiry = useCallback(() => {
        if (inquiryLoading) {
            return;
        } else {
            navigate(`/event/${event.id}/venue/inquiries/${inquiry?.id || NEW_INQUIRY_ID}`);
        }
    }, [inquiry, inquiryLoading, navigate, event]);

    const [loadingProposals, setLoadingProposals] = useState(false);

    useEffect(() => {
        let isMounted = true;

        async function loadPlanner() {
            setLoadingInquiryData(true);
            const { planner = {} } = await getEventPlannerData(event.id);
            if (isMounted) {
                if (
                    (planner.eventSpaces && planner.eventSpaces.length > 0) ||
                    (planner.accommodations && planner.accommodations.length > 0)
                ) {
                    setHasInquiryData(true);
                } else {
                    setHasInquiryData(false);
                }
                setLoadingInquiryData(false);
            }
        }

        loadPlanner();

        return () => {
            isMounted = false;
        };
    }, [event]);

    useEffect(() => {
        let isMounted = true;

        const loadVenueInquiries = async () => {
            setLoadingProposals(true);

            const venues = await getVenueInquiries(event.id);
            const proposalsPromises = venues
                .filter(venue => proposalActive(venue))
                .map(venue => venue.proposalId && getVenueProposal(venue.proposalId));

            const proposals = await Promise.all(proposalsPromises);

            if (isMounted) {
                setInquiredVenues(venues);
                getProposals(venues);
                setVenueProposals(keyBy(proposals, 'id'));
                if (venues.filter(venueInquiry => proposalActive(venueInquiry) && venueInquiry.proposalId).length > 0) {
                    setCompareVenues(true);
                }
                setLoadingProposals(false);
            }
        };

        function getProposals(venues: Bizly.Venue[]) {
            const proposalsPromises = venues
                .filter(venue => proposalActive(venue))
                .map(venue => venue.proposalId && getVenueProposal(venue.proposalId));

            Promise.all(proposalsPromises).then(responses => {
                if (isMounted) {
                    const proposals = responses.map(response => response.proposal);
                    setVenueProposals(keyBy(proposals, 'id'));
                }
            });
        }

        loadVenueInquiries();

        return () => {
            isMounted = false;
        };
    }, [event.id, submitKey]);

    const venueProposalsEmpty = isEmpty(venueProposals);
    const hasInquiredVenues = inquiredVenues.length > 0;
    const hasAtLeastOneProposalSentInquiry =
        inquiredVenues.filter(venue => PROPOSAL_INQUIRY_STATUS.has(venue.status)).length > 0;
    const hasAtLeastOneCancelledOrExpired =
        inquiredVenues.filter(venue => CANCELLED_OR_EXPIRED_STATUS.has(venue.status)).length > 0;

    function getProposalsSubmitted() {
        return inquiredVenues.filter(i => proposalActive(i) && i.proposalId);
    }

    const setInquiryDetailsFromVenue = async (venue: Bizly.Venue) => {
        if (!venue.inquiryId) return;
        const { inquiry } = await getInquiry(venue.inquiryId!);
        const venueInquiry = inquiry.venues.find(v => v.id === venue.id);

        setInquiryDetails({
            schedule: inquiry.scheduleFragment?.map(day => serializeDay(day)) ?? inquiryToSchedule(inquiry),
            submittedAt: inquiry.submittedAt,
            notes: inquiry.notes,
            datesFlexible: inquiry.flexibleDates,
            rejectedAt: venueInquiry?.rejectedAt || undefined,
            rejectionReason: venueInquiry?.rejectionReason || undefined,
            rejectionNotes: venueInquiry?.rejectionNotes || undefined,
            venueId: venue.id,
            venueInquiryId: venue.inquiryId,
            status: venue.status,
        });
    };

    const viewInquiry = useCallback(async (venue: Bizly.Venue) => {
        if (!venue.inquiryId) return;
        setShowInquiryDetails(true);
        await setInquiryDetailsFromVenue(venue);
    }, []);

    useEffect(() => {
        const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });
        if (hasInquiredVenues && query.open_inquiry) {
            const venue = inquiredVenues.find(v => v.id === Number(query.open_inquiry));
            if (venue) {
                viewInquiry(venue);
            }
        }
    }, [hasInquiredVenues, inquiredVenues, location.search, viewInquiry]);

    const validVenues = inquiredVenues.filter(venue => !REJECTED_STATUSES.has(venue.status));
    const availableSlots = Math.max(0, MAX_INQUIRY_VENUE_SLOTS - validVenues.length);
    const isBooked = validVenues && hasAcceptedProposal(validVenues);

    const getFilteredVenues = () => {
        const filterAndPadVenues = (status: Set<BookedState>) => {
            const filteredVenues = validVenues.filter(venue => status.has(venue.status));
            const length = filteredVenues.length;
            const paddedLength = length + availableSlots;

            return padArray(filteredVenues, paddedLength);
        };

        const sortVenues = (venues: Bizly.Venue[]) => {
            return venues.sort(
                (a, b) =>
                    (b.status === VenueStatus.ProposalReceived ? 1 : 0) -
                    (a.status === VenueStatus.ProposalReceived ? 1 : 0)
            );
        };

        switch (activeTab) {
            case TabLabels.ACTIVE: {
                const activeVenues = isBooked
                    ? validVenues.filter(venue => PROPOSAL_INQUIRY_STATUS.has(venue.status))
                    : filterAndPadVenues(PROPOSAL_INQUIRY_STATUS);

                return sortVenues(activeVenues as Bizly.Venue[]);
            }
            case TabLabels.INACTIVE: {
                return inquiredVenues.filter(venue => CANCELLED_OR_EXPIRED_STATUS.has(venue.status));
            }
            default: {
                return [];
            }
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedFilteredVenues = useMemo(() => getFilteredVenues(), [activeTab, inquiredVenues, venueProposals]);

    const onNudge = async (venueId: Nullable<number>) => {
        try {
            const venue = inquiry?.venues.find(v => v.id === venueId);
            if (inquiry?.id && venue?.venueInquiryId) {
                const response = await nudge(inquiry.id, venue.venueInquiryId);
                if (response.success) {
                    enqueueSnackbar(i18n.venue.inquiry.nudgeSuccess, {
                        variant: 'success',
                    });
                    currentInquiryActions.setInquiry(event.id, response.inquiry);
                }
            }
        } catch (e) {
            enqueueSnackbar(i18n.venue.inquiry.error.nudge, {
                variant: 'error',
            });
        }
    };

    const onCancelInquiry = (venueId: number, venueInquiryId: number) => {
        handleCloseDetailsModal();
        setVenueDetails({
            id: venueId,
            inquiryId: venueInquiryId,
        });
    };

    const directCancelInquiry = (venue: Bizly.Venue) => {
        handleCloseDetailsModal();
        setInquiryDetailsFromVenue(venue);
        setVenueDetails({
            id: venue.id,
            inquiryId: venue.inquiryId!,
        });
    };

    const getInquiryButton = (venue: Bizly.Venue) => {
        if (hasProposal(venue)) {
            return {
                label: i18n.venue.inquiry.viewProposal,
                handler: () => {
                    viewProposal(venue);
                },
            };
        }

        if (!readonly && proposalActive(venue)) {
            return {
                label: proposalExpired(venue) ? i18n.venue.inquiry.requestExtension : i18n.button.accept,
                handler: () => {
                    setAcceptModalOpen(venueProposals[venue.proposalId!]);
                },
            };
        }
    };

    const handleCloseDetailsModal = () => {
        setShowInquiryDetails(false);
        setInquiryDetails(undefined);
    };

    async function handleExportClick() {
        downloadFromUrl(await exportProposals(event.id));
    }

    const renderSkeletonItems = () => (
        <>
            {Array.from({ length: 4 }).map((_, index) => (
                <StyledGridItem item xs={3} key={index}>
                    <Skeleton />
                </StyledGridItem>
            ))}
        </>
    );

    if (loadingProposals) {
        return (
            <>
                <Box sx={{ pt: 0.5 }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                </Box>
                <Box>
                    <GridContainer container spacing={2}>
                        {renderSkeletonItems()}
                    </GridContainer>
                </Box>
            </>
        );
    }

    const tabConfigs = [{ label: TabLabels.ACTIVE }, { label: TabLabels.INACTIVE }];

    const handleTabChange = (_: React.SyntheticEvent, newValue: TabLabels) => {
        setActiveTab(newValue);
    };

    const renderProposalTabs = () => (
        <StyledTabs value={activeTab} onChange={handleTabChange}>
            {tabConfigs.map(({ label }) => {
                return <Tab key={label} label={label} value={label} />;
            })}
        </StyledTabs>
    );

    const renderCarbonScore = (venue: Bizly.Venue) => {
        return (
            !toggleNewCompareProposals &&
            compareVenues &&
            hasProposal(venue) &&
            venue.proposalId &&
            venueProposals[venue.proposalId]?.emissionReport?.tons !== undefined && (
                <ThrustCarbonScore score={venueProposals[venue.proposalId]?.emissionReport?.tons} />
            )
        );
    };

    const shouldDisplayProposalSummary = (venue: Bizly.Venue) => {
        return (
            compareVenues &&
            !venueProposalsEmpty &&
            proposalActive(venue) &&
            venue.proposalId &&
            venueProposals[venue.proposalId] !== undefined &&
            !toggleNewCompareProposals
        );
    };

    const ActiveEmptyState = () => (
        <EmptyVisual
            image={<img src={EmptyProposals} alt="No Inquiries" />}
            title="No Inquiries Or Proposals Yet"
            description="Now that you have added all 4 venues, build your inquiry."
            actionButton={
                <StyledLinkButton href={`/event/${event.id}/venue/inquiries/${NEW_INQUIRY_ID}`}>
                    Build Inquiry
                </StyledLinkButton>
            }
        />
    );

    const InactiveEmptyState = () => (
        <EmptyVisual
            image={<img src={EmptyProposals} alt="No Inactive Inquiries" />}
            title="No Inactive Inquiries or Proposals"
            description="You currently have no cancelled or rejected venue inquiries or proposals."
        />
    );

    const renderEmptyState = () => {
        const venueCount = validVenues.length;
        const addedInquiredVenuesCount = inquiredVenues.filter(venue => venue.status === VenueStatus.Added).length;

        if (venueCount < 4) {
            return (
                <>
                    <PageDescription>{i18n.venue.inquiry.noActiveVenueInquiries}</PageDescription>
                    <Spacer medium />
                </>
            );
        }

        if (addedInquiredVenuesCount === 4) {
            return <ActiveEmptyState />;
        }
    };

    const handleCompareButtonClick = () => {
        if (toggleNewCompareProposals) {
            setOpenCompareModal(true);
        } else {
            setCompareVenues(!compareVenues);
        }
    };

    return (
        <>
            <EventHeaderIconsFill>
                {inquiredVenues.some(venue => hasProposal(venue)) && (
                    <Button variant="text" onClick={handleExportClick}>
                        {i18n.venue.inquiry.export}
                    </Button>
                )}
                {getProposalsSubmitted().length > 1 && (
                    <Button variant="outlined" onClick={handleCompareButtonClick}>
                        {i18n.venue.inquiry.compareProposals}
                    </Button>
                )}
                {!loadingInquiryData && !venueProposalsEmpty && (
                    <Button onClick={handleEditInquiry} sx={{ marginLeft: '10px' }}>
                        {hasInquiryData ? i18n.venue.inquiry.reviewInquiry : i18n.venue.inquiry.buildInquiry}
                    </Button>
                )}
            </EventHeaderIconsFill>
            <main>
                {hasInquiredVenues ? (
                    <PageDescription large>{i18n.venue.inquiry.inquirySubheading}</PageDescription>
                ) : (
                    <PageDescription large>{i18n.venue.inquiry.listingSubHeading}</PageDescription>
                )}
                <Spacer medium />

                {renderProposalTabs()}
                {activeTab === TabLabels.INACTIVE && !hasAtLeastOneCancelledOrExpired && <InactiveEmptyState />}
                {activeTab === TabLabels.ACTIVE && !hasAtLeastOneProposalSentInquiry && renderEmptyState()}

                <GridContainer container spacing={2}>
                    {memoizedFilteredVenues?.map((venue: Bizly.Venue | undefined, idx: number) => {
                        const inquiryVenue = inquiry?.venues.find((v: BizlyAPI.Inquiry.Venue) => v.id === venue?.id);

                        return venue ? (
                            <Grid item xs={3} key={`${venue.id} - ${idx}`}>
                                <ProposalContainer padding={compareVenues}>
                                    <InquiryVenueTile
                                        tabLabel={activeTab}
                                        key={venue.id}
                                        venue={{
                                            ...venue.venue,
                                            status:
                                                venue.booking && venue.status === 'Inquiry Cancelled'
                                                    ? 'Booking Cancelled'
                                                    : venue.status,
                                        }}
                                        format="tile"
                                        pillType="inquiryStatus"
                                        width="100%"
                                        fixedRatio={INQUIRY_VENUE_TILE_FIXED_RATIO}
                                        padding="0 0 24px 0"
                                        onClick={hasProposal(venue) ? () => viewProposal(venue) : undefined}
                                        onNudge={() => onNudge(venue.id)}
                                        inquiryVenue={
                                            inquiryVenue
                                                ? { ...inquiryVenue, submittedAt: inquiry?.submittedAt }
                                                : undefined
                                        }
                                        viewInquiry={() => viewInquiry(venue)}
                                        inquiryButton={getInquiryButton(venue)}
                                        directCancelInquiry={() => directCancelInquiry(venue)}
                                        inquiryDetails={inquiryDetails}
                                    />
                                    {renderCarbonScore(venue)}
                                    {shouldDisplayProposalSummary(venue) && (
                                        <ProposalSummary
                                            proposal={venueProposals[venue.proposalId!]}
                                            onAccept={() => setAcceptModalOpen(venueProposals[venue.proposalId!])}
                                            onReject={() => setRejectModalOpen(venue.proposalId!)}
                                            readonly={readonly}
                                            sizeUnit={areaUnit}
                                            expired={proposalExpired(venue)}
                                            eventBudget={event?.budget?.toString()}
                                            hideActionButtons
                                        />
                                    )}
                                </ProposalContainer>
                            </Grid>
                        ) : (
                            <>
                                {validVenues.length < 4 && (
                                    <Grid item xs={3} key={idx}>
                                        <VenueTileAdd
                                            width="100%"
                                            fixedRatio={INQUIRY_EMPTY_VENUE_TILE_FIXED_RATIO}
                                            noBorder
                                            eventId={event.id.toString()}
                                        />
                                    </Grid>
                                )}
                            </>
                        );
                    })}
                </GridContainer>
            </main>

            {acceptModalOpen && (
                <ProposalAcceptModal
                    open
                    closeModal={() => setAcceptModalOpen(undefined)}
                    eventId={event.id}
                    proposal={acceptModalOpen}
                    setVenueBooked={booked => {
                        acceptModalOpen && setCurrency(acceptModalOpen.currency);
                        setVenueBooked(booked);
                    }}
                />
            )}
            <ProposalRejectModal
                open={!!rejectModalOpen}
                closeModal={() => setRejectModalOpen(undefined)}
                eventId={event.id}
                proposalId={rejectModalOpen}
            />

            <VenueCancelModal
                open={!!venueDetails}
                closeModal={() => setVenueDetails(undefined)}
                venueDetails={venueDetails}
                inquiredVenues={inquiredVenues}
                onCancelled={onUpdateVenues}
            />
            {showInquiryDetails && (
                <InquiryDetailsModal
                    open
                    closeModal={handleCloseDetailsModal}
                    inquiryDetails={inquiryDetails}
                    onCancelInquiry={onCancelInquiry}
                    hideActions
                />
            )}
            {openCompareModal && inquiryVenues && user && (
                <CompareModal
                    user={user}
                    eventBudget={event?.budget?.toString()}
                    inquiryVenues={inquiryVenues}
                    areaUnit={areaUnit}
                    venueProposals={venueProposals}
                    open={openCompareModal}
                    closeModal={() => {
                        setOpenCompareModal(false);
                    }}
                />
            )}
        </>
    );
};

export default VenueInquiry;
