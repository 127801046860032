import { TSpacerNames } from 'ui';
import Form from './Form';

export default function SingleFieldForm<T>({
    field,
    spacing = false,
    value,
    onChange,
    readonly,
    disabled,
}: {
    field: any;
    spacing?: TSpacerNames | false;
    value?: T;
    onChange: (update: T) => void;
    readonly?: boolean;
    disabled?: boolean;
}) {
    const fieldName = 'SingleFormField';
    type FieldName = typeof fieldName;

    return (
        <Form
            fields={{
                [fieldName]: field,
            }}
            schema={[{ fields: [fieldName], spacing }]}
            value={{ [fieldName]: value }}
            onChange={({ value }: { value: { [key in FieldName]: T } }) => {
                onChange(value[fieldName]);
            }}
            readonly={readonly}
            disabled={disabled}
        />
    );
}
