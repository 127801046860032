/* eslint-disable react-refresh/only-export-components */
import { useState } from 'react';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { isBlankTemplateId } from 'api/eventTemplates';
import { EventSettingsFields } from 'components/Events/EventSettingsHeaderSection';
import Form from 'components/Form';
import { getPassthroughProps } from 'components/Form/utils';
import PlaceInput from 'components/PlaceInput';
import { Spinner } from 'components/Spinner';
import Button from 'components/ui/Button';
import { Moment } from 'moment';
import { tzMoment } from 'utils/moment';
import { Link, useLocation } from 'react-router-dom';
import { getFontStyle, EFonts } from 'theme';
import { i18n } from 'translation';
import { RichTextCopy } from 'ui';

export type City = {
    location: Bizly.Event['location'];
    googlePlaceId: Bizly.Event['googlePlaceId'];
};

type TCityField = {
    field: string;
    defaultValue: City;
    error: object | null;
    onChange: ({ value, field, error }: { value: City; field: string; error: object | null }) => void;
};

export const CityField = ({ field, onChange: onChangeProp, defaultValue }: TCityField) => {
    const { search } = useLocation();
    const { location, googlePlaceId } = Object.fromEntries(new URLSearchParams(search));

    return (
        <PlaceInput
            asFormField
            inModalOrPopover
            onChange={(location: string, googlePlaceId: string) =>
                onChangeProp({
                    field,
                    error: {},
                    value: {
                        location,
                        googlePlaceId,
                    },
                })
            }
            defaultValue={defaultValue?.location || location}
            defaultValueId={defaultValue?.googlePlaceId || googlePlaceId}
        />
    );
};

export type EventDates = {
    start?: Bizly.Event['startsAt'];
    end?: Bizly.Event['endsAt'];
};

type TEventDatesField = {
    value: EventDates;
    onChange: (value: EventDates) => void;
};

export const EventDatesField = ({ value, onChange: onChangeProp }: TEventDatesField) => {
    const { search } = useLocation();
    const { start, end } = Object.fromEntries(new URLSearchParams(search));

    const [hasTouchedStartDate, setHasTouchedStartDate] = useState(false);
    const [hasTouchedEndDate, setHasTouchedEndDate] = useState(false);

    const currentDate = tzMoment();

    const getInitialDate = (dateString: string | undefined) => {
        return dateString ? tzMoment(dateString) : null;
    };

    const handleDateChange = (type: 'start' | 'end', newValue: Moment | string | null) => {
        const newValueObj = { ...value, [type]: newValue };
        if (type === 'start') {
            setHasTouchedStartDate(true);
        } else {
            setHasTouchedEndDate(true);
        }
        onChangeProp(newValueObj);
    };

    const initialStartDate = value?.start || getInitialDate(start);
    const initialEndDate = value?.end || getInitialDate(end);

    const validateDate = (date: Moment | null, isInteracted: boolean) => {
        const isValid = date && date.isAfter(currentDate, 'minute');
        const errorMsg = !isValid
            ? "Date cannot be in the past."
            : "";
        return { isValid, errorMsg, showError: isInteracted && !isValid };
    };

    const { errorMsg: startErrorMsg, showError: showStartError } = validateDate(initialStartDate, hasTouchedStartDate);
    const { errorMsg: endErrorMsg, showError: showEndError } = validateDate(initialEndDate, hasTouchedEndDate);

    return (
        <Box display="flex" gap={3}>
            <Box>
                <DateTimePicker
                    label="Start Date and Time"
                    value={initialStartDate}
                    onChange={(newValue) => handleDateChange('start', newValue)}
                    disablePast
                    onOpen={() => setHasTouchedStartDate(true)}
                    slotProps={{
                        textField: {
                            InputProps: {
                                style: { ...getFontStyle(EFonts.body) },
                            },
                            error: showStartError,
                            helperText: showStartError ? startErrorMsg : "",
                        },
                    }}
                />
            </Box>
            <Box>
                <DateTimePicker
                    label="End Date and Time"
                    value={initialEndDate}
                    onChange={(newValue) => handleDateChange('end', newValue)}
                    disablePast
                    onOpen={() => setHasTouchedEndDate(true)}
                    slotProps={{
                        textField: {
                            InputProps: {
                                style: { ...getFontStyle(EFonts.body) },
                            },
                            error: showEndError,
                            helperText: showEndError ? endErrorMsg : "",
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export type EventForm = Partial<
    {
        city: City;
        start: string;
        end: string;
        recordId: string;
        templateId: string | number;
        cventEventId: string | number;
    } & Pick<Bizly.Event, 'name' | 'cventId' | 'location' | 'type' | 'costCenter' | 'budget' | 'format' | 'department'>
>;

type TemplateOption = Omit<Bizly.EventTemplate, 'name'> & { name?: JSX.Element | string };
export type CventOption = BizlyAPI.CventEvent & { id: string | number; name?: JSX.Element | string };

export const AugmentedTemplatesField = ({
    field,
    value,
    onChange,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: object[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            options: {
                options,
            },
        },
    };

    const ViewPlaybookField = {
        type: 'display',
        options: {
            label: (
                <Link to={`/playbooks/${value}`}>
                    <Button
                        onClick={() => {
                            onViewTemplate && onViewTemplate(value);
                        }}
                        width={130}
                    >
                        {i18n.homepage.createMeetingModal.viewPlaybook}
                    </Button>
                </Link>
            ),
        },
    };

    const schema = [
        {
            fields: [field, ...(value && !isBlankTemplateId(value) ? [ViewPlaybookField] : [])],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return options.length ? (
        <>
            <Form
                fields={fields}
                schema={schema}
                value={{ ...valueProp, playbookDescription }}
                onChange={onChangeProp}
            />
            {playbookDescription && <RichTextCopy dangerouslySetInnerHTML={{ __html: playbookDescription || '' }} />}
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

const CventSelectField = ({
    field,
    value,
    onChange,
    options,
    loadingCventEvents,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: object[];
    loadingCventEvents: boolean;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            options: {
                options,
            },
        },
    };
    const schema = [
        {
            fields: [field],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return !loadingCventEvents ? (
        <>
            <Form fields={fields} schema={schema} value={{ ...valueProp }} onChange={onChangeProp} />
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

export type EventCreateFieldProps = {
    meetingInternalReferenceFieldLabel?: string;
    meetingCostCenterFieldLabel?: string;
    disabledFields?: string[];
    requiredFields?: Bizly.EventCreateInternalFields[];
    templates?: TemplateOption[];
    cvents?: CventOption[];
    city?: City | null;
    eventDates?: EventDates | null;
    recordId?: string;
    playbookDescription?: string;
    budgetRequired?: boolean;
    loadingCventEvents: boolean;
    onViewTemplate: () => void;
    costCenterOptions?: Nullable<string[]>;
    departmentOptions?: Nullable<string[]>;
};

export const EventCreateFields = ({
    meetingInternalReferenceFieldLabel,
    meetingCostCenterFieldLabel,
    disabledFields,
    requiredFields,
    templates,
    cvents,
    city,
    eventDates,
    recordId,
    playbookDescription,
    budgetRequired,
    loadingCventEvents,
    onViewTemplate,
    costCenterOptions,
    departmentOptions,
}: EventCreateFieldProps) => ({
    ...EventSettingsFields({
        internalReferencePrompt: meetingInternalReferenceFieldLabel,
        meetingCostCenterFieldLabel,
        disabledFields,
        requiredFields,
        costCenterOptions,
        departmentOptions,
    }),
    name: {
        prompt: i18n.homepage.createMeetingModal.eventName,
        type: 'text',
        disabled: disabledFields?.includes('name'),
        options: {
            autoFocus: true,
        },
    },
    city: {
        prompt: i18n.homepage.createMeetingModal.location,
        type: CityField,
        disabled: disabledFields?.includes('city'),
        ...(city && {
            options: {
                defaultValue: city,
            },
        }),
    },
    budget: {
        prompt: i18n.homepage.createMeetingModal.budget,
        type: 'text',
        optional: !budgetRequired,
        disabled: disabledFields?.includes('budget'),
    },
    templateId: {
        prompt: i18n.homepage.createMeetingModal.playbook,
        type: AugmentedTemplatesField,
        disabled: disabledFields?.includes('templateId'),
        options: {
            options: templates,
            playbookDescription: playbookDescription,
            onViewTemplate,
        },
        optional: true,
    },
    cventEventId: {
        prompt: i18n.homepage.createMeetingModal.cventEvent,
        type: CventSelectField,
        disabled: disabledFields?.includes('cventEventId'),
        options: {
            options: cvents,
            loadingCventEvents: loadingCventEvents,
        },
        optional: true,
    },
    format: {
        prompt: i18n.meetingsPage.meetingFormat,
        type: 'radioselect',
        options: {
            options: [i18n.meetingsPage.inPerson, i18n.meetingsPage.hybrid, i18n.meetingsPage.virtual],
        },
        optional: true,
        perRow: '2/3',
    },
    recordId: {
        prompt: i18n.homepage.createMeetingModal.recordId,
        type: 'text',
        disabled: true,
        ...(recordId && {
            options: {
                defaultValue: recordId,
            },
        }),
        optional: false,
    },
    eventDates: {
        prompt: i18n.communication.eventDates,
        type: EventDatesField,
        disabled: disabledFields?.includes('eventDates'),
        ...(eventDates && {
            options: {
                defaultValue: eventDates,
            },
        }),
    },
});

export const CreateAppleEventStep1FormSchema = [
    {
        key: 'templateId',
        fields: ['templateId'],
        spacing: 'smallish',
    },
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'smallish',
    },
    {
        key: 'city',
        fields: ['city'],
        spacing: 'smallish',
    },
    {
        key: 'eventDates',
        fields: ['eventDates'],
        spacing: 'smallish',
    },
];

export const CreateAppleEventStep2FormSchema = [
    {
        key: 'cventId',
        fields: ['cventId', 'recordId'],
        spacing: 'smallish',
    },
    {
        key: 'costCenter',
        fields: ['costCenter', 'department'],
        spacing: 'smallish',
    },
    {
        key: 'meetingType',
        fields: ['budget', 'type'],
        spacing: 'smallish',
    },
];

export const EventCreateSchema = (hiddenFields: Bizly.EventCreateInternalFields[] = [], includeCventEvents = false) => [
    ...(includeCventEvents
        ? [
              {
                  key: 'cventEventId',
                  fields: ['cventEventId'],
                  spacing: 'smallish',
              },
          ]
        : []),
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'smallish',
    },
    {
        key: 'city',
        fields: ['city'],
        spacing: 'smallish',
    },
    {
        key: 'budgetAndCostCenter',
        fields: ['budget', 'costCenter'].filter(field => !(hiddenFields as string[]).includes(field)),
        spacing: 'smallish',
    },
    ...(hiddenFields.includes('cventId')
        ? []
        : [
              {
                  key: 'cventId',
                  fields: ['cventId', 'department'],
                  spacing: 'smallish',
              },
          ]),
    ...(hiddenFields.includes('type')
        ? []
        : [
              {
                  key: 'type',
                  fields: ['type'],
                  spacing: 'smallish',
              },
          ]),
];

export const EventCreateSchemaWithTemplate = (
    hiddenFields?: Bizly.EventCreateInternalFields[],
    includeCventEvents = false
) => [
    {
        key: 'templateId',
        fields: ['templateId'],
        spacing: 'smallish',
    },
    ...EventCreateSchema(hiddenFields, includeCventEvents),
];

export const toBizlyEvent = ({ name, cventId, budget, type, costCenter, city, templateId, department, start, end, recordId }: EventForm) => {
    return {
        name,
        type,
        cventId,
        budget,
        costCenter,
        templateId,
        department,
        ...city,
        startsAt: start,
        endsAt: end,
        metadata: {
            recordId: recordId,
        },
    };
};

export const validateForm = (
    { name, cventId, type, costCenter, city, budget, department }: EventForm,
    budgetRequired = false,
    requiredFields: Bizly.EventCreateInternalFields[] = []
) => {
    if (!name?.trim()) return false;

    if (requiredFields.includes('cventId') && !cventId?.toString().trim()) return false;

    if (requiredFields.includes('department') && !department?.toString().trim()) return false;

    if (requiredFields.includes('type') && !type) return false;

    if (requiredFields.includes('costCenter') && !costCenter?.trim()) return false;

    if (!city?.location || !city?.googlePlaceId) return false;

    return !(budgetRequired && !String(budget ?? '').trim());
};

export type EventCreateForm = Partial<{
    eventName: string;
    eventDescription: string;
    requirements: string[];
    eventDate: {
        start?: Moment | null;
        end?: Moment | null;
    };
    guests: number;
    location: {
        location: string;
        googlePlaceId?: string;
    } | null;
    payment: {
        mode: string;
        cost: number;
    };
    costCenter: string;
    cventId: string;
    department: string;
    meetingType: string;
}>;

export type EventCreateFormErrors = Partial<Record<keyof EventCreateForm, boolean>>;
