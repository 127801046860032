import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, TextField, Tooltip } from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState, AutocompleteValue } from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withIconStyles } from 'shared';
import styled from 'styled-components';

import { findPlaces, getVenueByGooglePlaceId } from 'api';
import { QueryParamEnum } from 'constants/queryParams';
import BuildingSVG from 'images/icons/building-icon.svg?react';
import LocationPinSvg from 'images/icons/location_pin.svg?react';
import { i18n } from 'translation';
import { Copy } from 'ui';
import CheckMarkSvg from '../../../images/icons/check-mark.svg?react';
import { LoadingButton } from '../Button/Button';

const LocationPinIcon = styled(withIconStyles(LocationPinSvg))`
    min-width: 21px;
    max-width: 21px;
    padding-right: 1px;
`;

const BuildingIcon = styled(withIconStyles(BuildingSVG))`
    width: 16px;
    height: 16px;
`;

const CheckMarkIcon = styled(CheckMarkSvg)`
    margin-left: 12px;
`;

const SuggestionText = styled(Copy).attrs({ regular: true })`
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`;

type TOption = {
    description: string;
    placeId: string;
    noIcon?: boolean;
    types?: string[];
};

type TPlaceInput<TInputAsSuggestion extends boolean | undefined> = {
    locationBias?: Bizly.Location;
    clearable?: boolean;
    onRemove?: () => void;
    directVenueLinking?: boolean;
    locationValue?: string;
    placeId?: string;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
} & (TInputAsSuggestion extends true
    ? {
          inputAsSuggestion: true;
          onChange: (city: string, googlePlaceId?: string | undefined) => void;
      }
    : {
          inputAsSuggestion?: false | undefined;
          onChange: (city: string, googlePlaceId: string) => void;
      });

const GooglePlacesAutocomplete = (props: TPlaceInput<true> | TPlaceInput<false>) => {
    const {
        locationBias,
        onChange,
        clearable,
        onRemove,
        directVenueLinking,
        inputAsSuggestion,
        locationValue,
        placeId,
        disabled,
        placeholder,
        required,
    } = props;

    const navigate = useNavigate();

    const [inputQuery, setInputQuery] = useState<string>(locationValue ?? '');
    const [options, setOptions] = useState<TOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [noOptionsText, setNoOptionsText] = useState(i18n.venue.noLocationsForQuery(inputQuery ?? ''));
    const [loadingVenuePlaceId, setLoadingVenuePlaceId] = useState('');
    const [lastValidLocation, setLastValidLocation] = useState<string | null>(locationValue || null);

    const inputOption = useMemo(
        () => ({
            description: locationValue ?? '',
            placeId: placeId ?? '',
            noIcon: true,
            types: undefined,
        }),
        [locationValue, placeId]
    );

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };

    // Debounce the API call to avoid excessive requests
    const debouncedFetch = useMemo(
        () =>
            debounce(async (query: string) => {
                setNoOptionsText(i18n.venue.noLocationsForQuery(query || ''));
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findPlaces(query, locationBias?.lat, locationBias?.lng);
                        if (response.status === 'OK') {
                            setOptions([...response.predictions]);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        [locationBias]
    );

    useEffect(() => {
        // Call the debounced function whenever the input value changes
        debouncedFetch(inputQuery);

        // Cleanup function to cancel any pending debounced calls on component unmount
        return () => {
            debouncedFetch.cancel();
        };
    }, [inputQuery, debouncedFetch]);

    const handleSelection = (city: string, placeId: string) => {
        setInputQuery(city);
        setLastValidLocation(city);
        onChange?.(city, placeId);
    };

    function handleClear() {
        resetOptions();
        if (clearable) onRemove?.();
    }

    const onInputChange = (_: React.SyntheticEvent, newInputQuery: string) => {
        setInputQuery(newInputQuery);
        if (!newInputQuery) {
            handleClear();
        }
    };

    const onSelectionChange = (e: React.SyntheticEvent, val: AutocompleteValue<TOption, false, false, true>) => {
        if (!e || !val || typeof val === 'string') {
            handleClear();
            return;
        }
        handleSelection(val.description, val.placeId);
    };

    async function openVenueDetailDialog(option: TOption) {
        try {
            setLoadingVenuePlaceId(option.placeId);
            const result = await getVenueByGooglePlaceId(option.placeId);
            if (result.success) {
                const params = new URLSearchParams(location.search);
                params.set(QueryParamEnum.VENUE_ID, result.venue.id.toString());
                navigate({
                    pathname: location.pathname,
                    search: params.toString(),
                });
            }
        } catch (error) {
            console.error('Error fetching venue details:', error);
        } finally {
            setLoadingVenuePlaceId('');
        }
    }

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TOption,
        state: AutocompleteRenderOptionState
    ) => {
        const hasEstablishmentType = directVenueLinking && option?.types?.includes('establishment');
        return (
            <li {...props} key={option.placeId}>
                <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
                    <Box display="flex" alignItems="center" flex={1}>
                        {!option.noIcon && <LocationPinIcon />}
                        {<SuggestionText>{option.description}</SuggestionText>}
                        {hasEstablishmentType && (
                            <Tooltip title={i18n.venue.goToVenuePage} placement="top" arrow>
                                <Box>
                                    <LoadingButton
                                        variant="contained"
                                        onClick={e => {
                                            e.stopPropagation();
                                            openVenueDetailDialog(option);
                                        }}
                                        loading={loadingVenuePlaceId === option.placeId}
                                        loadingPosition="center"
                                    >
                                        <BuildingIcon />
                                    </LoadingButton>
                                </Box>
                            </Tooltip>
                        )}
                    </Box>
                    {state.selected && <CheckMarkIcon />}
                </Box>
            </li>
        );
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (options.length > 0) {
                setLastValidLocation(options[0].description);
                setInputQuery(options[0].description);
                onChange?.(options[0].description, options[0].placeId);
            } else if (inputQuery && inputQuery !== lastValidLocation) {
                setInputQuery(lastValidLocation ?? locationValue ?? '');
                onChange?.(lastValidLocation ?? locationValue ?? '', placeId || '');
            }
        }
    };

    return (
        <Autocomplete
            freeSolo
            disabled={disabled}
            loading={loading}
            disableClearable={!clearable}
            options={options.length > 0 ? [...(inputAsSuggestion ? [inputOption] : []), ...options] : []}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            renderOption={renderOption}
            noOptionsText={noOptionsText}
            inputValue={inputQuery}
            onInputChange={onInputChange}
            value={inputOption}
            filterOptions={x => x}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder ?? `${i18n.homepage.createMeetingModal.find} (required)`}
                    variant="outlined"
                    fullWidth
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onKeyDown={handleKeyDown}
                />
            )}
            onChange={onSelectionChange}
        />
    );
};

export default GooglePlacesAutocomplete;
