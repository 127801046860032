import { Box, Divider, styled, Typography } from '@mui/material';
import { EColors } from 'theme';
type ResultData = { icon: React.ReactNode; text: string };

type SearchCriteriaListProps = {
    resultsData?: ResultData[];
};

const StyledListContainer = styled(Box)({
    display: 'flex',
    gap: '8px',
});

const StyledIconText = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '0.2rem',
});

const StyledIcon = styled(Box)(({ theme }) => ({
    color: theme.getColor(EColors.primaryAction),
    display: 'flex',
    alignItems: 'center',
}));

const StyledText = styled(Typography)({
    fontSize: '13px',
});

export default function SearchCriteriaList(props: SearchCriteriaListProps) {
    const { resultsData = [] } = props;

    return (
        <StyledListContainer>
            {resultsData?.map((result, index) => (
                <>
                    <Divider orientation="vertical" flexItem />
                    <StyledIconText key={index}>
                        <StyledIcon>{result.icon}</StyledIcon>
                        <StyledText>{result.text}</StyledText>
                    </StyledIconText>
                </>
            ))}
        </StyledListContainer>
    );
}
