import VenueProposal from 'components/VenueProposal';
import VenueProposalV2 from 'components/VenueProposalV2';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { PostHogFeatureFlags } from 'shared';

type VenueProposalRendererProps = {
    proposalId: string | undefined;
    viewVenueListing: (venueId: number) => void;
    setVenueBooked?: ((booked: boolean) => void) | undefined;
    venueBooked?: boolean | undefined;
    withMessagesOpen?: boolean;
};

const VenueProposalRenderer = (props: VenueProposalRendererProps) => {
    const toggleRedesignedProposalPage = useFeatureFlagEnabled(PostHogFeatureFlags.toggleRedesignedProposalPage);
    const ProposalComponent = toggleRedesignedProposalPage ? VenueProposalV2 : VenueProposal;

    return <ProposalComponent {...props} />;
};

export default VenueProposalRenderer;
