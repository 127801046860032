import { Dialog, styled } from '@mui/material';
import { VenueCardProps } from 'pages/Workspaces/VenueSearch';

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        maxWidth: '1360px',
        minHeight: '80vh',
    },
});

type VenueListingModalProps = {
    open: boolean;
    onClose: () => void;
    venue: VenueCardProps | null;
};

export default function VenueListingModal(props: VenueListingModalProps) {
    const { open, onClose, venue } = props;

    if (!venue) return null;

    return (
        <StyledDialog fullWidth open={open} onClose={onClose}>
            IWG{venue.address}
        </StyledDialog>
    );
}
