import { Box, styled, Typography } from '@mui/material';
import { StyledFooterDetails } from './CompareModalStyledComponents';
import { getTotalDisplay } from './compareUtils';

type CompareOnrivaTotalsProps = {
    proposalColumns?: Bizly.VenueProposal[];
};

const StyledFooterHeader = styled(Box)`
    & {
        display: grid;
        grid-template-columns: 5fr repeat(4, 4fr);
        padding: 10px;
        padding-bottom: 5px;
    }
`;

const StyledFooterDetailContainer = styled(Box)`
    & {
        padding-block: 10px;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
        border-radius: 5px;
    }
`;

function CompareOnrivaTotals({ proposalColumns }: CompareOnrivaTotalsProps) {
    const isOnriva = true;

    return (
        <>
            <StyledFooterHeader>
                <Typography fontSize={14} fontWeight={500}>
                    Subtotal
                </Typography>
                {proposalColumns?.map(proposal => {
                    const { subtotal } = getTotalDisplay(proposal, isOnriva);
                    return (
                        <Typography fontWeight={600} fontSize={14} key={proposal.id}>
                            {subtotal}
                        </Typography>
                    );
                })}
            </StyledFooterHeader>
            <StyledFooterDetailContainer>
                <StyledFooterDetails>
                    <Typography fontSize={14} fontWeight={500}>
                        Processing Fee
                    </Typography>
                    {proposalColumns?.map(proposal => {
                        const { platformFee } = getTotalDisplay(proposal, isOnriva);
                        return (
                            <Typography fontWeight={600} fontSize={14} key={proposal.id}>
                                {platformFee}
                            </Typography>
                        );
                    })}
                </StyledFooterDetails>
            </StyledFooterDetailContainer>
        </>
    );
}

export default CompareOnrivaTotals;
