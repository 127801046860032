import { Notifications } from '@mui/icons-material';
import { Badge, Box, IconButton, styled } from '@mui/material';
import { EColors, getColor } from 'theme';

const NotificationsContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBlock: '1rem',
});

const StyledIconButtonWrapper = styled(Box)(({ theme: { getColor, EColors } }) => ({
    border: `1px solid ${getColor(EColors.grey, 0.8)}`,
    borderRadius: '8px',
    width: '46px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        backgroundColor: getColor(EColors.vividFuchsia),
        border: `5px solid ${getColor(EColors.vividFuchsia)}`,
        borderRadius: '50%',
    },
});

const StyledNotifications = styled(Notifications)({
    '& path': {
        fill: getColor(EColors.grey),
        stroke: getColor(EColors.black),
    },
});

export default function NotificationsHeader() {
    return (
        <NotificationsContainer>
            <StyledIconButtonWrapper>
                <IconButton sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
                    <StyledBadge overlap="circular" variant="dot" badgeContent=" ">
                        <StyledNotifications />
                    </StyledBadge>
                </IconButton>
            </StyledIconButtonWrapper>
        </NotificationsContainer>
    );
}
