import { zodResolver } from '@hookform/resolvers/zod';
import InputField from 'components/BizlyOS/InputFields/InputField';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import StepItemsContainer from 'components/BizlyOS/ListingsClaim/StepItemsContainer';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { ListingsCreateStepProps } from './ListingsCreate';

const Schema = z.object({
    venueWebsite: z
        .string()
        .min(1, { message: 'URL is required' })
        .regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}.*$/, 'Please enter a valid URL'),
});

type Data = z.infer<typeof Schema>;

export default function VenueWebsite(props: ListingsCreateStepProps) {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm<Data>({
        resolver: zodResolver(Schema),
        defaultValues: {
            venueWebsite: props.listingsCreateData.venueWebsite || '',
        },
        mode: 'onBlur',
    });

    const onSubmit = (data: Data) => {
        props.onNextStepClicked(data);
    };

    return (
        <ClaimStepContainer
            {...props}
            onSave={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
            contentCentered
        >
            <StepItemsContainer
                title="What's your venue's website?"
                description="We use this information to confirm your online presence as part of the review process. "
            >
                <Controller
                    name="venueWebsite"
                    control={control}
                    render={({ field }) => {
                        return (
                            <InputField
                                {...field}
                                error={errors.venueWebsite}
                                label="Venue Website"
                                required
                                placeholder="e.g., example.com or https://example.com"
                            />
                        );
                    }}
                />
            </StepItemsContainer>
        </ClaimStepContainer>
    );
}
