import { Box, styled, Typography } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import SearchCriteriaList from './SearchCriteriaList';

const ResultsHeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

const ResultsHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});

const StyledTitle = styled(Typography)({
    marginRight: '1.5rem',
});
0.5;

type ResultData = { icon: React.ReactNode; text: string };

type SearchResultsHeaderProps = {
    onEditSearchClick: () => void;
    resultsData?: ResultData[];
};

export default function SearchResultsHeader(props: SearchResultsHeaderProps) {
    const { onEditSearchClick, resultsData = [] } = props;

    return (
        <ResultsHeaderContainer>
            <ResultsHeader>
                <StyledTitle variant="h6">Results</StyledTitle>
                <SearchCriteriaList resultsData={resultsData} />
            </ResultsHeader>
            <Button variant="outlined" onClick={onEditSearchClick}>
                Edit Search
            </Button>
        </ResultsHeaderContainer>
    );
}
