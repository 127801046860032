import { Box } from '@mui/material';
import { searchLocations } from 'api/iwg/location';
import { searchAvailableSpaces, TSpacesQuery } from 'api/iwg/spaceInventory';
import RightDrawer from 'components/Drawer/RightDrawer';
import { Button } from 'components/Ui-V2/Button/Button';
import { ProductTypeIDs } from 'constants/iwg';
import { useCallback, useMemo, useState } from 'react';
import { i18n } from 'translation';
import SearchForm, { initialFormValues, TFormValues } from './SearchForm';
import VenueTypeSelect from './VenueTypeSelect';

type ViewType = IWG.WorkspaceType | 'main';
const DrawerOptions: Record<ViewType, { title: string; subtitle: string }> = {
    main: {
        title: i18n.workspaces.drawer.mainTitle,
        subtitle: i18n.workspaces.drawer.mainSubTitle,
    },
    meeting_rooms: {
        title: i18n.workspaces.drawer.meetingRoomsTitle,
        subtitle: i18n.workspaces.drawer.meetingRoomsSubTitle,
    },
    coworking_desks: {
        title: i18n.workspaces.drawer.coworkingDesksTitle,
        subtitle: i18n.workspaces.drawer.coworkingDesksSubTitle,
    },
    private_offices: {
        title: i18n.workspaces.drawer.privateOfficesTitle,
        subtitle: i18n.workspaces.drawer.privateOfficesSubTitle,
    },
};

type TSearchDrawerProps = {
    open: boolean;
    onClose: () => void;
    initialWorkspaceType?: IWG.WorkspaceType;
};

const SearchDrawer = ({ initialWorkspaceType, open, onClose }: TSearchDrawerProps) => {
    const initialValues = initialWorkspaceType
        ? { ...initialFormValues, workspaceType: initialWorkspaceType }
        : initialFormValues;
    const [values, setValues] = useState<TFormValues>(initialValues);

    const onTypeSelect = (type: IWG.WorkspaceType) => {
        setValues(prev => ({ ...prev, workspaceType: type }));
    };

    const isFormValid = useMemo(
        () =>
            Boolean(values.workspaceType) &&
            Boolean(values.location) &&
            values.minDelegates > 0 &&
            Boolean(values.date) &&
            Boolean(values.startTime) &&
            Boolean(values.endTime),
        [values]
    );

    const handleCancel = useCallback(() => {
        setValues({
            ...initialFormValues,
        });
    }, []);

    const handleSearch = useCallback(async () => {
        if (
            !values.workspaceType ||
            !values.location ||
            !values.minDelegates ||
            !values.date ||
            !values.startTime ||
            !values.endTime
        ) {
            return;
        }
        const locations = await searchLocations(values.location.description);
        const locationIds = (locations ?? []).map(location => location.id);
        const queryParams: TSpacesQuery = {
            bookingDate: values.date.toISOString(),
            productTypeId: ProductTypeIDs[values.workspaceType],
            startTime: values.startTime,
            endTime: values.endTime,
            minimumDelegates: values.minDelegates,
            locationNumbers: locationIds,
        };
        const response = await searchAvailableSpaces(queryParams);
        console.log('location response: ', response);
    }, [values]);

    const footer = useMemo(
        () => (
            <Box display="flex" gap={1}>
                <Button variant="text" onClick={handleCancel} colorKey="black">
                    {i18n.button.cancel}
                </Button>
                <Button variant="contained" onClick={handleSearch} disabled={!isFormValid}>
                    {i18n.button.search}
                </Button>
            </Box>
        ),
        [isFormValid, handleCancel, handleSearch]
    );

    return (
        <RightDrawer
            drawerOpen={open}
            onClose={onClose}
            title={DrawerOptions[values.workspaceType ?? 'main'].title}
            subtitle={DrawerOptions[values.workspaceType ?? 'main'].subtitle}
            footer={values.workspaceType ? footer : null}
        >
            {!values.workspaceType ? (
                <VenueTypeSelect onSelect={onTypeSelect} />
            ) : (
                <SearchForm values={values} setValues={setValues} />
            )}
        </RightDrawer>
    );
};

export default SearchDrawer;
