import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Button } from 'components/BizlyOS/Button/Button';
import ConfirmationDialog from 'components/BizlyOS/ConfirmationDialog';
import { SecondaryTab, SecondaryTabs } from 'components/BizlyOS/Tabs';
import { H2Headline } from 'components/Ui-V2/Headline/Headline';
import { useVenueTagsQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import React, { useEffect, useState } from 'react';
import { EColors, getColor } from 'theme';
import AddEventSpaceDetails from './AddEventSpaceDetails';
import AddSpaceSetupCapacity from './AddSpaceSetupCapacity';

const FOOTER_HEIGHT = 84;

const TabOptions = [
    {
        value: 'general',
        label: 'General',
        Element: AddEventSpaceDetails,
    },
    { value: 'setup_capacity', label: 'Setup & Capacity', Element: AddSpaceSetupCapacity },
];

const DrawerContainer = styled(Box)({
    width: '38rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const DrawerHeader = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2.5),
    paddingBottom: 0,
    boxSizing: 'border-box',
    transition: '0.3s all ease-in-out',
    backgroundColor: getColor(EColors.pureWhite),
}));

const DrawerBody = styled(Box)({
    boxSizing: 'border-box',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
});

const DrawerBodyHeader = styled(Box)({
    position: 'sticky',
    top: 0,
    zIndex: 1,
});

export const DrawerBodyContent = styled(Box)(({ theme }) => ({
    flex: 1,
    padding: theme.spacing(2.5),
}));

export const DrawerBodyFooter = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    height: `${FOOTER_HEIGHT}px`,
    padding: spacing(2.5),
    background: getColor(EColors.pureWhite),
    boxShadow: `0 1px 5px ${getColor(EColors.grey)}`,
    position: 'sticky',
    bottom: 0,
}));

export const ActionButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
}));

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    isValid: boolean;
    isLast: boolean;
}

export const DrawerActionButtons = ({
    onCancel,
    onSubmit,
    isSubmitting,
    isValid,
    isLast,
}: DrawerActionButtonsProps) => {
    return (
        <ActionButtonContainer>
            <Button variant="secondary" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid} onClick={onSubmit}>
                {isSubmitting ? 'Submitting...' : isLast ? 'Submit' : ' Save and Continue'}
            </Button>
        </ActionButtonContainer>
    );
};

export type SpaceDrawerChildrenProps = {
    stepNumber: number;
    totalSteps: number;
    onFinish: (space: Bizly.OsEventSpace) => void;
    seletedEventSpace: Bizly.OsEventSpace | undefined;
    isLast: boolean;
    setIsChanged: (changed: boolean) => void;
    venueTags: Bizly.VenueTags[];
};

type AddEventSpaceDrawerProps = {
    drawerOpen: boolean;
    setDrawerOpen: (toggle: boolean) => void;
    seletedEventSpace: Bizly.OsEventSpace | undefined;
    changeSeletedEventSpace: (space: Bizly.OsEventSpace | undefined) => void;
};

export default function AddEventSpaceDrawer({
    drawerOpen,
    setDrawerOpen,
    seletedEventSpace,
    changeSeletedEventSpace,
}: AddEventSpaceDrawerProps) {
    const isAdd = !seletedEventSpace;

    // changedEventSpace is created to keep updated data separate from selectedEventSpace
    const [changedEventSpace, setChangedEventSpace] = useState<Bizly.OsEventSpace>();
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [isChanged, setIsChanged] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const { data: venueTags } = useVenueTagsQuery();

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setCurrentIndex(newValue);
    };

    const onClose = () => {
        setDrawerOpen(false);
        setCurrentIndex(0);
        setChangedEventSpace(undefined);
        changeSeletedEventSpace(undefined);
    };

    function onOpenConfirmationDialog() {
        setOpenConfirmationDialog(false);
        onClose();
    }

    function onCloseConfirmationDialog() {
        setOpenConfirmationDialog(false);
    }

    const onFinish = (data: Bizly.OsEventSpace) => {
        setChangedEventSpace(data);
        if (currentIndex + 1 !== TabOptions.length) {
            setCurrentIndex(val => ++val);
        } else {
            // TODO: add update/create api calls
            onClose();
        }
    };

    const onCloseClicked = () => {
        if (isChanged) {
            setOpenConfirmationDialog(true);
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (seletedEventSpace) {
            setChangedEventSpace({ ...seletedEventSpace });
        }
    }, [seletedEventSpace]);

    return (
        <>
            <Drawer anchor="right" open={drawerOpen} onClose={onCloseClicked}>
                <DrawerContainer>
                    <DrawerHeader>
                        <H2Headline>{isAdd ? 'Add Event Space' : seletedEventSpace?.name}</H2Headline>
                        <IconButton onClick={onCloseClicked}>
                            <CloseIcon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                        </IconButton>
                    </DrawerHeader>
                    <DrawerBody>
                        <DrawerBodyHeader>
                            <SecondaryTabs onChange={handleTabChange} value={currentIndex}>
                                {TabOptions.map((option, index) => (
                                    <SecondaryTab
                                        key={option.value}
                                        label={option.label}
                                        value={index}
                                        disabled={isAdd && !changedEventSpace}
                                    />
                                ))}
                            </SecondaryTabs>
                        </DrawerBodyHeader>
                        {!isAdd && !changedEventSpace
                            ? null
                            : React.createElement(TabOptions[currentIndex].Element, {
                                  seletedEventSpace: changedEventSpace,
                                  stepNumber: currentIndex + 1,
                                  totalSteps: TabOptions.length,
                                  isLast: currentIndex + 1 === TabOptions.length,
                                  onFinish,
                                  setIsChanged: setIsChanged,
                                  venueTags: venueTags || [],
                              })}
                    </DrawerBody>
                </DrawerContainer>
            </Drawer>
            <ConfirmationDialog
                title="Unsaved Changes"
                message="Are you sure you want to close without saving? Your changes to the Event Spaces form will not be saved if you continue."
                open={openConfirmationDialog}
                onConfirm={onOpenConfirmationDialog}
                onCancel={onCloseConfirmationDialog}
            />
        </>
    );
}
