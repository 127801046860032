import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import keyBy from 'lodash/keyBy';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { REJECTED_STATUSES } from 'components/VenueCard';
import VenueMap from 'components/VenueMap';
import { InfiniteGrid, NoSearchGrid } from 'components/VenueSearch/VenueSearchInfiniteGrid';
import { useVenueSearch } from 'hooks/venueSearchHooks';
import { useUser } from 'providers/user';
import { hasAcceptedProposal, useCurrentInquiry } from 'stores/current-inquiry';
import { TFilterValue } from './VenueSearchFilters';
import EmptyVisual from 'components/Ui-V2/EmptyVisual';
import { i18n } from 'translation';
import EmptyPageImage from 'images/empty-pages-assets/empty_venues.png';

const FittedVenueMap = styled(VenueMap)`
    flex: 0 0 calc(100% - 20px);
    max-width: 503px;
    min-width: 503px;
    max-height: 100vh;
    position: sticky;
    top: 0;
    margin-left: 20px;

    @media (max-width: 960px) {
        display: none;
    }
`;

type TInfiniteVenues = {
    event?: Bizly.Event;
    viewVenueListing: (venueId: number) => void;
    searchFilters: TFilterValue;
    latLng?: Bizly.Location;
    showMap: boolean;
    hideActions: boolean;
    selectedVenues?: { [venueId: number]: Bizly.Venue };
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
};

const InfiniteVenues = (props: TInfiniteVenues) => {
    const [highlightedId, setHighlightedId] = useState<number>();
    const [pinHighlightedId, setPinHighlightedId] = useState<number>();

    const { event, viewVenueListing, showMap, searchFilters, latLng, ...restProps } = props;

    const { user } = useUser();
    const { hasMore, loadMore, results, loading } = useVenueSearch(searchFilters, event?.id, latLng);
    const { venues: inquiryVenues } = useCurrentInquiry();
    const hasAnAcceptedProposal = hasAcceptedProposal(inquiryVenues ?? []);

    const venuesById = useMemo(() => (inquiryVenues ? keyBy(inquiryVenues, venue => venue.id) : {}), [inquiryVenues]);

    const resultsWithStatus = useMemo(() => {
        if (!results) return [];

        return results
            .filter(venue => {
                // Filter out office locations if team setting hide_office_location_tiles is true
                if (venue.type.id === 10) {
                    // office location
                    return !user.team?.hideOfficeLocationTiles;
                }

                return true;
            })
            .map(venue => {
                const inquiryVenue = venuesById[venue.id];
                if (inquiryVenue?.status && REJECTED_STATUSES.has(inquiryVenue.status)) {
                    return { ...venue, status: inquiryVenue.status };
                }
                return venue;
            });
    }, [results, venuesById, user.team?.hideOfficeLocationTiles]);

    const onPinClick = useCallback(
        (venueId: number) => {
            setPinHighlightedId(venueId);
        },
        [setPinHighlightedId]
    );

    const noResults = resultsWithStatus.length === 0;

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row', md: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            mt={4}
            sx={{ display: noResults ? 'block' : 'flex' }}
        >
            <Box style={{ flex: '1 1 51.75rem' }}>
                {!results ? (
                    <NoSearchGrid />
                ) : (
                    <>
                        {!noResults && latLng && !loading ? (
                            <InfiniteGrid
                                data={resultsWithStatus}
                                hasMore={hasMore}
                                loadMore={loadMore}
                                onVenueHover={id => setHighlightedId(id || undefined)}
                                highlightedVenue={highlightedId}
                                pinHighlightedVenue={pinHighlightedId}
                                onVisit={viewVenueListing}
                                hasAcceptedProposal={hasAnAcceptedProposal}
                                {...restProps}
                            />
                        ) : (
                            <EmptyVisual
                                image={<img src={EmptyPageImage} alt="No Venues Found" />}
                                title="No Results Found"
                                description={i18n.venues.noVenuesFound}
                            />
                        )}
                    </>
                )}
            </Box>

            {!noResults && showMap && (
                <FittedVenueMap
                    center={latLng}
                    venues={results}
                    highlightedVenueId={highlightedId}
                    pinHighlightedVenueId={pinHighlightedId}
                    onPinClick={onPinClick}
                    showLegend
                />
            )}
        </Stack>
    );
};

export default InfiniteVenues;
