import { Box, styled } from '@mui/material';
import { Body1, Heading2 } from '../Typography/Typography';

const Container = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    width: '600px',
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.pureWhite),
    padding: spacing(3.75),
    border: '1px solid ' + getColor(EColors.bizlyOSBorder),
    borderRadius: '12px',
    maxHeight: '100%',
    overflowY: 'auto',
}));

type StepItemsContainerProps = {
    title: string;
    description: string;
    children: React.JSX.Element | React.JSX.Element[];
};

function StepItemsContainer({ title, description, children }: StepItemsContainerProps) {
    return (
        <Container>
            <Heading2 marginBottom={1.25}>{title}</Heading2>
            <Body1 marginBottom={3.75}>{description}</Body1>
            {children}
        </Container>
    );
}

export default StepItemsContainer;
