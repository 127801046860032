import Dialog from '@mui/material/Dialog';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';

export const StyledDialog = muiStyled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: theme.spacing(3),
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '80vh',
        overflow: 'hidden',
        maxWidth: '1030px',
        margin: '0 auto',
    },

    '& .MuiDialogContent-root': {
        padding: `${theme.spacing(3.75)} ${theme.spacing(5)}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '1 1 auto',
        width: '32.5rem',
    },
}));

export const ModalImage = styled.img`
    height: 100%;
    pointer-events: none;
    user-select: none;
    width: 30rem;
    display: none;

    @media (min-width: 1024px) {
        display: block;
    }
`;
