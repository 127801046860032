import { Box, styled } from '@mui/material';
import { VenueCardProps } from 'pages/Workspaces/VenueSearch';
import VenueCard from './VenueCard';

type VenueListProps = {
    venuesData: VenueCardProps[];
    onVenueCardClick: (venue: VenueCardProps) => void;
};

const StyledVenueCardListContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
});

export default function VenueList(props: VenueListProps) {
    const { venuesData, onVenueCardClick } = props;

    return (
        <StyledVenueCardListContainer>
            {venuesData.map((venue, index) => (
                <Box key={index} onClick={() => onVenueCardClick(venue)}>
                    <VenueCard {...venue} />
                </Box>
            ))}
        </StyledVenueCardListContainer>
    );
}
