import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import emailAnimationData from 'animations/magic-email.json';
import { isAuthenticationPartialSuccess, isSSOOnlyAccount, loginWithGoogleId, sendMagicLink } from 'api';
import { Button } from 'components/Ui-V2/Button/Button';
import SignInImage from 'images/authentication/sign-in.jpeg';
import BizlyLogo from 'images/logos/bizly-dark.svg?react';
import jwt_decode from 'jwt-decode';
import Lottie from 'lottie-react';
import { useSnackbar } from 'notistack';
import { useAuthenticate } from 'pages/Login/util';
import { useCallback, useState } from 'react';
import { i18n } from 'translation';
import { emailIsValid } from 'utils';
import { ExternalRedirect } from './Authentication';
import { GoogleAuthentication } from './GoogleAuthentication';
import { TAuthForm, TGoogleAuth, TResponseObject } from './types';

const RootContainer = styled(Box)({
    display: 'flex',
    height: '100vh',
});

const LeftSection = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    gap: theme.spacing(4),
    padding: theme.spacing(8),
    maxWidth: '31.25rem',
}));

const CenteredContent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
});

const RightSection = styled(Box)({
    flex: 1,
    backgroundImage: `url(${SignInImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const Logo = styled(BizlyLogo)({
    marginBottom: '1.5rem',
});

const AnimationContainer = styled(Box)({
    width: '6.25rem',
    height: '6.25rem',
    marginBottom: '1rem',
});

const OrDivider = styled(Divider)(({ theme: { getColor, EColors }, theme }) => ({
    margin: theme.spacing(2, 0),

    '&::before, &::after': {
        borderColor: alpha(getColor(EColors.pureBlack), 0.1),
    },
}));

interface SignInPageProps {
    authFormValue: TAuthForm;
    onChange: (params: { value: Partial<TAuthForm> }) => void;
    onGoogleSignInSuccess: (user: BizlyAPI.User) => void;
}

export const SignInPage = ({ authFormValue, onChange, onGoogleSignInSuccess }: SignInPageProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [SSOLink, setSSOLink] = useState(null);

    const { enqueueSnackbar } = useSnackbar();
    const [authenticating] = useAuthenticate();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({ value: { email: event.target.value } });
    };

    const handleSendLink = useCallback(async () => {
        if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
            return enqueueSnackbar('Please enter a valid email address.', {
                variant: 'error',
            });
        }

        setIsLoading(true);
        try {
            await sendMagicLink({ email: authFormValue.email });
            setIsEmailSent(true);
        } catch (e) {
            if (isSSOOnlyAccount(e)) {
                return setSSOLink(e.raw.ssoLink);
            }

            if (isAuthenticationPartialSuccess(e)) {
                return <ExternalRedirect to="https://www.bizly.com/pricing/" />;
            }

            return enqueueSnackbar('An error occurred. Please try again.', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    }, [authFormValue.email, enqueueSnackbar]);

    const handleGoogleSignIn = useCallback(
        async (credentialResponse: TGoogleAuth) => {
            if (!credentialResponse.credential) {
                return enqueueSnackbar('Google sign-in failed. Please try again.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                const { credential } = credentialResponse;

                const decoded: TResponseObject = jwt_decode(credential);
                const { email } = decoded;

                if (!email) {
                    return enqueueSnackbar(i18n.error.default, {
                        variant: 'error',
                    });
                }

                const { user } = await loginWithGoogleId({
                    email,
                    googleIdToken: credential,
                });

                if (user?.authToken) {
                    onGoogleSignInSuccess(user);
                } else {
                    throw new Error('Login successful but no auth token received');
                }
            } catch (e) {
                console.error('Login error:', e);
                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                if (isAuthenticationPartialSuccess(e)) {
                    return <ExternalRedirect to="https://www.bizly.com/pricing/" />;
                }

                enqueueSnackbar('An error occurred during login. Please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, onGoogleSignInSuccess]
    );

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && authFormValue.email && emailIsValid(authFormValue.email)) {
            handleSendLink();
        }
    };

    if (authenticating) {
        return (
            <RootContainer>
                <LeftSection>
                    <Typography component="h1" gutterBottom sx={{ fontWeight: 500 }}>
                        {i18n.signIn.authenticating}
                    </Typography>
                </LeftSection>
                <RightSection />
            </RootContainer>
        );
    }

    return (
        <RootContainer>
            <LeftSection>
                <Box>
                    <Logo />
                </Box>
                <CenteredContent>
                    {!isEmailSent && !SSOLink ? (
                        <Box>
                            <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 500 }}>
                                {i18n.signIn.welcome}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {i18n.signIn.subheading}
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="email"
                                value={authFormValue.email || ''}
                                onChange={handleEmailChange}
                                onKeyUp={handleKeyPress}
                                placeholder="Enter your email"
                                margin="normal"
                                autoFocus
                                disabled={isLoading}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleSendLink}
                                disabled={!authFormValue.email || !emailIsValid(authFormValue.email) || isLoading}
                                sx={{ mt: 2, mb: 2 }}
                            >
                                {i18n.signIn.sendLink}
                            </Button>

                            <OrDivider>Or</OrDivider>

                            <GoogleAuthentication onSuccess={handleGoogleSignIn} />

                            <Typography variant="body2" sx={{ mb: 4, mt: 2 }}>
                                {i18n.signIn.agreement}{' '}
                                <Link
                                    href="https://www.bizly.com/terms-of-use"
                                    color="primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.signIn.tos}
                                </Link>
                                <span> and </span>
                                <Link
                                    href="https://bizly.com/privacy-policy/"
                                    color="primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.signIn.privacyPolicy}
                                </Link>
                                <span>.</span>
                            </Typography>
                        </Box>
                    ) : SSOLink ? (
                        <Box>
                            <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 500 }}>
                                {i18n.signIn.ssoLoginRequired}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {i18n.signIn.ssoLoginDescription}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => (window.location.href = SSOLink)}
                                sx={{ mt: 2, mb: 2 }}
                            >
                                {i18n.signIn.ssoLoginProceed}
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ mt: '-10rem' }}>
                            <AnimationContainer>
                                <Lottie animationData={emailAnimationData} loop={false} />
                            </AnimationContainer>
                            <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 500 }}>
                                {i18n.signIn.emailSent}
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                {i18n.signIn.emailSentDescription}
                            </Typography>
                            <Button fullWidth variant="outlined" onClick={() => setIsEmailSent(false)} sx={{ mt: 2 }}>
                                {i18n.signIn.backToSignIn}
                            </Button>
                        </Box>
                    )}
                </CenteredContent>
                {/* TODO: Update this to new page once implemented */}
                {/* <Box>
                    <Typography variant="body2">
                        Are you a venue looking to list on Bizly?{' '}
                        <Link href="/learn-more" color="primary">
                            Learn More
                        </Link>
                    </Typography>
                </Box> */}
            </LeftSection>
            <RightSection />
        </RootContainer>
    );
};
