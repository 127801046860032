import Check from '@mui/icons-material/Check';
import {
    StepLabel as MuiStepLabel,
    StepConnector as MuiSteperConnector,
    StepIconProps,
    stepConnectorClasses,
    styled,
} from '@mui/material';
import { EColors } from 'theme';
import { Body1 } from '../Typography/Typography';

export const StepLabel = styled(MuiStepLabel)(({ theme: { getColor, EColors } }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiStepLabel-label': {
        color: getColor(EColors.pureWhite),
        fontSize: '1rem',
        fontWeight: '700',
    },
}));

export const StepConnector = styled(MuiSteperConnector)(({ theme: { getColor, EColors } }) => ({
    marginLeft: '19px',
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: getColor(EColors.bizlyOSBorder),
        height: '50px',
    },
}));

export const StepIconRoot = styled('div')<{ ownerState: { completed?: boolean; active?: boolean } }>(
    ({ theme: { getColor, EColors, spacing }, ownerState }) => ({
        margin: spacing(0.5),
        boxSizing: 'border-box',
        backgroundColor: getColor(EColors.pureWhite),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%',
        ...(ownerState.completed && {
            backgroundColor: getColor(EColors.bizlyOSPrimary),
        }),
        ...(ownerState.active && {
            border: '1px solid ' + getColor(EColors.bizlyOSPrimary),
        }),
        '& .StepIcon-completedIcon': {
            fontSize: 20,
            color: getColor(EColors.pureWhite),
        },
    })
);

export function StepIcon(props: StepIconProps) {
    const { active, completed, icon } = props;

    return (
        <StepIconRoot ownerState={{ completed, active }}>
            {completed ? (
                <Check className="StepIcon-completedIcon" />
            ) : (
                <Body1 fontWeight={600} color={active ? EColors.bizlyOSPrimary : EColors.bizlyOSBorder}>
                    {icon}
                </Body1>
            )}
        </StepIconRoot>
    );
}
