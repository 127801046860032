import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import FiltersButton from 'components/FilterButton';
import Tokenizer, { TToken } from 'components/Tokenizer';
import { ScrollButton, TokenizerContainer, TokenizerWrapper } from 'components/VenueSearch/VenueSearchFiltersRow';
import { useEffect, useRef, useState } from 'react';
import { i18n } from 'translation';
import { Copy, Switch } from 'ui';

const SearchFiltersRowContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const FiltersButtonContainer = styled(Box)`
    flex: 0 0 auto;
`;

const ActiveFiltersContainer = styled(Box)`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const ViewMapContainer = styled(Box)`
    flex: 0 0 auto;
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;

type TShowScrollButtons = {
    left: boolean;
    right: boolean;
};

type SearchFiltersRowProps = {
    showMap: boolean;
    onToggleMap: (e: React.ChangeEvent<HTMLInputElement>) => void;
    filters?: TToken[];
    onDeleteFilter: (filter: TToken) => void;
};

export default function SearchFiltersRow(props: SearchFiltersRowProps) {
    const { showMap, onToggleMap, filters, onDeleteFilter } = props;

    const [showScrollButtons, setShowScrollButtons] = useState<TShowScrollButtons>({ left: false, right: false });

    const containerRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const { scrollWidth, clientWidth, scrollLeft } = container;
            setShowScrollButtons({
                left: scrollLeft > 0,
                right: scrollWidth > clientWidth + scrollLeft,
            });
        }
    };

    useEffect(() => {
        handleScroll();
    }, [filters]);

    useEffect(() => {
        window.addEventListener('resize', handleScroll);
        const container = containerRef.current;
        container?.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
            container?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const handleToggleFiltersMenu = () => {
        //TODO: Implement toggle filters menu
        console.log('@@Toggle filters menu clicked');
    };

    const hasFilters = filters && filters.length > 0;
    const isOverflow = containerRef.current && containerRef.current.scrollWidth > containerRef.current.clientWidth;

    return (
        <SearchFiltersRowContainer>
            <FiltersButtonContainer>
                <FiltersButton toggleFiltersMenu={handleToggleFiltersMenu} filterCount={0} />
            </FiltersButtonContainer>
            <TokenizerWrapper display="flex" flex={1} hasTokens={hasFilters} isOverflow={isOverflow} hasVenues={true}>
                <TokenizerContainer ref={containerRef}>
                    {hasFilters && (
                        <Copy>
                            <Tokenizer tokens={filters} />
                        </Copy>
                    )}
                </TokenizerContainer>
                {showScrollButtons.left && (
                    <ScrollButton
                        variant="outlined"
                        className="left"
                        onClick={scrollLeft}
                        show={showScrollButtons.left}
                    >
                        <KeyboardArrowLeft />
                    </ScrollButton>
                )}
                {showScrollButtons.right && (
                    <ScrollButton variant="text" className="right" onClick={scrollRight} show={showScrollButtons.right}>
                        <KeyboardArrowRight />
                    </ScrollButton>
                )}
                {/* <ActiveFiltersContainer>
                    <ActiveFiltersList onDeleteFilter={onDeleteFilter} filters={filters} />
                </ActiveFiltersContainer> */}
            </TokenizerWrapper>
            <ViewMapContainer>
                <Copy>{i18n.venue.viewMap}</Copy>
                <Switch checked={showMap} onChange={onToggleMap} value="showMap" />
            </ViewMapContainer>
        </SearchFiltersRowContainer>
    );
}
