import { Stack, styled, Typography } from '@mui/material';

import React from 'react';
import { i18n } from 'translation';
import CoworkingDesksIcon from '../../../images/icons/workspaces/coworking-desks.svg?react';
import MeetingRoomsIcon from '../../../images/icons/workspaces/meeting-rooms.svg?react';
import PrivateOfficeIcon from '../../../images/icons/workspaces/private-office.svg?react';

const VenueTypes: Array<{
    Icon: React.FC;
    title: string;
    description: string;
    type: IWG.WorkspaceType;
}> = [
    {
        Icon: MeetingRoomsIcon,
        title: i18n.workspaces.venueTypes.meetingRooms,
        description: i18n.workspaces.venueTypes.meetingRoomsDescription,
        type: 'meeting_rooms',
    },
    {
        Icon: CoworkingDesksIcon,
        title: i18n.workspaces.venueTypes.coworkingDesks,
        description: i18n.workspaces.venueTypes.coworkingDesksDescription,
        type: 'coworking_desks',
    },
    {
        Icon: PrivateOfficeIcon,
        title: i18n.workspaces.venueTypes.privateOffices,
        description: i18n.workspaces.venueTypes.privateOfficesDescription,
        type: 'private_offices',
    },
];

const TypeContainer = styled(Stack)(({ theme: { getColor, EColors } }) => ({
    padding: '20px',
    gap: '8px',
    alignItems: 'center',
    border: `1px solid ${getColor(EColors.bizlyOSBorder)}`,
    borderRadius: '4px',
    cursor: 'pointer',

    '&:hover': {
        borderColor: getColor(EColors.primaryAction),
        backgroundColor: getColor(EColors.primaryAction, 0.1),
    },
}));

type TVenueTypeSelect = {
    onSelect: (type: IWG.WorkspaceType) => void;
};

const VenueTypeSelect = ({ onSelect }: TVenueTypeSelect) => {
    return (
        <Stack gap={2.5}>
            {VenueTypes.map(type => (
                <TypeContainer key={type.title} onClick={() => onSelect(type.type)}>
                    {<type.Icon />}
                    <Typography fontWeight={600} lineHeight="1rem">
                        {type.title}
                    </Typography>
                    <Typography fontWeight={400} lineHeight="1rem" fontSize="0.875rem">
                        {type.description}
                    </Typography>
                </TypeContainer>
            ))}
        </Stack>
    );
};

export default VenueTypeSelect;
