import { alpha, Box, styled } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { StyledVenueBudgetLabel } from './CompareModal/CompareModalStyledComponents';

type BudgetLabelTotalProps = {
    title: string;
    totalProposal: string;
    isAboveBudget: boolean;
    alertVariant?: AlertProps['variant'];
};

const BudgetHeader = styled(Box)`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

const AlertBox = styled(Box)`
    & > .MuiAlert-root {
        margin-top: 1rem;
        background-color: ${alpha('#1e90ff', 0.1)};
        font-weight: 400;
    }
`;

const BudgetLabelTotal = ({ title, totalProposal, isAboveBudget, alertVariant }: BudgetLabelTotalProps) => {
    return (
        <Box>
            <BudgetHeader>
                <Typography fontSize={14} fontWeight={500}>
                    {title}
                </Typography>
                <StyledVenueBudgetLabel aboveBudget={isAboveBudget}>{totalProposal}</StyledVenueBudgetLabel>
            </BudgetHeader>
            <AlertBox>
                <Alert variant={alertVariant || 'filled'} severity="info">
                    Proposal total does not calculate taxes and fees which the venue will include in their final
                    invoice.
                </Alert>
            </AlertBox>
        </Box>
    );
};

export default BudgetLabelTotal;
