import CloseIcon from '@mui/icons-material/Close';
import { alpha, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { EColors, getColor } from 'theme';

const ModalAction = styled('div')({
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 9999,
});

const StyledCloseIcon = styled(CloseIcon)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    cursor: pointer;
`;

const ModalGalleryContainer = styled('div')({
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus-visible': {
        outline: 'none',
    },
});

const GalleryModal = ({
    children,
    isOpen,
    onClose,
}: {
    children?: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
}) => {
    useEffect(() => {
        return () => {
            document.body.style.removeProperty('overflow');
        };
    }, []);

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{
                backdrop: {
                    style: { backgroundColor: alpha(getColor(EColors.pureBlack), 0.9) },
                },
            }}
            onTransitionExited={() => {
                document.body.style.removeProperty('overflow');
            }}
        >
            <>
                <ModalAction>
                    <StyledCloseIcon onClick={onClose} />
                </ModalAction>
                <ModalGalleryContainer>{children}</ModalGalleryContainer>
            </>
        </Modal>
    );
};

export default GalleryModal;
