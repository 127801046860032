import { get } from 'api';
import axios from 'axios';

const IWG_TOKEN_KEY = 'IWG-TOKEN';

// Helper function to get the token and store it
export const getIWGToken = async (): Promise<string> => {
    try {
        const response = await get(`iwg/token`);
        if (response.success) {
            localStorage.setItem(IWG_TOKEN_KEY, response.token);
            return response.token;
        } else {
            throw new Error(JSON.stringify(response));
        }
    } catch (error) {
        console.error('Error fetching token:', error);
        throw error;
    }
};

// Helper function to get the token from localStorage
const getStoredToken = (): string | null => localStorage.getItem(IWG_TOKEN_KEY);

// Axios instance with interceptor to add Authorization header
const iwgAxiosClient = axios.create({
    baseURL: `https://dev.platform.bizly.com/iwg-api`, // This will use the proxied path
});

iwgAxiosClient.interceptors.request.use(
    async config => {
        const token = getStoredToken();
        if (token && token !== 'undefined') {
            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            const newToken = await getIWGToken(); // Fetch token if it's not available
            config.headers['Authorization'] = `Bearer ${newToken}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Track the state of the token refresh
let isRefreshing = false;
let refreshTokenPromise: Promise<string> | null = null;

iwgAxiosClient.interceptors.response.use(
    response => response, // Pass through any response that isn't an error
    async error => {
        const originalRequest = error.config;

        // Check if the error is a 401 and it wasn't already retried
        if (error.response?.status === 401 && !originalRequest._retry) {
            try {
                if (isRefreshing) {
                    throw new Error('Failed to refresh the token');
                }
                isRefreshing = true;

                refreshTokenPromise = getIWGToken()
                    .then(newToken => {
                        isRefreshing = false;
                        refreshTokenPromise = null;
                        return newToken;
                    })
                    .catch(err => {
                        isRefreshing = false;
                        refreshTokenPromise = null;
                        throw err;
                    });

                const newToken = await refreshTokenPromise;
                localStorage.setItem(IWG_TOKEN_KEY, newToken);

                // Retry the original request with the new token
                originalRequest._retry = true;
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return iwgAxiosClient(originalRequest);
            } catch (tokenRefreshError) {
                // Handle token refresh failure (optional: clear stored token, log out, etc.)
                console.error('Token refresh failed:', tokenRefreshError);
                throw tokenRefreshError;
            }
        }

        // If it's not a 401 or the retry failed, reject the error
        return Promise.reject(error);
    }
);

export default iwgAxiosClient;
