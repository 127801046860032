import debounce from 'awesome-debounce-promise';
import camelize from 'camelize';
import isNumber from 'lodash/isNumber';
import QueryString from 'qs';
import snakeize from 'snakeize';
import 'whatwg-fetch';
import { serializeDate } from '../utils/date_util';
import { getCookie } from '../utils/tokens';

// TODO: try again to get netlify environment variables by context working
// For now, this is an unsavory coupling with the production url
export const apiHost = import.meta.env.VITE_APP_ENV === 'prod' ? '/api' : '/api-dev';
const DEBOUNCE_DELAY = 1000;

// These are passed along by the Netlify proxy instead
// const apiClientId = import.meta.env.VITE_APP_API_CLIENT_ID
// const apiClientSecret = import.meta.env.VITE_APP_API_CLIENT_SECRET

const baseUrl = () => {
    return `${apiHost}/`;
};

export const get = (endpoint, requirements = {}) => {
    const { requireToken, showErrorCode } = requirements;

    if (typeof requireToken === 'boolean') {
        return call(endpoint, {}, requireToken, showErrorCode);
    }
    return call(endpoint, {}, true, showErrorCode);
};

export const post = (endpoint, payload, requirements = {}) => {
    const { requireToken = true, showErrorCode } = requirements;

    return call(
        endpoint,
        {
            body: JSON.stringify(snakeize(payload)),
            method: 'POST',
        },
        requireToken,
        showErrorCode
    );
};

export const put = (endpoint, payload, requirements = {}) => {
    const { showErrorCode } = requirements;

    return call(
        endpoint,
        {
            body: JSON.stringify(snakeize(payload)),
            method: 'PUT',
        },
        true,
        showErrorCode
    );
};

export const patch = (endpoint, payload, requirements = {}) => {
    const { showErrorCode } = requirements;

    return call(
        endpoint,
        {
            body: JSON.stringify(snakeize(payload)),
            method: 'PATCH',
        },
        true,
        showErrorCode
    );
};

export const deleteCall = (endpoint, payload, requirements = {}) => {
    const { showErrorCode } = requirements;

    return call(
        endpoint,
        {
            body: JSON.stringify(snakeize(payload)),
            method: 'DELETE',
        },
        true,
        showErrorCode
    );
};

const authRedirect = () => {
    const pathname = window.location.pathname;
    if (!pathname.includes('/sign-in')) {
        const oldPath = encodeURIComponent(pathname + window.location.search);
        window.location = `/sign-in?redirect=${oldPath}`;
    }
};

const call = async (endpoint, options, requireToken = true, showErrorCode = false) => {
    const pathname = window.location.pathname;
    const isPublicRoute =
        pathname.includes('/login') ||
        pathname.includes('/sign-in') ||
        pathname.includes('/rsvp') ||
        pathname.includes('/survey') ||
        pathname.includes('/accept-team-invite') ||
        pathname.includes('/auth') ||
        (pathname.includes('/proposals') && !pathname.includes('/event'));
    const token = getCookie();

    if (requireToken && !token && !isPublicRoute) {
        authRedirect();
    } else {
        const path = `${baseUrl()}${endpoint}`;
        const mergedOptions = { ...defaultOptions(token), ...options };

        let responseStatus = { status: '', statusText: '' };

        return fetch(path, mergedOptions)
            .then(res => {
                responseStatus = res;
                return res.json();
            })
            .then(json => camelize(json))
            .then(async camelized => {
                if (!camelized.success) {
                    if (responseStatus.status === 401) {
                        authRedirect();
                    }
                    return Promise.reject(
                        showErrorCode
                            ? {
                                  code: responseStatus.status,
                                  message: camelized.message || responseStatus.statusText,
                                  raw: { ...camelized },
                              }
                            : camelized.message
                    );
                }
                return camelized;
            })
            .catch(error => {
                console.warn(`API request failed at ${path}`, error);
                return Promise.reject(error);
            });
    }
};

const defaultOptions = token => ({
    credentials: 'include',
    headers: {
        Authorization: `Bearer ${token}`,
        // 'bizly-api-client-id': apiClientId,
        // 'bizly-api-client-secret': apiClientSecret,
        'url-for-links':
            import.meta.env.VITE_APP_ENV === 'prod'
                ? 'https://platform.bizly.com/'
                : 'https://dev.platform.bizly.com/',
        'content-type': 'application/json',
    },
});

const serializeAgenda = agenda => ({
    id: agenda.id,
    addToInquiry: agenda.addToInquiry,
    attendees: agenda.attendees || 1,
    amenities: {
        av: agenda.avNeeds?.map(avo => avo.id) ?? [],
        foodAndBeverage:
            agenda.fbNeeds?.map(fbo => ({
                optionId: fbo.id,
                styleId: fbo.diningStyle ? fbo.diningStyle.id : null,
            })) ?? [],
    },
    end: agenda.end,
    name: agenda.name,
    roomSetup: agenda.setup && agenda.setup.id,
    start: agenda.start,
    startTime: agenda.startTime,
    endTime: agenda.endTime,
});

const deserializeAgenda = agenda => ({
    attendees: agenda.attendees,
    addToInquiry: agenda.addToInquiry,
    avNeeds: agenda.amenities.av,
    end: agenda.end,
    fbNeeds: agenda.amenities.foodAndBeverage,
    name: agenda.name,
    setupId: agenda.roomSetup,
    start: agenda.start,
    startTime: agenda.startTime,
    endTime: agenda.endTime,
});

export const serializeDay = day => ({
    ...day,
    guestrooms: day.guestrooms || [],
    agenda: day.agenda ? day.agenda.map(a => serializeAgenda(a)) : [],
});
export const deserializeDay = day => ({
    ...day,
    agenda: day.agenda.map(a => deserializeAgenda(a)),
});

const serializeEvent = event => ({
    ...event,
    schedule: event.schedule.map(day => serializeDay(day)),
});

const deserializeEvent = event => {
    return {
        ...event,
        schedule: event.schedule.map(day => deserializeDay(day)),
    };
};

const isValidQuestion = question =>
    !!question.type && Object.keys(questionDefinitionIds).includes(question.type) && question.type !== 'foodAllergies';

const serializeQuestion = question => {
    const { checkInDate, checkOutDate, definition, prompt, responseRequired, ...isomorphicProps } = question;
    return {
        ...isomorphicProps,
        checkInDate: serializeDate(checkInDate),
        checkOutDate: serializeDate(checkOutDate),
        isSeeded: definition.id < 5 || definition.id === 11,
        type: Object.keys(questionDefinitionIds).find(key => questionDefinitionIds[key] === definition.id),
        value: prompt,
        required: Boolean(responseRequired), // comes back 0 or 1
    };
};

const deserializeQuestion = question => {
    const { checkInDate, checkOutDate, required, type, value, ...isomorphicProps } = question;
    return {
        ...isomorphicProps,
        checkInDate: serializeDate(checkInDate),
        checkOutDate: serializeDate(checkOutDate),
        prompt: value,
        responseRequired: required ? 1 : 0,
        definitionId: questionDefinitionIdForType(type),
    };
};
export const questionDefinitionIds = {
    dietaryRestrictions: 1,
    foodAllergies: 2,
    travelDates: 3,
    logistics: 4,
    attendanceType: 11,
    text: 5,
    multipleChoice: 6,
    multipleChoiceMultipleAnswers: 7,
    date: 8,
    linearScale: 9,
};
const questionDefinitionIdForType = type => questionDefinitionIds[type];

export const isQuestionValid = question => {
    const { type } = question;

    const questionToValidator = {
        text: ({ value: prompt }) => !!prompt,
        multipleChoice: ({ value: prompt, options = [] }) => !!prompt && options.length >= 2 && options.every(o => o),
        multipleChoiceMultipleAnswers: ({ value: prompt, options = [] }) =>
            !!prompt && options.length >= 2 && options.every(o => o),
        date: ({ value: prompt }) => !!prompt,
        linearScale: ({ value: prompt, options = {} }) =>
            !!prompt && isNumber(options.lowValue) && isNumber(options.highValue),
    };

    const defaultValidator = () => true;

    const validator = questionToValidator[type] || defaultValidator;

    return validator(question);
};

export const getTemplates = () => get('events/templates').then(json => json.templates);

export const updateEvent = debounce(
    event => put(`events/${event.id}`, deserializeEvent(event)).then(json => serializeEvent(json.event)),
    DEBOUNCE_DELAY
);

export const updateEventNoDebounce = event =>
    put(`events/${event.id}`, deserializeEvent(event)).then(json => serializeEvent(json.event));

export const loadEventOptions = eventId => get(`events/${eventId}/options`);

const placesAPIOptions = {
    inputtype: 'textquery',
    fields: ['place_id', 'formatted_address'],
    key: import.meta.env.VITE_APP_GMAPS_KEY,
};

const placesApiOptionsLocationRestricted = {
    ...placesAPIOptions,
    // components: 'country:us|country:pr|country:ca|country:mx|country:gb', // THIS SHOULD BE REPLACED BY REGION FILTERING ON VENUES
};

export const findPlaces = debounce((query, lat, lng, strictbounds = false) => {
    const options = {
        ...placesAPIOptions,
        ...(lat && lng
            ? {
                  location: [lat, lng],
                  radius: 50000,
              }
            : {}),
        input: query,
    };

    if (strictbounds) {
        options.strictbounds = true;
    }

    return fetch(`/gmaps/maps/api/place/autocomplete/json?${QueryString.stringify(options, { arrayFormat: 'comma' })}`)
        .then(res => res.json())
        .then(json => camelize(json));
}, 200);

export const findCities = debounce(async (query, allowGlobal) => {
    const options = allowGlobal ? placesAPIOptions : placesApiOptionsLocationRestricted;
    const res = await fetch(
        `/gmaps/maps/api/place/autocomplete/json?${QueryString.stringify(
            { ...options, types: '(cities)', input: query },
            { arrayFormat: 'comma' }
        )}`
    );
    const json = await res.json();
    return camelize(json);
}, 500);

export const getPlace = debounce(query => {
    return fetch(`/gmaps/maps/api/place/details/json?place_id=${query}&key=${import.meta.env.VITE_APP_GMAPS_KEY}`)
        .then(res => res.json())
        .then(json => camelize(json));
}, 500);

export const addDocument = (id, document) => post(`events/${id}/resources`, document);

export const removeDocument = (eventId, documentId) => deleteCall(`events/${eventId}/resources/${documentId}`);

export const loadDocuments = id => get(`events/${id}/resources`).then(json => json.resources);

export const loadParcels = id =>
    get(`events/${id}/parcels`).then(json =>
        json.parcels.map(p => ({
            ...p,
            recipients: p.recipients || [],
        }))
    );

// TODO: this currently loads all parcels, change to use an endpoint specifically for loading parcel of id
export const loadParcel = (eventId, parcelId) =>
    loadParcels(eventId).then(parcels => parcels.find(p => p.id === parcelId));

export const loadEventRegistrationInfo = (eventId, slugId) =>
    get(`events/${eventId}/registration-pages/${slugId}`, { requireToken: false });

export const loadPlaybookRegistrationInfo = (playbookId, slugId) =>
    get(`playbooks/${playbookId}/registration-pages/${slugId}`, { requireToken: false });

export const submitEventRegistrationInfo = (eventId, slugId, requestBody) =>
    post(`events/${eventId}/register/${slugId}`, requestBody, { requireToken: false });

export const createParcel = (eventId, parcel) => post(`events/${eventId}/parcels`, parcel);

export const updateParcel = (eventId, parcel) => put(`events/${eventId}/parcels/${parcel.id}`, parcel);

export const deleteParcel = (eventId, parcel) => deleteCall(`events/${eventId}/parcels/${parcel.id}`);

export const sendParcel = (eventId, parcelId) => post(`events/${eventId}/parcels/${parcelId}/send`);

export const publishParcel = (eventId, parcelId) => post(`events/${eventId}/parcels/${parcelId}/publish`);

export const exportParcelResponses = (eventId, parcelId) => get(`events/${eventId}/parcels/${parcelId}/export`);

export const loadEvent = id => get(`events/${id}`).then(json => serializeEvent(json.event));

export const loadEventWithTemplateAndCollaborators = id =>
    get(`events/${id}?include=template,collaborators`).then(({ event, template, collaborators }) => ({
        event: serializeEvent(event),
        template,
        collaborators,
    }));

export const loadEventDeserialized = id => get(`events/${id}`).then(json => json.event);

export const loadQuestions = id =>
    get(`parcels/${id}/questions`)
        .then(json => json.questions.map(serializeQuestion))
        // Protect against cases where type may be null or foreign which would cause script errors later
        .then(questionsToValidate => questionsToValidate.filter(isValidQuestion));

export const createQuestion = (parcelId, question) =>
    post(`parcels/${parcelId}/questions`, deserializeQuestion(question)).then(json => serializeQuestion(json.question));

export const deleteQuestion = (parcelId, questionId) => deleteCall(`parcels/${parcelId}/questions/${questionId}`);

export const updateQuestion = (parcelId, question) =>
    put(`parcels/${parcelId}/questions/${question.id}`, deserializeQuestion(question)).then(json =>
        serializeQuestion(json.question)
    );

export const updateChecklistItem = (event, checklist, updatedItem) => {
    const endpoint = `events/${event.id}/checklists/${checklist.uuid}/items/${updatedItem.uuid}/complete`;
    return updatedItem.completed ? post(endpoint) : deleteCall(endpoint);
};

export const getPaymentCredentials = paymentCardId => get(`payment-cards/${paymentCardId}/details`);

export * from './ai';
export * from './attendees';
export * from './auth';
export * from './bookings';
export * from './collaborators';
export * from './connectors';
export * from './events';
export * from './parcels';
export * from './planner';
export * from './proposalForms';
export * from './schedule';
export * from './user';
export * from './venues';
export * from './virtualMeetings';
