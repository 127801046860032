import colorFns from 'colorFns';
import EventHeader, { getLast } from 'components/EventHeader';
import Footer from 'components/Footer';
import GenericNavButtons from 'components/GenericNavButtons';
import { MEETINGS_NAV_WIDTH, MeetingsNav } from 'components/MeetingsNav';
import SideNav, { SIDE_NAV_WIDTH, SIDE_SMALL_NAV_WIDTH } from 'components/SideNav';
import { Spinner, SpinnerOverlay } from 'components/Spinner';
import RegistrationIconSvg from 'images/icons/registration.svg?react';
import EventDashboard from 'pages/EventDashboard';
import Vendor from 'pages/Vendor';
import Vendors from 'pages/Vendors';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import {
    Navigate,
    NavigationType,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useNavigationType,
    useParams,
} from 'react-router-dom';
import { LoadMeeting, selectMeeting, useMeetings } from 'stores/meetings';
import { LoadSchedule, useSchedule } from 'stores/schedule';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, MaxWidthRow, Row } from 'ui';
import { Attendees } from './Attendees';
import ScheduleCalendarPage from './Calendar';
import Communication from './Communication/Communication';
import EditParcel from './EditParcel/EditParcel';
// TODO: complete translations for these components if the agenda feature is turned on
import { QueryParamEnum } from 'constants/queryParams';
import { parse } from 'query-string';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { hasContract } from 'utils/venue';
import EditSchedule from './EditSchedule';
import { PageNotFound } from './PageNotFound';
import Planner from './Planner/';
import SpendTracker from './SpendTracker';
import VenuePage from './Venue';

const CenteredRow = styled(Row)`
    justify-content: center;

    flex-grow: 1;
    flex-shrink: 0;
    min-height: 0;
`;

export const PAGE_PADDING = 36;

const PaddedContent = styled(MaxWidthRow)`
    display: flex;
    width: 100%;
    padding: ${PAGE_PADDING}px;
    box-sizing: border-box;

    align-items: flex-start;

    min-height: calc(100% - 72px);
    height: calc(100% - 72px);
`;

const FullWidthAndHeight = styled(Column)`
    flex: 1 1 0;
    display: flex;
    justify-content: center;

    width: 100%;
`;

const HideableColumn = styled(Column)<{ hidden: boolean }>`
    flex-shrink: 0;
    min-height: 100%;
    width: 100%;

    ${props =>
        props.hidden
            ? `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;
    opacity: 0; /* without this, certain things still show */
    visibility: hidden; /* performance reasons */

    pointer-events: none
  `
            : ``}
`;

const EVENT_HEADER_BLACKLIST = ['guest-list/:sub/*', 'agenda/:sub/*'];

const Header = ({
    loading,
    pageOffset,
    maxSearchWidth,
}: {
    loading: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
}) => (
    <Routes>
        {EVENT_HEADER_BLACKLIST.map(path => (
            <Route key={path} path={path} element={null} />
        ))}
        <Route
            path="*"
            element={<EventHeader loading={loading} pageOffset={pageOffset} maxSearchWidth={maxSearchWidth} />}
        />
    </Routes>
);

const Event = ({ backgroundLocation }: { backgroundLocation?: Location }) => {
    const { eventId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { loading, event, error, suggestedVenues, updateAttendeesCount } = useEvent();
    const { loading: inquiryLoading, loaded: inquiryLoaded } = useCurrentInquiry();
    const { user, toggles } = useUser();
    const schedule = useSchedule();
    const meeting = useMeetings(selectMeeting(eventId));

    // let the foreground location handle loading/error
    if (backgroundLocation && (error || loading)) {
        return null;
    }

    if (error) {
        return <PageNotFound />;
    }

    if (loading || !meeting || (inquiryLoading && !inquiryLoaded)) {
        const content = (
            <FullWidthAndHeight>
                <LoadMeeting id={eventId} />
                <Spinner />
            </FullWidthAndHeight>
        );

        if (toggles.gate.createMeetingsFlow) {
            return (
                <FullWidthAndHeight>
                    <Spinner />
                </FullWidthAndHeight>
            );
        }

        return content;
    }

    const { trackspending } = parse(location.search, { parseBooleans: true });
    const viewVenueListing = (venueId: number) => {
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };
    const goToVenueSearch = () => {
        navigate(`/event/${event.id}/venue`);
    };
    const viewInquiries = () => navigate(`/event/${event.id}/venue/inquiries`);

    const viewProposal = (venue?: Bizly.Venue) => {
        if (venue) {
            let url = `/event/${event.id}/venue/proposal/${venue.proposalId}`;
            if (hasContract(venue)) {
                url = `/event/${event.id}/venue/contracting`;
            }

            navigate(url, {
                state: {
                    shouldGoBack: true,
                },
            });
        }
    };

    const viewVenueListingInPopup = (venueId: number) => {
        const params = new URLSearchParams(location.search);
        params.set(QueryParamEnum.VENUE_ID, venueId.toString());
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const agendasEnabled =
        user.team?.useAgendas || (schedule.loaded && (schedule.items.length > 0 || schedule.pendingItems.length > 0));
    const agendasUnknown = !user.team || !schedule.loaded;

    const { startsAt, endsAt, timeZone } = meeting;

    const editParcelContext = {
        ...event,
        meeting: { startsAt, endsAt, timeZone },
    };

    // To support urls published on production
    if (trackspending) {
        return <Navigate to="spend-tracker?tab=final" />;
    }

    return (
        <HideableColumn hidden={Boolean(backgroundLocation)}>
            <LoadMeeting id={eventId} />
            <Header
                loading={false}
                pageOffset={SIDE_SMALL_NAV_WIDTH + MEETINGS_NAV_WIDTH}
                maxSearchWidth={toggles.gate.createMeetingsFlow ? 360 : undefined}
            />
            <CenteredRow>
                <LoadSchedule />
                <Routes {...(backgroundLocation ? { location: backgroundLocation } : {})}>
                    <Route
                        path={`venue/*`}
                        element={
                            <VenuePage
                                event={event}
                                suggestedVenues={suggestedVenues}
                                viewVenueListing={viewVenueListingInPopup}
                                viewProposal={viewProposal}
                                sideMargin={toggles.gate.createMeetingsFlow ? 68 : undefined}
                                pageOffset={toggles.gate.createMeetingsFlow ? SIDE_NAV_WIDTH : undefined}
                            />
                        }
                    />

                    <Route
                        index
                        element={
                            <EventDashboard
                                viewVenueListing={viewVenueListing}
                                viewProposal={viewProposal}
                                goToVenueSearch={goToVenueSearch}
                                viewInquiries={viewInquiries}
                                pagePadding={PAGE_PADDING}
                            />
                        }
                    />

                    <Route
                        path="*"
                        element={
                            <PaddedContent>
                                <LoadSchedule />
                                <Routes {...(backgroundLocation ? { location: backgroundLocation } : {})}>
                                    {(agendasUnknown || agendasEnabled) && (
                                        <Route
                                            path="agenda"
                                            element={agendasUnknown ? <SpinnerOverlay /> : <ScheduleCalendarPage />}
                                        />
                                    )}
                                    <Route path="planner" element={<Planner />} />
                                    <Route path="agenda/:agendaId" element={<EditSchedule />} />
                                    <Route path="virtual" element={<Vendors />} />
                                    <Route path={'virtual/:vendorId'} element={<Vendor />} />
                                    <Route path="guest-list/*" element={<Attendees />} />
                                    <Route path="communication/*" element={<Communication />} />
                                    <Route path={'spend-tracker/*'} element={<SpendTracker />} />
                                    <Route
                                        path="communication/edit/:type/:parcelId"
                                        element={
                                            <EditParcel
                                                context={editParcelContext}
                                                onParcelSend={updateAttendeesCount}
                                            />
                                        }
                                    />
                                    <Route path="*" element={<PageNotFound eventId={event.id} />} />
                                </Routes>
                            </PaddedContent>
                        }
                    />
                </Routes>
            </CenteredRow>
            {toggles.gate.useFooter && <Footer />}
        </HideableColumn>
    );
};

const RegistrationIcon = styled(RegistrationIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;
`;

export default function EventPage() {
    const location = useLocation();
    const backgroundLocation = location.state && location.state.backgroundLocation;
    const { event } = useEvent();
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const { toggles } = useUser();

    const content = (
        <MeetingsNav eventId={event.id}>
            {/* We must be careful not to conditionally render this base event and just change it's props so we don't lose it's state */}
            <Event backgroundLocation={backgroundLocation} />
            {backgroundLocation && <Event />}
        </MeetingsNav>
    );

    if (toggles.gate.createMeetingsFlow) {
        const cameFromApp = navigationType === NavigationType.Push;
        const shouldGoBack = location.state?.shouldGoBack;
        const props = shouldGoBack ? { onBack: () => navigate(-1) } : { backTo: getLast(location) };
        const commProps =
            shouldGoBack || cameFromApp ? { onBack: () => navigate(-1) } : { backTo: `/events/${event.id}` };

        return (
            <SideNav
                fillWidth
                fillHeight
                fullWidth
                routeChildren={
                    <Routes>
                        <Route
                            path="communication/*/invite"
                            element={
                                <GenericNavButtons
                                    {...commProps}
                                    links={[
                                        {
                                            key: 'Edit Invite',
                                            active: true,
                                            children: (
                                                <>
                                                    <RegistrationIcon active />
                                                    {i18n.communication.editInvite}
                                                </>
                                            ),
                                        },
                                    ]}
                                />
                            }
                        />
                        <Route
                            path="communication/*/note"
                            element={
                                <GenericNavButtons
                                    {...commProps}
                                    links={[
                                        {
                                            key: 'Edit Note/Survey',
                                            active: true,
                                            children: (
                                                <>
                                                    <RegistrationIcon active />
                                                    {i18n.communication.editNoteSurvey}
                                                </>
                                            ),
                                        },
                                    ]}
                                />
                            }
                        />
                        <Route path="*" element={<GenericNavButtons {...props} />} />
                    </Routes>
                }
            >
                {content}
            </SideNav>
        );
    }
    return (
        <SideNav fillWidth fillHeight fullWidth minimized>
            {content}
        </SideNav>
    );
}
