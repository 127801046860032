import { SvgIconComponent } from '@mui/icons-material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExploreIcon from '@mui/icons-material/Explore';
import FlightIcon from '@mui/icons-material/Flight';
import GroupsIcon from '@mui/icons-material/Groups';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StarIcon from '@mui/icons-material/Star';
import { Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { EColors, getColor } from 'theme';

interface KeyDetail {
    section: string;
    content: string;
    key?: keyof typeof IconMap;
}

interface OptionalKeyDetail {
    section?: string;
    content?: string;
    key?: keyof typeof IconMap;
}

interface KeyDetailsAccordionProps {
    keyDetails: (KeyDetail | OptionalKeyDetail)[] | undefined;
}

const IconMap: Record<string, SvgIconComponent> = {
    overview: ApartmentIcon,
    spaces: GroupsIcon,
    dining: RestaurantIcon,
    seasonality: CalendarMonthIcon,
    airports: FlightIcon,
    transit: DirectionsBusIcon,
    nearby: ExploreIcon,
    ratings: StarIcon,
    awards: EmojiEventsIcon,
    sustainability: EnergySavingsLeafIcon,
};

const StyledContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '24px',
    background: getColor(EColors.pureWhite),
    border: `1px solid ${getColor(EColors.placeholderBackground)}`,
    borderRadius: '12px',
    flex: 'none',
    order: 1,
    alignSelf: 'stretch',
    flexGrow: 0,
    overflowY: 'auto',
});

const TitleTypography = styled(Typography)({
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    paddingBottom: '20px',
});

const AccordionContainer = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1px',
});

const StyledAccordion = styled(Accordion, {
    shouldForwardProp: prop => prop !== 'isVisible',
})<{ isVisible: boolean }>(({ isVisible }) => ({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
    transition: 'opacity 0.5s ease, transform 0.5s ease',
    '&.MuiAccordion-root': {
        borderRadius: 0,
        border: 'none',
        borderBottom: `1px solid ${getColor(EColors.placeholderBackground)}`,
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 0,
            paddingBottom: '1rem',
        },
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '&.MuiPaper-root': {
            borderRadius: 0,
        },
    },
    '&:first-of-type': {
        borderTop: `1px solid ${getColor(EColors.placeholderBackground)}`,
    },
}));

const StyledAccordionSummary = styled(AccordionSummary)({
    padding: '20px 0',
    transition: 'all 0.2s ease',
    '& .MuiAccordionSummary-content': {
        margin: 0,
        transition: 'margin 0.2s ease',
    },
    minHeight: 'unset',
    '&.Mui-expanded': {
        minHeight: 'unset',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '0',
    },
});

const IconContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
});

const SectionTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: '1rem',
    color: '#1A1A1A',
    lineHeight: '1.4rem',
});

const StyledAccordionDetails = styled(AccordionDetails)({
    padding: '0',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    marginBottom: 2,
    '& ul': {
        margin: 0,
    },
});

const SkeletonWrapper = styled(Box)({
    width: '100%',
});

const SkeletonItemContainer = styled(Box)<{ isFirst: boolean }>(({ isFirst }) => ({
    padding: '20px 0',
    borderTop: isFirst ? `1px solid ${getColor(EColors.placeholderBackground)}` : 'none',
    borderBottom: `1px solid ${getColor(EColors.placeholderBackground)}`,
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
}));

const LoadingSkeleton = () => (
    <SkeletonWrapper>
        {Array(10)
            .fill(0)
            .map((_, index) => (
                <SkeletonItemContainer key={index} isFirst={index === 0}>
                    <Skeleton variant="circular" animation="wave" width={20} height={20} />
                    <Skeleton animation="wave" width="calc(100% - 32px)" height={22} />
                </SkeletonItemContainer>
            ))}
    </SkeletonWrapper>
);

export const KeyDetailsAccordion = ({ keyDetails }: KeyDetailsAccordionProps) => {
    const [expandedPanels, setExpandedPanels] = useState<Set<string>>(new Set());
    const [visibleSections, setVisibleSections] = useState<Set<number>>(new Set());

    useEffect(() => {
        if (keyDetails?.length) {
            keyDetails.forEach((_, index) => {
                setTimeout(() => {
                    setVisibleSections(prev => new Set([...prev, index]));
                }, index * 150);
            });
        }
    }, [keyDetails]);

    const handleChange = (section: string | undefined) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        if (!section) return;

        setExpandedPanels(prev => {
            const newPanels = new Set(prev);
            if (isExpanded) {
                newPanels.add(section);
            } else {
                newPanels.delete(section);
            }
            return newPanels;
        });
    };

    return (
        <StyledContainer>
            <TitleTypography variant="h5" component="h2">
                Key Details
            </TitleTypography>

            {!keyDetails?.length ? (
                <LoadingSkeleton />
            ) : (
                <AccordionContainer>
                    {keyDetails?.map((detail, index) => {
                        const Icon = detail.key ? IconMap[detail.key] : undefined;
                        const isVisible = visibleSections.has(index);

                        if (!detail.section) return null;

                        return (
                            <StyledAccordion
                                key={index}
                                expanded={expandedPanels.has(detail.section)}
                                onChange={handleChange(detail.section)}
                                isVisible={isVisible}
                            >
                                <StyledAccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: getColor(EColors.pureBlack) }} />}
                                >
                                    <IconContainer>
                                        {Icon && <Icon sx={{ color: getColor(EColors.primaryAction), fontSize: 20 }} />}
                                        <SectionTitle>{detail.section}</SectionTitle>
                                    </IconContainer>
                                </StyledAccordionSummary>

                                <StyledAccordionDetails>
                                    {detail.content && <div dangerouslySetInnerHTML={{ __html: detail.content }} />}
                                </StyledAccordionDetails>
                            </StyledAccordion>
                        );
                    })}
                </AccordionContainer>
            )}
        </StyledContainer>
    );
};
