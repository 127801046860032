import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Box, Snackbar, SnackbarCloseReason, styled } from '@mui/material';
import { useUpdateListingMutation } from 'hooks/queries/BizlyOS/useListingQuery';
import EmptyFaqAnswered from 'images/bizlyOS/empty/empty_faq_answered.svg?react';
import EmptyFaqUnanswered from 'images/bizlyOS/empty/empty_faq_unanswered.svg?react';
import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import * as z from 'zod';
import { Button } from '../Button/Button';
import EmptyMessage from '../EmptyMessage';
import RichTextEditorField from '../InputFields/RichTextEditorField';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import { Tab, Tabs } from '../Tabs';
import TitledContainer from '../TitledContainer';
import { Body2 } from '../Typography/Typography';

const ANSWERED_KEY = 'answered';
const UNANSWERED_KEY = 'unanswered';

const StyledTab = styled(Tab)(({ theme: { getColor, EColors } }) => ({
    '&.MuiTab-root': {
        color: getColor(EColors.pureBlack),
    },
}));

const QuestionContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    paddingBottom: spacing(2.5),
    marginBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const UnansweredQuestionContainer = styled(QuestionContainer)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

interface DrawerActionButtonsProps {
    onCancel: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    isValid: boolean;
}

const DrawerActionButtons = ({ onCancel, onSubmit, isSubmitting, isValid }: DrawerActionButtonsProps) => {
    return (
        <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button variant="secondary" disableElevation onClick={onCancel}>
                Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid} onClick={onSubmit}>
                {isSubmitting ? 'Submitting...' : 'Save'}
            </Button>
        </Box>
    );
};

const FAQSideDrawerSchema = z.object({
    answer: z.string().min(8, 'Answer is required'),
});

type FAQSideDrawerFormData = z.infer<typeof FAQSideDrawerSchema>;

type FAQSideDrawerProps = {
    drawerOpen: boolean;
    onClose: () => void;
    selectedQuestion: Bizly.VenueFaq;
    onSave: (anserr: string) => void;
};

function FAQSideDrawer({ drawerOpen, onClose, selectedQuestion, onSave }: FAQSideDrawerProps) {
    const isUpdate = !!selectedQuestion?.answer;
    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
        setValue,
        reset,
    } = useForm<FAQSideDrawerFormData>({
        resolver: zodResolver(FAQSideDrawerSchema),
        defaultValues: {
            answer: '',
        },
        mode: 'onChange',
    });

    const onSubmit = (data: FAQSideDrawerFormData) => {
        onSave(data.answer);
    };

    const onCancel = () => {
        reset();
        onClose();
    };

    useEffect(() => {
        setValue('answer', selectedQuestion?.answer || '');
    }, [selectedQuestion, setValue]);

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={onCancel}
            title={isUpdate ? 'Update FAQ' : 'Answer FAQ'}
            footer={
                <DrawerActionButtons
                    onCancel={onCancel}
                    onSubmit={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                />
            }
        >
            <Controller
                name="answer"
                control={control}
                render={({ field }) => {
                    return <RichTextEditorField {...field} error={errors.answer} label={selectedQuestion.question} />;
                }}
            />
        </SideDrawer>
    );
}

function AnsweredQuestions({
    questions,
    onEdit,
}: {
    questions: Bizly.VenueFaq[];
    onEdit: (question: Bizly.VenueFaq) => void;
}) {
    if (questions.length === 0) {
        return (
            <EmptyMessage
                icon={<EmptyFaqUnanswered />}
                title="Don't Leave Planners Guessing!"
                description="Answer common questions upfront to help planners make informed decisions and book with confidence."
                action={<Button sx={{ height: 40, padding: '10px 20px' }}>Answer</Button>}
            />
        );
    }

    return questions.map(question => (
        <QuestionContainer key={question.id}>
            <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
                <Box display="flex" gap={1} alignItems="center">
                    <CheckCircleIcon sx={{ color: getColor(EColors.bizlyOSPrimary), fontSize: '19px' }} />
                    <Body2 fontWeight={500}>{question.question}</Body2>
                </Box>
                <Button variant="text" onClick={() => onEdit(question)}>
                    Edit
                </Button>
            </Box>
            <Box
                dangerouslySetInnerHTML={{ __html: question.answer || '' }}
                sx={{ fontSize: '14px', '& > p': { margin: 0 } }}
            />
        </QuestionContainer>
    ));
}

function UnansweredQuestions({
    questions,
    onAnswer,
}: {
    questions: Bizly.VenueFaq[];
    onAnswer: (qustion: Bizly.VenueFaq) => void;
}) {
    if (questions.length === 0) {
        return (
            <EmptyMessage
                icon={<EmptyFaqAnswered />}
                title="All FAQs Answered!"
                description="Thanks for providing helpful answers to common questions. Any new FAQs will show up here."
            />
        );
    }

    return questions.map(question => (
        <UnansweredQuestionContainer key={question.id}>
            <Box display="flex" gap={1} alignItems="center">
                <CheckCircleIcon sx={{ color: getColor(EColors.bizlyOSBorder), fontSize: '19px' }} />
                <Body2 fontWeight={500}>{question.question}</Body2>
            </Box>
            <Button variant="text" onClick={() => onAnswer(question)}>
                Answer
            </Button>
        </UnansweredQuestionContainer>
    ));
}

export default function ListingFAQs({ listing }: { listing: Bizly.Venue }) {
    const updateListingMutation = useUpdateListingMutation(listing.id.toString(), setSnackbarOpen);

    const [tabValue, setTabValue] = useState(UNANSWERED_KEY);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({ type: '', message: '' });

    function handleSnackbarClose(_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function setSnackbarOpen(type?: string, message?: string) {
        setSnackbarMessage({ message: message || '', type: type || '' });
        setOpenSnackbar(true);
    }

    const qustions = useMemo(() => {
        const unansweredQuestions: Bizly.VenueFaq[] = [];
        const answeredQuestions: Bizly.VenueFaq[] = [];

        listing.faq.forEach(faq => {
            if (faq.answer) {
                answeredQuestions.push(faq);
            } else {
                unansweredQuestions.push(faq);
            }
        });

        return {
            unansweredQuestions,
            answeredQuestions,
        };
    }, [listing]);

    const [selectedQuestion, setSelectedQuestion] = useState<Bizly.VenueFaq>();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const onQuestionClicked = (qustion: Bizly.VenueFaq) => {
        setSelectedQuestion(qustion);
        setDrawerOpen(true);
    };

    const onSave = (newAnswer: string) => {
        if (!selectedQuestion) return;

        const getUpdatedFaq = (highlight: Bizly.VenueFaq) => {
            if (highlight.id === selectedQuestion.id) {
                return { ...highlight, answer: newAnswer };
            } else if (highlight.answer) {
                return highlight;
            }
            return null;
        };

        const faqs = compact(listing.faq.map(getUpdatedFaq));

        updateListingMutation.mutate({
            google_place_id: listing.googlePlaceId,
            faq: faqs,
        });
        setSelectedQuestion(undefined);
        setDrawerOpen(false);
    };

    return (
        <>
            <TitledContainer
                title="FAQs"
                rightElement={
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <StyledTab
                            value={UNANSWERED_KEY}
                            label={`Unanswered (${qustions.unansweredQuestions.length})`}
                        />
                        <StyledTab value={ANSWERED_KEY} label={`Answered (${qustions.answeredQuestions.length})`} />
                    </Tabs>
                }
            >
                {tabValue === UNANSWERED_KEY ? (
                    <UnansweredQuestions questions={qustions.unansweredQuestions} onAnswer={onQuestionClicked} />
                ) : (
                    <AnsweredQuestions questions={qustions.answeredQuestions} onEdit={onQuestionClicked} />
                )}
            </TitledContainer>
            {selectedQuestion && (
                <FAQSideDrawer
                    drawerOpen={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    selectedQuestion={selectedQuestion}
                    onSave={onSave}
                />
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                    '&.MuiSnackbar-root': { top: '65px', right: '20px' },
                }}
            >
                <Alert
                    severity="success"
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: snackbarMessage.type === 'success' ? getColor(EColors.bizlyOSPrimary) : 'red',
                    }}
                    onClose={handleSnackbarClose}
                >
                    {snackbarMessage.message}
                </Alert>
            </Snackbar>
        </>
    );
}
