import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { Box, List, ListItem, ListItemText, Autocomplete as MuiAutocomplete, Slider as MuiSlider } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiMenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import MuiSelect from '@mui/material/Select';
import MuiTextField from '@mui/material/TextField';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import LabeledCheckbox from 'components/Ui-V2/Checkbox';
import LabeledRadio from 'components/Ui-V2/Radio';
import useThemedColor from 'hooks/useThemedColor';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { withInteractibleIconStyles } from 'shared';
import styled from 'styled-components';
import { EColors } from 'theme';
import { tzMoment } from 'utils/moment';
import CheckMark from '../images/icons/check-mark.svg?react';
import DownArrow from '../images/icons/down-arrow.svg?react';
import { AlignedRow, Column, Copy, Grid, InlineRow, Row, Spacer, TruncatingSingleLineCopy } from '../ui';
import { CsvFileUploader } from './CsvUploader';
import { ImageUploader } from './ImageUploader';
import { DATE_FORMAT } from './Planner/utils';
import { Uploader } from './Uploader';
import DatePicker from './ui/DatePicker';
import CounterInput from './ui/Input/CounterInput';

const PER_ROW = {
    default: '0',
    auto: 'auto',
    1: '100%',
    2: '50%',
    '2/3': '66.66%',
    3: '33.33%',
    4: '25%',
    5: '20%',
    6: '16.66%',
};

const perRowToPercentage = perRow => {
    if (perRow !== 0 && !perRow) {
        return perRow;
    }

    const [items, spaces] = typeof perRow === 'string' ? perRow.split('/') : [1, perRow];
    return ((items / spaces) * 100).toFixed(2) + '%';
};

export const perRowStyles = perRow => `
    flex-basis: ${PER_ROW[perRow] || perRowToPercentage(perRow) || PER_ROW.default};
    
    ${
        !perRow || perRow === 'default'
            ? `
    flex-grow: 1;
    `
            : ``
    };
  `;

export const fixedWidthStyles = fixedWidth => `
    flex-basis: ${fixedWidth}px;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: ${fixedWidth}px;
`;

const denseMargin = `
  margin-top: 8px;
  margin-bottom: 4px;
`;

// NONE FIELD
// when you want to define a field as none
export const NoneField = () => null;

// DISPLAY FIELD
// when you want to define a field as none
const SingleLineRow = styled(Row)`
    align-items: center;
    max-height: 19px;
`;
const FittedImageContainer = styled.div`
    display: flex;
    min-width: 43px;
    max-width: 43px;
    max-height: 19px;
    height: 19px;
    align-items: center;
`;

const FittedImage = styled.img`
    width: 24px;
`;

const Description = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    font-size: 0.8rem;
`;

const TruncatableCopy = styled(({ overflowTruncate, ...props }) => {
    const CopyComponent = overflowTruncate ? TruncatingSingleLineCopy : Copy;

    return <CopyComponent {...props} />;
})`
    line-height: 19px;
`;

const LabelWithIcon = ({ icon, iconUrl, iconAlt, label, doubleHeight, overflowTruncate }) => (
    <SingleLineRow doubleHeight={doubleHeight}>
        {(iconUrl || icon) && (
            <FittedImageContainer doubleHeight={doubleHeight}>
                {iconUrl ? <FittedImage src={iconUrl} alt={iconAlt} doubleHeight={doubleHeight} /> : icon}
            </FittedImageContainer>
        )}
        <TruncatableCopy overflowTruncate={overflowTruncate}>{label}</TruncatableCopy>
    </SingleLineRow>
);
export const DisplayField = ({
    iconUrl = undefined,
    iconAlt = undefined,
    label = '',
    value = '',
    formatter = val => val,
    className = '',
}) => (
    <FormTextFieldReadonly className={className}>
        <LabelWithIcon iconUrl={iconUrl} iconAlt={iconAlt} label={label || formatter(value)} />
    </FormTextFieldReadonly>
);

export const FieldDescription = ({ value = '' }) => <Description>{value}</Description>;

const CheckboxField = styled(FormControlLabel)`
    margin: 0;
    margin-left: -10px;
    ${denseMargin}
    .MuiCheckbox-root {
        padding: 8px;
    }
`;

// This is a special field that displays a radio select with "Yes" and "No"
// 3 possible values: null for no value, true/false or valueAs/null if value is selected
const YES = 'Yes';
const NO = 'No';

const RadioCheckboxControl = styled(LabeledRadio)`
    margin: 0;
    ${({ perRow }) => perRowStyles(perRow)}
    .MuiIconButton-root {
        padding: 8px;
    }
`;

export const RadioCheckbox = ({ value, onChange: onChangeProp, disabled, useBooleanAsValue }) => {
    const handleChange = event => {
        const newValue = event.target.value === YES;
        onChangeProp(event, useBooleanAsValue ? newValue : event.target.value);
    };

    const selectedValue = useBooleanAsValue ? (value === true ? YES : value === false ? NO : undefined) : value;

    return (
        <RadioGroup row value={selectedValue} onChange={handleChange}>
            <RadioCheckboxControl label={YES} disabled={disabled} value={YES} />
            <RadioCheckboxControl label={NO} disabled={disabled} value={NO} />
        </RadioGroup>
    );
};

export const Checkbox = ({
    field,
    value,
    readonly,
    disabled,
    onChange,
    label,
    asRadio,
    placeholder,
    useBooleanAsValue = false,
}) => {
    const props = {
        field,
        disabled,
        useBooleanAsValue,
        value,
        checked: value,
        onChange: (evt, checked) => {
            onChange({
                field,
                value: checked,
                errors: null,
            });
        },
    };

    const radio = <RadioCheckbox {...props} />;
    const checkbox = <MuiCheckbox color="primary" {...props} />;

    if (readonly) {
        return props.checked ? (
            <DisplayField label={label} />
        ) : placeholder ? (
            <DisplayField label={placeholder} />
        ) : null;
    }

    return <CheckboxField label={label} control={asRadio ? radio : checkbox} />;
};

// CHECKBOX GRID
const ControlsGrid = styled(Grid)`
    flex-flow: row wrap;
    justify-content: flex-start;

    flex: 1 0 0;
    min-height: 0;
    flex-basis: auto;
    margin: 15px 20px;
`;

const griddedStyles = `
    margin: 0;
    padding: 0.25em 1em;
    padding-left: 0;
    box-sizing: border-box;
  `;

const getOptionVals = option =>
    typeof option === 'string' || typeof option === 'number'
        ? {
              optionKey: option,
              optionLabel: option,
          }
        : {
              ...option,
              optionKey: option.id,
              optionLabel: option.name || option.label,
              optionDisabled: option.disabled,
          };

const GriddedCheckbox = styled(LabeledCheckbox)`
    ${griddedStyles}
    ${({ perRow }) => perRowStyles(perRow)}
`;

const MultiselectListItem = styled(ListItem)`
    ${({ densePadding }) => (densePadding ? 'padding: 0;' : 'padding: 16px 0 0 0;')}
`;

export const Multiselect = ({
    field,
    value: val,
    disabled,
    readonly,
    densePadding,
    onChange,
    perRow,
    options,
    placeholder = '',
    customIcon,
    customIconChecked,
}) => {
    const value = val || [];

    const onSelectionChange = (evt, optionKey) => {
        const newSelection = evt.target.checked
            ? Array.from(new Set([...value, optionKey]))
            : value.filter(v => v !== optionKey);
        onChange({
            field,
            value: newSelection,
            errors: null,
        });
    };

    if (readonly) {
        const valuesSet = new Set(value.map(getOptionVals).map(o => o.optionKey));

        let selectedOptions = options.map(getOptionVals).filter(({ optionKey }) => valuesSet.has(optionKey));

        if (selectedOptions.length === 0 && placeholder) {
            selectedOptions = [{ optionLabel: placeholder }];
        }

        return (
            <List disablePadding={densePadding}>
                {selectedOptions.map(({ optionKey, optionLabel }) => (
                    <MultiselectListItem key={optionKey} densePadding={densePadding}>
                        <ListItemText primary={optionLabel} />
                    </MultiselectListItem>
                ))}
            </List>
        );
    }
    return (
        <ControlsGrid>
            {options.map(option => {
                const { optionKey, optionLabel, optionDisabled } = getOptionVals(option);

                return (
                    <GriddedCheckbox
                        key={optionKey}
                        label={optionLabel}
                        perRow={perRow}
                        disabled={disabled || optionDisabled}
                        isChecked={value.includes(optionKey)}
                        customIcon={customIcon}
                        customIconChecked={customIconChecked}
                        onChange={e => onSelectionChange(e, optionKey)}
                    />
                );
            })}
        </ControlsGrid>
    );
};

const GridRadioGroup = styled(RadioGroup)`
    flex-flow: row wrap;
    justify-content: flex-start;
    flex: 1 0 0;
    min-height: 0;
    flex-basis: auto;
`;

// RADIO GRID
const GriddedRadio = styled(LabeledRadio)`
    ${griddedStyles}
    ${({ perRow }) => perRowStyles(perRow)}
    ${({ noTranslation }) => (noTranslation ? 'transform: unset' : '')}
`;

export const RadioSelect = ({
    field,
    value,
    readonly,
    densePadding,
    onChange,
    perRow,
    options,
    noTranslation,
    placeholder = '',
    ...props
}) => {
    const radioOptions = useMemo(() => options.map(getOptionVals), [options]);

    const handleChange = event => {
        onChange({
            field,
            value: event.target.value,
            errors: null,
        });
    };

    if (readonly) {
        return <FormTextFieldReadonly densePadding={densePadding}>{value || placeholder}</FormTextFieldReadonly>;
    }

    return (
        <GridRadioGroup row value={value} onChange={handleChange}>
            {radioOptions.map(({ optionKey, optionLabel }) => {
                return (
                    <GriddedRadio
                        {...props}
                        key={optionKey}
                        value={optionKey}
                        label={optionLabel}
                        perRow={perRow}
                        noTranslation={noTranslation}
                    />
                );
            })}
        </GridRadioGroup>
    );
};

const FlexSpan = styled.span`
    flex-basis: 0;
    text-align: center;
`;

export const LinearScaleField = ({ options, field, value, onChange, readonly }) => {
    const { lowLabel, lowValue, highLabel, highValue } = options;
    const [val, setVal] = useState(value);
    useEffect(() => {
        setVal(value);
    }, [value]);

    return (
        <>
            <Spacer />
            <AlignedRow itemSpacing="large">
                {lowLabel && <FlexSpan>{lowLabel}</FlexSpan>}
                <AirbnbSlider
                    min={lowValue}
                    max={highValue}
                    valueLabelDisplay={readonly ? 'on' : 'auto'}
                    value={val}
                    onChange={(e, newVal) => setVal(newVal)}
                    onChangeCommitted={(e, newVal) => onChange({ field, value: newVal, errors: null })}
                    disabled={readonly}
                />
                {highLabel && <FlexSpan>{highLabel}</FlexSpan>}
            </AlignedRow>
        </>
    );
};

const getBasicOnChange = (field, onChange) => val =>
    onChange({
        field,
        value: val,
        errors: null,
    });

// SELECT
export const FormTextFieldBorders = ({ theme: { getColor, EColors } }) => `
    &.MuiOutlinedInput-root.Mui-disabled,
    .MuiOutlinedInput-root.Mui-disabled {
        opacity: 0.7;

        fieldset.MuiOutlinedInput-notchedOutline {
            border-color: ${getColor(EColors.lightGrey)} !important;
            box-shadow: 0 0 0 0 !important;
        } 
    }

    &.MuiOutlinedInput-root:hover > fieldset,
    .MuiOutlinedInput-root:hover > fieldset, 

    .MuiOutlinedInput-notchedOutline {
        border-color: ${getColor(EColors.lightGrey)};
        border-radius: 3px;
    }
`;

const Select = styled(MuiSelect)`
    ${denseMargin};

    .MuiSelect-root {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)} !important;
        border-radius: 3px;
        // padding: 10.5px 30px 10.5px 14px;
    }

    .MuiSelect-icon {
        top: unset;
        right: 19px;
    }

    ${FormTextFieldBorders};

    .MuiInputBase-input {
        padding: 8px 8px 9px;
    }
`;

const MenuItem = styled(MuiMenuItem)`
    padding: 10.5px 19px 10.5px 14px;

    &:hover,
    &.Mui-focusVisible {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownItemHover)} !important;
    }

    &.Mui-selected {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownItem)};
    }

    &.MuiButtonBase-root {
        display: flex;
        padding: 0.625rem 0.938rem;
        justify-content: flex-start;
    }
`;

const RightCheckMark = styled(withInteractibleIconStyles(CheckMark))`
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
`;

const Emphasize = styled.div`
    font-weight: 600;
    width: 0;
    flex-grow: 1;
`;

const NOTHING_SELECTED = '';

export const SelectField = ({
    field,
    value,
    readonly,
    disabled,
    onChange,
    options,
    placeholder = '',
    className = '',
    overflowTruncate = false,
    valueAsOption = false,
    autoFocusOptionKey = '',
}) => {
    const container = React.useRef();
    const standardized = {
        value: value === null || value === undefined ? getOptionVals('') : getOptionVals(value),
        options: options.map(getOptionVals),
    };
    const selectedOption =
        value !== null && value !== undefined
            ? standardized.options.find(o => o.optionKey === standardized.value.optionKey) || standardized.value
            : standardized.value;

    const fieldValue =
        value === null || value === undefined ? NOTHING_SELECTED : valueAsOption ? selectedOption : value;

    const selectedOptionDisplayProps = {
        iconUrl: selectedOption.iconUrl,
        iconAlt: selectedOption.optionLabel,
        label: selectedOption.label || selectedOption.optionLabel,
    };

    return readonly ? (
        <DisplayField {...selectedOptionDisplayProps} className={className} />
    ) : (
        <Select
            ref={container}
            className={className}
            MenuProps={{
                anchorEl: () => container.current,
                getContentAnchorEl: null,

                transformOrigin: { vertical: -8, horizontal: 'left' },
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                MenuListProps: { disablePadding: true },

                style: { maxHeight: 340 },
            }}
            variant="outlined"
            value={fieldValue?.id || fieldValue}
            onChange={getBasicOnChange(field, onChange)}
            disabled={disabled}
            displayEmpty={!!placeholder}
            IconComponent={DownArrow}
            renderValue={optionKey =>
                optionKey === NOTHING_SELECTED ? (
                    <TruncatableCopy overflowTruncate={overflowTruncate} $color={EColors.darkGrey}>
                        {placeholder}
                    </TruncatableCopy>
                ) : (
                    <LabelWithIcon {...selectedOptionDisplayProps} overflowTruncate={overflowTruncate} />
                )
            }
        >
            {standardized.options.map(option => {
                const { optionKey, optionLabel, iconUrl, icon, doubleHeight, emphasize } = option;
                const optionVal = valueAsOption ? option : optionKey;
                return (
                    <MenuItem key={optionKey} value={optionVal} autoFocus={optionKey === autoFocusOptionKey}>
                        <LabelWithIcon
                            icon={icon}
                            iconUrl={iconUrl}
                            iconAlt={optionLabel}
                            label={
                                <>
                                    {emphasize ? <Emphasize>{optionLabel}</Emphasize> : optionLabel}
                                    {fieldValue === optionVal && <RightCheckMark />}
                                </>
                            }
                            doubleHeight={doubleHeight}
                            overflowTruncate={overflowTruncate}
                        />
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export const AutoComplete = ({
    field,
    value,
    readonly,
    disabled,
    disableClearable,
    onChange,
    options,
    placeholder = '',
    className = '',
    overflowTruncate = false,
    valueAsOption = false,
}) => {
    const standardized = {
        value: value === null || value === undefined ? getOptionVals('') : getOptionVals(value),
        options: options.map(getOptionVals),
    };
    const selectedOption =
        value !== null && value !== undefined
            ? standardized.options.find(o => o.optionKey === standardized.value.optionKey) || standardized.value
            : standardized.value;
    const fieldValue =
        value === null || value === undefined ? NOTHING_SELECTED : valueAsOption ? selectedOption : value;

    const selectedOptionDisplayProps = {
        iconUrl: selectedOption.iconUrl,
        iconAlt: selectedOption.optionLabel,
        label: selectedOption.label || selectedOption.optionLabel,
    };

    const renderMeetingOption = (props, option) => {
        const { optionKey, optionLabel, iconUrl, icon, doubleHeight, emphasize } = option;
        const optionVal = valueAsOption ? option : optionKey;
        return (
            <li {...props} key={option.id}>
                <LabelWithIcon
                    icon={icon}
                    iconUrl={iconUrl}
                    iconAlt={optionLabel}
                    label={
                        <>
                            {emphasize ? <Emphasize>{optionLabel}</Emphasize> : optionLabel}
                            {fieldValue === optionVal && <RightCheckMark />}
                        </>
                    }
                    doubleHeight={doubleHeight}
                    overflowTruncate={overflowTruncate}
                />
            </li>
        );
    };

    return readonly ? (
        <DisplayField {...selectedOptionDisplayProps} className={className} />
    ) : (
        <MuiAutocomplete
            disabled={disabled}
            disableClearable={disableClearable}
            value={selectedOption}
            options={standardized.options || []}
            getOptionLabel={option => option.optionLabel || ''}
            renderOption={renderMeetingOption}
            renderInput={params => <TextField {...params} label={placeholder} />}
            onChange={(_, value) =>
                onChange({
                    field,
                    value: value?.optionKey ? value.optionKey : value,
                    errors: null,
                })
            }
        />
    );
};

const MuiDatePickerField = styled(MuiDatePicker)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    .MuiInputBase-input {
        padding: 0.5em;
    }
`;

// DATE PICKER
// undefined value causes it to be current day
export const DatePickerField = ({
    field,
    value = null,
    readonly,
    densePadding,
    onChange,
    disabled,
    placeholder = '',
    minDate,
    maxDate,
}) =>
    readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {value ? moment(value).format('ll') : placeholder}
        </FormTextFieldReadonly>
    ) : (
        <MuiDatePickerField
            margin="dense"
            variant="outlined"
            value={value}
            clearable
            onChange={getBasicOnChange(field, onChange)}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />
    );

// DATE PICKER OUTLINED
// undefined value causes it to be current day
export const DatePickerOutlined = styled(DatePicker)`
    input {
        font-size: 16px !important;
        min-height: 38px;
        width: 100%;
    }

    &:hover input {
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.23)};
    }
`;

export const DatePickerOutlinedField = ({
    field,
    value = null,
    readonly,
    densePadding,
    onChange,
    disabled,
    placeholder,
    minDate,
    maxDate,
    minDateMessage,
    datesToDisable = [],
    format = 'MMMM do',
}) => {
    const momentValue = moment(value);
    const fieldValue = momentValue.isValid() ? momentValue : null;
    const disabledDates = datesToDisable.map(d => moment(d).format(DATE_FORMAT));

    return readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {fieldValue ? fieldValue.format(format) : ''}
        </FormTextFieldReadonly>
    ) : (
        <DatePickerOutlined
            margin="dense"
            format={format}
            placeholder={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            minDateMessage={minDateMessage}
            shouldDisableDate={newDateObj => {
                const currDateObj = moment(newDateObj).format(DATE_FORMAT);
                return currDateObj !== value && disabledDates.includes(currDateObj);
            }}
            onChange={newDateObj =>
                onChange({
                    field,
                    value: moment(newDateObj).format(DATE_FORMAT),
                    errors: null,
                })
            }
            value={fieldValue}
            disabled={disabled}
            clearable
        />
    );
};

function CustomRangeShortcuts(props) {
    const { items, onChange, isValid, changeImportance = 'accept' } = props;

    if (items == null || items.length === 0) {
        return null;
    }

    const resolvedItems = items.map(item => {
        const newValue = item.getValue({ isValid });

        return {
            label: item.label,
            onClick: () => {
                onChange(newValue, changeImportance, item);
            },
            disabled: !isValid(newValue),
        };
    });

    return (
        <Box
            sx={{
                gridRow: 1,
                gridColumn: 2,
            }}
        >
            <List
                dense
                sx={theme => ({
                    display: 'flex',
                    px: theme.spacing(4),
                    mt: theme.spacing(2),
                    '& .MuiListItem-root': {
                        pt: 0,
                        pl: 0,
                        pr: theme.spacing(1),
                    },
                })}
            >
                {resolvedItems.map(item => {
                    return (
                        <ListItem key={item.label}>
                            <Chip {...item} />
                        </ListItem>
                    );
                })}
            </List>
            <Divider />
        </Box>
    );
}

// DATE PICKER
// undefined value causes it to be current day
export const DateRangePicker = ({
    field,
    value = null,
    readonly,
    densePadding,
    onChange,
    shortcutsItems,
    disabled,
    placeholder = '',
    minDate,
    maxDate,
    prompt,
    startEndLabel,
    slotProps,
}) =>
    readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {value ? `${tzMoment(value[0]).format('ll') - tzMoment(value[1]).format('ll')}` : placeholder}
        </FormTextFieldReadonly>
    ) : (
        <>
            {prompt && <Spacer small />}
            <MuiDateRangePicker
                value={value}
                onChange={getBasicOnChange(field, onChange)}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                slots={{
                    shortcuts: CustomRangeShortcuts,
                }}
                slotProps={{
                    shortcuts: {
                        items: shortcutsItems,
                    },
                    toolbar: {
                        hidden: true,
                    },
                    actionBar: {
                        hidden: true,
                    },
                    ...slotProps,
                }}
                localeText={startEndLabel}
            />
        </>
    );

// TEXT FIELD
const TextField = React.forwardRef(({ value, onChange, maxLength, inputProps = {}, ...props }, ref) => {
    return (
        <MuiTextField
            inputRef={ref}
            onChange={e => onChange(e.target.value)}
            margin="dense"
            value={value || ''}
            variant="outlined"
            inputProps={{ maxLength, ...inputProps }}
            {...props}
        />
    );
});

const FormTextFieldLabel = styled(Copy)`
    flex: ${props => props.labelSpan || 1} 0 0;
    margin-right: 16px;
`;

export const FormTextField = styled(TextField)`
    flex: ${props => props.inputSpan || 1} 0 0;
    pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};
    min-height: 50px;
    resize: none;
    overflow: hidden;

    ${FormTextFieldBorders}

    background: ${({
        disabled,
        theme: {
            getColor,
            EColors: { disabledAction, pureWhite },
        },
    }) => getColor(disabled ? disabledAction : pureWhite)};
    border-radius: 3px;

    ${props =>
        props.smallPlaceholder
            ? `
    .MuiInputBase-input::placeholder {
        font-size: 0.8rem;
    }`
            : ''}

    .MuiInputBase-input {
        padding: 10.5px 14px;
    }
`;

const FormTextFieldReadonly = styled(Copy)`
    line-height: 19px;
    padding: ${props => (props.densePadding ? '0' : '10.5px')} 0;
    ${denseMargin}
    ${props => (props.color ? 'color: ' + props.color : '')};
`;

const getBasicOnChangeInteger =
    (field, onChange, saveCursor = () => {}, resetCursor = () => {}, maxLimit) =>
    val => {
        const isValid = val === '' || val.match(/^\d+$/g);
        const intVal = parseInt(val);
        const isBeyondLimit = maxLimit && intVal > maxLimit;
        if (isValid && !isBeyondLimit) {
            saveCursor(null);
            return onChange({
                field,
                value: intVal,
                errors: null,
            });
        } else {
            resetCursor();
        }
    };

const getBasicOnChangePercentage =
    (field, onChange, fractionLimit = 10, saveCursor = () => {}, resetCursor = () => {}) =>
    val => {
        const isValid = val === '' || val.match(new RegExp(`^(\\d*)$|^(\\d+\\.{1}\\d{0,${fractionLimit}})$`, 'g'));
        if (isValid) {
            saveCursor(null);
            return onChange({
                field,
                value: val,
                errors: null,
            });
        } else {
            resetCursor();
        }
    };

const getBasicOnBlurPercentage = (field, onChange) => e => {
    const val = e.target.value;
    const newVal = val.match(/^(\d+.)$/g) ? val.replace('.', '') : val;

    if (newVal !== val)
        return onChange({
            field,
            value: newVal,
            errors: null,
        });
};

export const LabeledTextField = ({
    field,
    value,
    // this is weird but allows it to be inferred as boolean | undefined while also resolving to false
    readonly = false && true,
    densePadding = undefined,
    onChange,
    label = undefined,
    labelSpan = undefined,
    inputSpan = undefined,
    placeholder = '',
    currency = 'USD',
    numberType = undefined,
    maxLimit,
    ...props
}) => {
    const [focus, setFocus] = React.useState(false);
    const [range, setSelectionRange] = React.useState([0, 0]);
    const resetCursor = () => {
        if (range && range[1] - range[0] > 0) {
            setTimeout(() => {
                inputRef.current.setSelectionRange(range[0], range[1]);
            }, 0);
        }
    };

    const saveCursor = element => {
        setSelectionRange(element ? [element.selectionStart, element.selectionEnd] : null);
    };

    const inputRef = React.useRef();

    let valueFormatter = val => val;
    let defaultPlaceholder;
    let onFieldChange = getBasicOnChange(field, onChange);
    let onFieldFocus = () => {};
    let onFieldBlur = () => {};
    let onKeyPress = numberType ? e => saveCursor(e.target) : () => {};

    const onFocus = e => {
        setFocus(true);
        resetCursor();
        onFieldFocus(e);
        return props.onFocus?.(e);
    };
    const onBlur = e => {
        setFocus(false);
        saveCursor(document.activeElement === e.target ? e.target : null);
        onFieldBlur(e);
        return props.onBlur?.(e);
    };

    if (numberType === 'currency') {
        const userLocale = Intl.NumberFormat().resolvedOptions().locale;
        const currencyFormatter = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
        });
        const decimals = currencyFormatter.resolvedOptions().maximumFractionDigits;
        const currencySymbol = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
            maximumSignificantDigits: 1,
        })
            .format(0)
            .replace('0', '');
        defaultPlaceholder = currencySymbol || '';

        valueFormatter = val => (!focus && (val || val === 0) ? currencyFormatter.format(val) : val);

        onFieldChange = getBasicOnChangePercentage(field, onChange, decimals, saveCursor, resetCursor);

        const fixFractions = () => {
            const float = parseFloat(value);
            const isNumber = !Number.isNaN(float);
            const isFloat = isNumber && !Number.isInteger(float);
            const fixed = isFloat ? float.toFixed(decimals) : value;

            if (fixed !== float) {
                return onChange({
                    field,
                    value: fixed,
                    errors: null,
                });
            }
        };
        onFieldBlur = fixFractions;
    }
    if (numberType === 'percentage') {
        defaultPlaceholder = '%';
        valueFormatter = val => (!focus && (val || val === 0) ? val + '%' : val);
        onFieldChange = getBasicOnChangePercentage(field, onChange, 10, saveCursor, resetCursor);
        onFieldBlur = getBasicOnBlurPercentage(field, onChange);
    }
    if (numberType === 'integer') {
        valueFormatter = val => (val || val === 0 ? val.toString() : '');
        onFieldChange = getBasicOnChangeInteger(field, onChange, saveCursor, resetCursor, maxLimit);
    }

    return readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {valueFormatter(value) || placeholder || defaultPlaceholder}
        </FormTextFieldReadonly>
    ) : (
        <InlineRow>
            {label && <FormTextFieldLabel labelSpan={labelSpan}>{label}</FormTextFieldLabel>}
            <FormTextField
                {...props}
                inputSpan={inputSpan}
                placeholder={placeholder || defaultPlaceholder}
                value={valueFormatter(value)}
                onChange={onFieldChange}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={inputRef}
                onKeyPress={onKeyPress}
            />
        </InlineRow>
    );
};

// TEXT AREA FIELD
const RelativeRow = styled(Row)`
    position: relative;
`;
const MaxLengthCopy = styled(Copy)`
    position: absolute;
    bottom: 8px;
    right: 8px;
`;
export const TextArea = ({
    field,
    value,
    readonly,
    densePadding,
    onChange,
    disabled,
    maxLength,
    rows = 4,
    rowsMax = 8,
    placeholder = '',
    smallPlaceholder = false,
    autoHeight = false,
}) => {
    const { darkGrey, black } = useThemedColor();

    const handleResize = e => {
        if (!autoHeight) return;
        e.target.style.height = 'auto';
        e.target.style.minHeight = `${e.target.scrollHeight}px`;
    };

    return readonly ? (
        <FormTextFieldReadonly densePadding={densePadding} color={value ? black : darkGrey}>
            {value || placeholder || ''}
        </FormTextFieldReadonly>
    ) : (
        <RelativeRow>
            <FormTextField
                multiline
                rows={autoHeight ? undefined : rows}
                rowsMax={autoHeight ? undefined : rowsMax}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onChange={getBasicOnChange(field, onChange)}
                disabled={disabled}
                smallPlaceholder={smallPlaceholder}
                onInput={handleResize}
            />
            {maxLength !== undefined && (
                <MaxLengthCopy small color={darkGrey}>
                    {maxLength - (value?.length || 0)}
                </MaxLengthCopy>
            )}
        </RelativeRow>
    );
};

// SLIDER FIELD
const markLabelStyles = `
  color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
  top: -4px;
  transform: translateX(-50%) translateY(-100%);
  font-size: 15px;
`;

const SliderContainer = styled(Box)`
    padding: 2em 2em 0;
`;

const AirbnbSlider = styled(MuiSlider)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    height: 8px;

    .MuiSlider-thumb {
        height: 24px;
        width: 24px;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        border: 2px solid currentColor;
    }
    .MuiSlider-track {
        height: 8px;
    }
    .MuiSlider-rail {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
        opacity: 1;
        height: 8px;
    }
    .MuiSlider-mark {
        background-color: transparent;
    }
    .MuiSlider-markLabel {
        ${markLabelStyles}
    }
    .MuiSlider-valueLabel {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        border: 1px solid;
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.imagePlaceholderIcon)};
        border-radius: 6px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
    }
`;

export const Slider = ({ field, value, onChange, min, max, marks, valueLabelFormat, readonly }) => {
    // slider is very choppy without this as entire form is updated for every uncommitted value change
    const [val, setVal] = useState(value);
    useEffect(() => {
        setVal(value);
    }, [value]);

    if (readonly) {
        const isArray = Array.isArray(value);
        const left = isArray ? value[0] : value;
        const right = isArray ? value[1] : value;

        const getLabel = val => {
            if (val === undefined || val === null) return '';
            return valueLabelFormat ? valueLabelFormat(val) : marks.find(mark => mark.value === val)?.label;
        };

        const leftLabel = getLabel(left);
        const rightLabel = getLabel(right);

        return <DisplayField label={leftLabel === rightLabel ? leftLabel : [leftLabel, rightLabel].join(' - ')} />;
    }

    return (
        <SliderContainer>
            <AirbnbSlider
                min={min}
                max={max}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                marks={marks}
                value={val}
                onChange={(e, newVal) => setVal(newVal)}
                onChangeCommitted={(e, newVal) => onChange({ field, value: newVal, errors: null })}
            />
        </SliderContainer>
    );
};

// UPLOAD LIST
export const UploadList = ({
    field,
    value = [],
    onChange,
    prompt,
    description,
    ctaLabel = undefined,
    limit = undefined,
    readonly,
    disabled,
    nested = false,
}) => {
    const removeSelf = targetIdx =>
        onChange({
            field,
            value: value.filter((resource, idx) => idx !== targetIdx),
            errors: null,
        });
    const addResource = resource => onChange({ field, value: [...value, resource], errors: null });

    const limitReached = limit && value.length === limit;

    return (
        <Column itemSpacing="small">
            {value.map((resource, index) => (
                <Column key={`${resource.url}_${index}`}>
                    <Uploader
                        onChange={newValue => newValue === null && removeSelf(index)}
                        resource={resource}
                        disabled={disabled}
                        readonly={readonly}
                        nested={nested}
                    />
                </Column>
            ))}
            {!limitReached && !readonly && !disabled && (
                <Uploader
                    prompt={prompt}
                    description={description}
                    onChange={newResource => addResource(newResource)}
                    resource={null}
                    ctaLabel={ctaLabel}
                    nested={nested}
                />
            )}
        </Column>
    );
};

// UPLOAD SINGLE
export const UploadSingle = ({ field, value, onChange, prompt, description, ctaLabel, readonly, disabled, nested }) => {
    return (
        <UploadList
            limit={1}
            field={field}
            value={value ? [{ url: value }] : []}
            onChange={({ field, value, error }) => onChange({ field, value: value[0]?.url ?? null, error })}
            prompt={prompt}
            description={description}
            ctaLabel={ctaLabel}
            readonly={readonly}
            disabled={disabled}
            nested={nested}
        />
    );
};

// IMAGE UPLOAD FIELD
const ImageUploaderField = styled(ImageUploader)`
    ${denseMargin}
`;
export const ImageUploadField = ({
    field,
    value = null,
    onChange,
    readonly,
    disabled,
    heightRatio,
    accessDenied = false,
    onClickAccessDenied,
}) => {
    return (
        <ImageUploaderField
            onChange={value => onChange({ field, value: value.url, errors: null })}
            resource={typeof value === 'string' ? { url: value } : value}
            disabled={readonly || disabled}
            heightRatio={heightRatio}
            accessDenied={accessDenied}
            onClickAccessDenied={onClickAccessDenied}
        />
    );
};

// CSV UPLOAD FIELD
const CsvFileUploadField = styled(CsvFileUploader)`
    ${denseMargin};
`;

export const CsvUploadField = ({ field, onChange, readonly, disabled }) => {
    return (
        <CsvFileUploadField
            onChange={file => {
                onChange({ field, value: file, errors: null });
            }}
            disabled={readonly || disabled}
        />
    );
};

// COUNTER
// This will align it with the rest of the fields
const CounterInputField = styled(CounterInput)`
    ${denseMargin}
    input {
        font-size: 16px;
    }
    padding: 1px 0;
`;
export const CounterField = ({ field, value = 0, onChange, disabled, min, max, readonly }) => {
    return (
        <CounterInputField
            onCountChange={getBasicOnChange(field, onChange)}
            count={value}
            min={min}
            max={max}
            disabled={disabled}
            readonly={readonly}
        />
    );
};
