import { debounce } from 'lodash';
import { RefObject, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { HEADER_HEIGHT } from 'utils/header';

export const TAB_HEIGHT = 40;
const VISIBLE_TOP = HEADER_HEIGHT + TAB_HEIGHT;

const Tabs = styled.ul`
    list-style: none;
    display: flex;
    padding: 0;
    position: sticky;
    top: ${HEADER_HEIGHT}px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    z-index: 999;
    height: ${TAB_HEIGHT}px;
`;

const Tab = styled('li')`
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;
    text-transform: uppercase;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};

    &.active {
        border-bottom: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    }
`;

export type TabType = {
    label: string;
    targetRef: RefObject<HTMLDivElement>;
};

type ScrollTabsProps = {
    tabs: TabType[];
    className?: string;
};

const ScrollTabs = ({ tabs, className }: ScrollTabsProps) => {
    const [activeTab, setActiveTab] = useState<RefObject<HTMLDivElement> | null>(null);

    const handleTabClick = (targetRef: RefObject<HTMLDivElement>) => {
        if (targetRef.current) {
            setActiveTab(targetRef);
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = useCallback(
        debounce(() => {
            // Use debounce
            for (const tab of tabs) {
                if (tab.targetRef.current) {
                    const rect = tab.targetRef.current.getBoundingClientRect();
                    const verticalMiddle = (rect.top + rect.bottom) / 2;
                    // set as active tab when half of the section is visible on the screen
                    if (verticalMiddle >= VISIBLE_TOP && verticalMiddle <= window.innerHeight) {
                        setActiveTab(tab.targetRef);
                        return;
                    }
                }
            }
        }, 100),
        [tabs]
    );

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Tabs className={className}>
            {tabs.map(tab => (
                <Tab
                    key={tab.label}
                    onClick={() => handleTabClick(tab.targetRef)}
                    className={activeTab === tab.targetRef ? 'active' : ''}
                >
                    {tab.label}
                </Tab>
            ))}
        </Tabs>
    );
};

export default ScrollTabs;
