import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Popover from '@mui/material/Popover';
import pick from 'lodash/pick';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BLANK_TEMPLATE_ID, isBlankTemplateId } from 'api/eventTemplates';
import { createEvent } from 'api/events';
import Form from 'components/Form';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { SpinnerOverlay } from 'components/Spinner';
import { Button } from 'components/Ui-V2/Button/Button';
import TextButton from 'components/ui/Button/TextButton';
import { H2Headline } from 'components/ui/Headline';
import EventCreateImage from 'images/event-create.jpg';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useUser } from 'providers/user';
import { getCventEvent, useCvent } from 'stores/cvent';
import LoadCventEvents from 'stores/cvent/LoadCventEvents';
import { LoadEventTemplates, LoadPlaybook, selectPlaybook, useEventTemplates } from 'stores/eventTemplates';
import { i18n } from 'translation';
import { Column, CopyFaded, Row, Spacer } from 'ui';
import AppleEventCreate from './Steps/AppleEventCreate';
import {
    CventOption,
    EventCreateFields,
    EventCreateSchema,
    EventCreateSchemaWithTemplate,
    EventForm,
    toBizlyEvent,
    validateForm,
} from './formSchema';
import { ModalImage, StyledDialog } from './styled';
import { useBudgetRequired } from './utils';

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const BLANK_CVENT_ID = 1;
const APPLE_TEAM_ID = 10982;

export default function EventCreateModal({
    template,
    defaultFormValues,
    onClose,
    onCreateEvent,
    errors,
    fromPlaybook = false,
}: {
    template?: Bizly.EventTemplate;
    defaultFormValues?: Partial<{
        name: string;
        budget: string;
        costCenter: string;
        cventId: string;
        type: string;
        templateId: string | number;
        cventEventId: string | number;
    }>;
    onClose: () => void;
    onCreateEvent?: (eventId: number) => Promise<void>;
    errors?: string[];
    fromPlaybook?: boolean;
}) {
    const isFlagEnabled = useFeatureFlagEnabled('toggleAppleCreateEventModal');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { search } = useLocation();
    const { location, googlePlaceId } = Object.fromEntries(new URLSearchParams(search));

    const budgetRequired = useBudgetRequired();

    const templateLocationName = template?.location?.name || location;
    const templateLocationGooglePlaceId = template?.location?.googlePlaceId || googlePlaceId;

    const templateLocation = React.useMemo(
        () =>
            templateLocationName && templateLocationGooglePlaceId
                ? {
                      location: templateLocationName,
                      googlePlaceId: templateLocationGooglePlaceId,
                  }
                : null,
        [templateLocationName, templateLocationGooglePlaceId]
    );

    const { user } = useUser();
    const isAppleUser = isFlagEnabled && user.team?.id === APPLE_TEAM_ID;

    const { templates, loading: loadingEventTemplates } = useEventTemplates();
    const templateOptions = React.useMemo(
        () =>
            templates.map(template => ({
                ...template,
                name: isBlankTemplateId(template.id) ? (
                    <CopyFaded>{i18n.homepage.createMeetingModal.noPlaybook}</CopyFaded>
                ) : (
                    `${template.name} (${
                        template.category === 'shared'
                            ? `${user.team?.name}`
                            : template.category === 'personal'
                              ? `${user.firstName} ${user.lastName}`
                              : 'Bizly'
                    })`
                ),
            })),
        [templates, user]
    );

    const { cventEvents, loadingCventEvents } = useCvent();
    const cventOptions = React.useMemo(() => {
        const namedEvents: CventOption[] = cventEvents.map(cventEvent => ({
            ...cventEvent,
            id: cventEvent.cventId,
            name: cventEvent.meetingName,
            costCenter: cventEvent.costCenter,
        }));
        namedEvents.unshift({
            id: BLANK_CVENT_ID,
            name: <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>,
            meetingName: '',
            cventId: '',
            status: '',
        });
        return namedEvents;
    }, [cventEvents]);

    const [value, setValue] = React.useState<EventForm>({
        ...(templateLocation && { city: templateLocation }),
        templateId: template?.id ?? BLANK_TEMPLATE_ID,
        cventEventId: defaultFormValues?.cventId ?? BLANK_CVENT_ID,
        ...defaultFormValues,
    });
    const updateForm = ({ value }: { value: EventForm }) => setValue(value);

    const memoizedErrors = useMemo(() => JSON.stringify(errors), [errors]);

    React.useEffect(() => {
        if (memoizedErrors && memoizedErrors !== 'undefined') {
            const parsedErrors = JSON.parse(memoizedErrors);
            parsedErrors.forEach((error: string) => {
                enqueueSnackbar(error, { variant: 'error' });
            });
        }
    }, [memoizedErrors, enqueueSnackbar]);

    const playbook = useEventTemplates(selectPlaybook(value.templateId));
    const cventEvent = useCvent(getCventEvent(value.cventEventId));

    const immutableFields = useMemo(
        () => pick(defaultFormValues, user.team?.authMeetingCreateFields ?? []),
        [defaultFormValues, user.team]
    );

    const playbookUpdatedRef = useRef(false);
    React.useEffect(() => {
        if (playbook) {
            if (playbookUpdatedRef.current === false && playbook.id === defaultFormValues?.templateId) {
                // skip when the initial playbook has loaded
                return;
            }
            setValue(curValue => ({
                ...curValue,
                city: {
                    location: playbook.location ?? location,
                    googlePlaceId: playbook.googlePlaceId ?? googlePlaceId,
                },
                templateId: playbook.id,
                cventId: playbook.internalId,
                type: playbook.meetingType,
                costCenter: playbook.costCenter,
                budget: playbook.budget,
                department: playbook.department,
                name: playbook.name,
            }));
            playbookUpdatedRef.current = true;
        }

        // we want to override playbook values with the default form values that came from the link the user followed
        if (immutableFields) {
            setValue(curValue => ({
                ...curValue,
                ...immutableFields,
            }));
        }

        // we want to do Cvent last, as we assume that it should override any equivalent Playbook values
        // or a previously selected Cvent event
        if (cventEvent && cventEvent.cventId) {
            setValue(curValue => ({
                ...curValue,
                cventId: cventEvent.cventId,
                name: cventEvent.meetingName,
                costCenter: cventEvent.costCenter,
            }));
        }
    }, [playbook, cventEvent, immutableFields, defaultFormValues, location, googlePlaceId]);

    const onViewTemplate = useCallback(() => {
        if (fromPlaybook) {
            onClose();
        }
    }, [fromPlaybook, onClose]);

    const playbookLocation = React.useMemo(
        () =>
            playbook
                ? {
                      location: playbook.location ?? location,
                      googlePlaceId: playbook.googlePlaceId ?? googlePlaceId,
                  }
                : undefined,
        [googlePlaceId, location, playbook]
    );

    const playbookDescription = playbook && playbook.description ? playbook.description : undefined;

    const formFields = useMemo(
        () =>
            EventCreateFields({
                meetingInternalReferenceFieldLabel: user.team?.meetingInternalReferenceFieldLabel,
                meetingCostCenterFieldLabel: user.team?.meetingCostCenterFieldLabel,
                disabledFields: user.team?.authMeetingCreateFields,
                requiredFields: user.team?.features.requiredInternalFields,
                templates: templateOptions,
                cvents: cventOptions,
                city: playbookLocation ?? templateLocation,
                playbookDescription: playbookDescription,
                budgetRequired: budgetRequired,
                loadingCventEvents: loadingCventEvents,
                onViewTemplate: onViewTemplate,
                costCenterOptions: user.team?.costCenters,
                departmentOptions: user.team?.departments,
            }),
        [
            user,
            templateOptions,
            cventOptions,
            templateLocation,
            playbookLocation,
            playbookDescription,
            budgetRequired,
            loadingCventEvents,
            onViewTemplate,
        ]
    );

    const formSchema =
        loadingEventTemplates || templates?.length
            ? EventCreateSchemaWithTemplate(
                  user.team?.features.hiddenInternalFields,
                  user.team?.cventIntegrationEnabled
              )
            : EventCreateSchema(user.team?.features.hiddenInternalFields, user.team?.cventIntegrationEnabled);

    const [submitting, setSubmitting] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const onSubmit = async () => {
        if (!validateForm(value, budgetRequired, user.team?.features.requiredInternalFields)) {
            return enqueueSnackbar(i18n.homepage.createMeetingModal.completeRequiredFields, { variant: 'error' });
        }

        setSubmitting(true);
        try {
            const { event } = await createEvent(toBizlyEvent(value));
            onCreateEvent && (await onCreateEvent(event.id));
            navigate(`/event/${event.id}/venue`);
            onClose();
        } catch (e) {
            // leaving this direct error for Apple, even though it breaks i18n
            enqueueSnackbar(String(e), { variant: 'error' });
            setSubmitting(false);
        }
    };

    return (
        <StyledDialog open onBackdropClick={submitting ? undefined : onClose}>
            <DialogContent>
                <LoadEventTemplates />
                <LoadCventEvents />
                {value.templateId && <LoadPlaybook id={value.templateId} />}
                {isAppleUser ? (
                    <>
                        <AppleEventCreate
                            formData={value}
                            setFormData={setValue}
                            onCreateEvent={onCreateEvent}
                            handleClose={onClose}
                            options={templateOptions}
                            playbookDescription={playbookDescription}
                            onViewTemplate={onViewTemplate}
                        />
                    </>
                ) : (
                    <>
                        <Column itemSpacing="small">
                            <H2Headline>{i18n.meetingsPage.meetingDetails}</H2Headline>
                            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                                <Row justifyContent="center">
                                    <Popover
                                        open={!!anchorEl}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <MediumDialogContent>
                                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                                        </MediumDialogContent>
                                    </Popover>
                                    <TextButton
                                        onClick={e => {
                                            setAnchorEl(e.currentTarget);
                                        }}
                                        warning
                                    >
                                        {i18n.homepage.createMeetingModal.teamProtocol(user.team.name)}
                                    </TextButton>
                                </Row>
                            )}
                        </Column>

                        <Spacer medium />

                        <Form
                            key={playbook?.id ?? 0}
                            fields={formFields}
                            schema={formSchema}
                            value={value}
                            onChange={updateForm}
                        />

                        <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }} gap={1}>
                            <Button variant="text" onClick={onClose} colorKey="black">
                                {i18n.button.cancel}
                            </Button>
                            <Button variant="contained" onClick={onSubmit} disabled={submitting}>
                                {i18n.button.create}
                            </Button>
                        </Box>
                    </>
                )}

                {submitting && <SpinnerOverlay />}
            </DialogContent>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'contents' } }}>
                <ModalImage src={EventCreateImage} alt="event-create" />
            </Box>
        </StyledDialog>
    );
}
