import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import RichTextEditorField from 'components/BizlyOS/InputFields/RichTextEditorField';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import StepItemsContainer from 'components/BizlyOS/ListingsClaim/StepItemsContainer';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { ListingsCreateStepProps } from './ListingsCreate';

const Schema = z.object({
    venueAbout: z.string().optional(),
});

type Data = z.infer<typeof Schema>;

export default function VenueAbout(props: ListingsCreateStepProps) {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm<Data>({
        resolver: zodResolver(Schema),
        defaultValues: {
            venueAbout: props.listingsCreateData.venueAbout || '',
        },
        mode: 'onChange',
    });

    const onSubmit = (data: Data) => {
        props.onNextStepClicked(data);
    };

    return (
        <ClaimStepContainer
            {...props}
            onSave={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
            contentCentered
        >
            <StepItemsContainer
                title="About your venue"
                description="Tell us what makes your venue special! Do you have unique features, a captivating history, or exceptional service?"
            >
                <Box width="538px" height="228px">
                    <Controller
                        name="venueAbout"
                        control={control}
                        render={({ field }) => {
                            return <RichTextEditorField {...field} error={errors.venueAbout} label="" />;
                        }}
                    />
                </Box>
            </StepItemsContainer>
        </ClaimStepContainer>
    );
}
