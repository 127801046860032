import { Box, styled } from '@mui/material';
import emailAnimationData from 'animations/magic-email.json';
import { Body1, Heading2 } from 'components/BizlyOS/Typography/Typography';
import Lottie from 'lottie-react';

const Container = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    flex: 1,
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.pureWhite),
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(6.25),
}));

const AnimationContainer = styled(Box)({
    width: '6.25rem',
    height: '6.25rem',
    marginBottom: '1rem',
});

const Info = styled(Body1)(({ theme: { getColor, EColors } }) => ({
    '& span': {
        color: getColor(EColors.bizlyOSPrimary),
        textDecoration: 'underline',
        cursor: 'pointer',
        fontWeight: 600,
    },
}));

export default function Successful({ verified = false }: { verified?: boolean }) {
    return (
        <Container>
            <Box maxWidth="600px">
                {verified ? (
                    <>
                        <Heading2 marginTop={13.75} marginBottom={1.25}>
                            Identity verified!
                        </Heading2>
                        <Info>
                            We’ve received your application to list {'{venueName}'} on Bizly. We’ll be in touch within
                            3-5 business days regarding the next steps. If you have any questions in the meantime, check
                            out our <span>FAQS</span>.
                        </Info>
                    </>
                ) : (
                    <>
                        <AnimationContainer>
                            <Lottie animationData={emailAnimationData} loop={false} />
                        </AnimationContainer>
                        <Heading2 marginTop={1.25} marginBottom={1.25}>
                            Confirm your identity
                        </Heading2>
                        <Body1 marginBottom={2.5}>
                            We've sent a magic link to your email to verify your account. Click the link to verify your
                            identity. The link expires in 30 minutes.
                        </Body1>
                        <Info>
                            Didn’t get the email? <span>Click here to resend</span>
                        </Info>
                    </>
                )}
            </Box>
        </Container>
    );
}
