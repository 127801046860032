import { zodResolver } from '@hookform/resolvers/zod';
import { Box, styled } from '@mui/material';
import InputField from 'components/BizlyOS/InputFields/InputField';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { map } from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EColors } from 'theme';
import { z } from 'zod';
import {
    DrawerActionButtons,
    DrawerBodyContent,
    DrawerBodyFooter,
    SpaceDrawerChildrenProps,
} from './AddEventSpaceDrawer';

const CAPACITIES: { id: keyof Bizly.RoomCapacity; label: string; required?: boolean }[] = [
    { id: 'maximumCapacity', label: 'Max Standing Capacity', required: true },
    { id: 'maximumSeatedCapacity', label: 'Max Seated Capacity', required: true },
    { id: 'banquetRounds', label: 'Banquet Round Capacity' },
    { id: 'boardRoomConference', label: 'Boardroom Capacity' },
    { id: 'classroom', label: 'Classroom Capacity' },
    { id: 'cocktailRounds', label: 'Cocktail Rounds Capacity' },
    { id: 'crescentRounds', label: 'Crescent Rounds Capacity' },
    { id: 'eshaped', label: 'E-Shaped Capacity' },
    { id: 'hollowSquare', label: 'Hollow Square Capacity' },
    { id: 'perimeterSeating', label: 'Perimeter Seating Capacity' },
    { id: 'royalConference', label: 'Royal Conference' },
    { id: 'theater', label: 'Theater Capacity' },
    { id: 'tshaped', label: 'T-Shaped Capacity' },
    { id: 'ushaped', label: 'U-Shaped Capacity' },
];

const DEFAULTVALUES = {
    eshaped: 0,
    theater: 0,
    tshaped: 0,
    ushaped: 0,
    classroom: 0,
    hollowSquare: 0,
    banquetRounds: 0,
    cocktailRounds: 0,
    crescentRounds: 0,
    maximumCapacity: 0,
    perimeterSeating: 0,
    boardRoomConference: 0,
    maximumSeatedCapacity: 0,
    royalConference: 0,
    talkShow: 0,
};

const Grid = styled(Box)(({ theme: { spacing } }) => ({
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: 'auto',
    gap: spacing(1.25),
    gridRowGap: spacing(2.5),
}));

const AddSpaceSetupCapacitySchema = z.object({
    maximumSeatedCapacity: z.number().min(1, 'Max Seated Capacity is required'),
    maximumCapacity: z.number().min(1, 'Max Standing Capacity is required'),
    eshaped: z.number().min(0).optional(),
    theater: z.number().min(0).optional(),
    tshaped: z.number().min(0).optional(),
    ushaped: z.number().min(0).optional(),
    classroom: z.number().min(0).optional(),
    talkShow: z.number().min(0).optional(),
    hollowSquare: z.number().min(0).optional(),
    banquetRounds: z.number().min(0).optional(),
    cocktailRounds: z.number().min(0).optional(),
    crescentRounds: z.number().min(0).optional(),
    royalConference: z.number().min(0).optional(),
    perimeterSeating: z.number().min(0).optional(),
    boardRoomConference: z.number().min(0).optional(),
});

type AddSpaceSetupCapacityFormData = z.infer<typeof AddSpaceSetupCapacitySchema>;

export default function AddSpaceSetupCapacity({
    stepNumber,
    totalSteps,
    isLast,
    seletedEventSpace,
    onFinish,
    setIsChanged,
}: SpaceDrawerChildrenProps) {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm<AddSpaceSetupCapacityFormData>({
        resolver: zodResolver(AddSpaceSetupCapacitySchema),
        defaultValues: seletedEventSpace ? seletedEventSpace.capacities : DEFAULTVALUES,
        mode: 'onChange',
    });

    const onSave = (data: AddSpaceSetupCapacityFormData) => {
        if (seletedEventSpace) {
            const capacities: Bizly.RoomCapacity = { ...DEFAULTVALUES };
            map(data, (value: number | undefined, key: string) => {
                if (value === undefined) {
                    capacities[key as keyof Bizly.RoomCapacity] = 0;
                } else {
                    capacities[key as keyof Bizly.RoomCapacity] = value;
                }
            });

            const newEventSpaceData = { ...seletedEventSpace, capacities };
            onFinish(newEventSpaceData);
        }
    };

    useEffect(() => {
        setIsChanged(isDirty);
    }, [isDirty, setIsChanged]);

    return (
        <>
            <DrawerBodyContent>
                <Grid>
                    {CAPACITIES.map(capacity => (
                        <Controller
                            key={capacity.id}
                            name={capacity.id}
                            control={control}
                            render={({ field }) => {
                                return (
                                    <InputField
                                        {...field}
                                        value={field.value || ''}
                                        onChange={event => field.onChange(Number(event.target.value))}
                                        required={capacity.required}
                                        error={errors[capacity.id]}
                                        label={capacity.label}
                                        type="number"
                                    />
                                );
                            }}
                        />
                    ))}
                </Grid>
            </DrawerBodyContent>
            <DrawerBodyFooter>
                <Body2 fontWeight="500" color={EColors.bizlyOSPrimary}>
                    Step {stepNumber}/{totalSteps}
                </Body2>
                <DrawerActionButtons
                    onSubmit={handleSubmit(onSave)}
                    onCancel={() => reset()}
                    isSubmitting={isSubmitting}
                    isValid={isValid && isDirty}
                    isLast={isLast}
                />
            </DrawerBodyFooter>
        </>
    );
}
