import { Alert, Box, Snackbar, SnackbarCloseReason, styled } from '@mui/material';
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridCellCheckboxRenderer,
    GridColDef,
    GridHeaderCheckbox,
    GridRowParams,
} from '@mui/x-data-grid-pro';
import { Spinner } from 'components/Spinner';
import { useGetEventSpaceQuery, useRemoveEventSpaceMutation } from 'hooks/queries/BizlyOS/useListingQuery';
import EmptyTemplateImage from 'images/empty-pages-assets/empty_table.svg';
import { useState } from 'react';
import { EColors, getColor } from 'theme';
import { tzMoment, userTimeZone } from 'utils/moment';
import { Button } from '../Button/Button';
import { ImageWithSkeleton } from '../Image/ImageWithSkeleton';
import TitledContainer from '../TitledContainer';
import { Body1, Body2, Heading5 } from '../Typography/Typography';
import AddEventSpaceDrawer from './EventSpaces/AddEventSpaceDrawer';

const StyledDataGridPro = styled(DataGridPro)<DataGridProProps>(({ theme: { getColor, EColors } }) => ({
    flexGrow: 1,
    border: 'none',
    '& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader': {
        borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSGray20),
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
}));

const StyledGridCellCheckboxRenderer = styled(GridCellCheckboxRenderer)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSBorder),
    '&.Mui-checked': {
        color: getColor(EColors.bizlyOSPrimary),
    },
}));

const StyledGridHeaderCheckbox = styled(GridHeaderCheckbox)(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.bizlyOSBorder),
    '&.Mui-checked': {
        color: getColor(EColors.bizlyOSPrimary),
    },
}));

const CenteredBox = styled(Box)({
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    minHeight: '460px',
});

const EmptyTasks = () => {
    return (
        <CenteredBox>
            <img src={EmptyTemplateImage} alt="Empty Image" />
            <Heading5 variant="h6" fontSize="1.125" lineHeight="2.25rem">
                Showcase Your Event & Meeting Spaces
            </Heading5>
            <Body1 width="450px" textAlign="center" lineHeight="120%">
                Let planners know about your spaces, including restaurants, boardrooms, ballrooms, rooftops, and more!
            </Body1>
        </CenteredBox>
    );
};

const columns: GridColDef[] = [
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: params => <StyledGridCellCheckboxRenderer {...params} />,
        renderHeader: params => <StyledGridHeaderCheckbox {...params} />,
    },
    {
        field: 'name',
        renderHeader: () => (
            <Body2 fontWeight={500} px="10px">
                Outlet
            </Body2>
        ),
        flex: 1,
        minWidth: 100,
        renderCell: params => {
            return (
                <Box display="flex" alignItems="center" padding="10px" gap="20px">
                    <ImageWithSkeleton
                        src={params.row.images[0]?.srcUrl || ''}
                        alt="Outlet Image"
                        width={60}
                        height={60}
                    />
                    <Body2 fontWeight={500} lineHeight="100%">
                        {params.value}
                    </Body2>
                </Box>
            );
        },
    },
    {
        field: 'size',
        headerName: 'Size',
        width: 130,
        valueGetter: (value, row) => {
            const unit = row.metric ? 'Sq. Mt' : 'Sq. Ft';
            if (!value) {
                return null;
            }
            return `${value} ${unit}`;
        },
    },
    {
        field: 'maxCapacity',
        headerName: 'Capacity',
        width: 100,
    },
    {
        field: 'updatedAt',
        headerName: 'Last Updated',
        width: 130,
        valueGetter: params => {
            return tzMoment(params, userTimeZone).format('MMM D, YYYY');
        },
    },
];

export default function ListingEventSpaces({ listing }: { listing: Bizly.Venue }) {
    const { data: eventSpaces, isLoading: isEventSpaceLoading } = useGetEventSpaceQuery(listing.id.toString());

    const removeEventSpaceMutation = useRemoveEventSpaceMutation(listing.id.toString(), setSnackbarOpen);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [seletedEventSpace, setSeletedEventSpace] = useState<Bizly.OsEventSpace>();
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({ type: '', message: '' });

    const handleRowClick = (params: GridRowParams<Bizly.OsEventSpace>) => {
        setSeletedEventSpace(params.row);
        setDrawerOpen(true);
    };

    const handleSelectionChange = (selectionModel: number[]) => {
        setSelectedRowIds(selectionModel);
    };

    const clearSelection = () => setSelectedRowIds([]);

    const handleDelete = async () => {
        removeEventSpaceMutation.mutateAsync(selectedRowIds);
        clearSelection();
    };

    return (
        <>
            <TitledContainer
                title={selectedRowIds.length > 0 ? selectedRowIds.length + ' Event Spaces selected' : 'Event Spaces'}
                rightElement={
                    selectedRowIds.length > 0 ? (
                        <Box display="flex" gap={1.25}>
                            <Button variant="outline" sx={{ height: 40 }} onClick={clearSelection}>
                                Cancel
                            </Button>
                            <Button
                                disabled={removeEventSpaceMutation.isLoading}
                                sx={{ height: 40 }}
                                onClick={handleDelete}
                                color="error"
                            >
                                {removeEventSpaceMutation.isLoading ? 'Deleting...' : 'Delete'}
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            sx={{ padding: '4px' }}
                            variant="text"
                            onClick={() => {
                                setDrawerOpen(true);
                                setSeletedEventSpace(undefined);
                            }}
                        >
                            + Add New Space
                        </Button>
                    )
                }
                bodyPadding={0}
            >
                {isEventSpaceLoading || !eventSpaces ? (
                    <Spinner />
                ) : eventSpaces.length > 0 ? (
                    <StyledDataGridPro
                        columns={columns}
                        rows={eventSpaces}
                        onRowClick={handleRowClick}
                        loading={isEventSpaceLoading}
                        rowHeight={80}
                        hideFooter
                        disableRowSelectionOnClick
                        checkboxSelection
                        rowSelectionModel={selectedRowIds}
                        onRowSelectionModelChange={handleSelectionChange}
                    />
                ) : (
                    <EmptyTasks />
                )}
            </TitledContainer>
            <AddEventSpaceDrawer
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                seletedEventSpace={seletedEventSpace}
                changeSeletedEventSpace={setSeletedEventSpace}
            />
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                    '&.MuiSnackbar-root': { top: '65px', right: '20px' },
                }}
            >
                <Alert
                    severity="success"
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: snackbarMessage.type === 'success' ? getColor(EColors.bizlyOSPrimary) : 'red',
                    }}
                    onClose={handleSnackbarClose}
                >
                    {snackbarMessage.message}
                </Alert>
            </Snackbar>
        </>
    );

    function handleSnackbarClose(_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function setSnackbarOpen(type: string, message: string) {
        setSnackbarMessage({ message, type });
        setOpenSnackbar(true);
    }
}
