import iwgAxiosClient from '.';

export type TSpacesQuery = {
    productTypeId: number;
    bookingDate: string;
    startTime: string;
    endTime: string;
    minimumDelegates: number;
    locationNumbers: string[];
};

export const searchAvailableSpaces = async (query: TSpacesQuery) => {
    try {
        const response = await iwgAxiosClient.get<IWG.SpaceDTO[]>(
            '/public/space-inventory/api/v1/space/availability',
            {
                params: {
                    'query.productTypeId': query.productTypeId,
                    'query.bookingDate': query.bookingDate,
                    'query.startTime': query.startTime,
                    'query.endTime': query.endTime,
                    'query.minimumDelegates': query.minimumDelegates,
                    'query.locationNumbers': query.locationNumbers.join(','),
                },
            }
        );
        return response.data;
    } catch (e) {
        console.error('IWG search available spaces error: ', e);
        return [];
    }
};
