import { Check } from '@mui/icons-material';
import { Box, Card, CardContent, CardMedia, Divider, Grid, styled, Typography } from '@mui/material';

type VenueCardProps = {
    address: string;
    location: string;
    features: string[];
    rates: string;
    image: string;
};

const StyledCard = styled(Card)({
    margin: '2px',
    padding: '10px',
});

const StyledCardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'stretch',
});

const ImageWrapper = styled(Box)({
    flex: '0 0 28%',
});

const StyledCardMedia = styled(CardMedia)({
    height: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
});

const LocationWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginBottom: '10px',
});

const RatesFromWrapper = styled(Box)({
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
});

const StyledCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    padding: '0px',
    flex: 1,

    '&:last-child': {
        paddingBottom: '0px',
    },
});

export default function VenueCard(props: VenueCardProps) {
    const { address, location, features, rates, image = '' } = props;
    return (
        <StyledCard variant="outlined">
            <StyledCardContainer>
                <ImageWrapper>
                    <StyledCardMedia component="img" image={image} alt="venue image" />
                </ImageWrapper>
                <StyledCardContent>
                    <LocationWrapper>
                        <Typography fontSize={16} variant="h6" component="div">
                            {address}
                        </Typography>
                        <Typography fontSize={14} variant="body2" color="text.secondary">
                            {location}
                        </Typography>
                    </LocationWrapper>

                    <Grid container spacing={1}>
                        {features.map((feature, index) => (
                            <Grid item xs={6} key={index}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Check color="primary" sx={{ fontSize: 15 }} />
                                    <Typography fontSize={14} key={index} variant="body2" color="text.primary">
                                        {feature}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <RatesFromWrapper>
                        <Divider sx={{ margin: '10px 0' }} />
                        <Typography fontSize={14} variant="body2" color="text.secondary">
                            Rates from
                        </Typography>
                        <Typography fontSize={16} fontWeight={500} variant="body1" component="div">
                            From ${rates}
                        </Typography>
                    </RatesFromWrapper>
                </StyledCardContent>
            </StyledCardContainer>
        </StyledCard>
    );
}
