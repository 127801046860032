import { useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Box,
	Button,
	DialogContent,
	Link,
	Popover,
	TextField,
	FormControl,
	MenuItem,
	Select,
	Grid,
} from "@mui/material";
import { i18n } from "translation";
import { H2Headline } from "components/ui/Headline";
import { VerbiageAlert } from "./styled";
import InfoIcon from "@mui/icons-material/Info";
import { RichTextDisplay } from "components/Form/RichTextEditor";
import { SpinnerOverlay } from "components/Spinner";
import { useUser } from "providers/user";
import {
	AugmentedTemplatesField,
	CityField,
	EventDatesField,
	EventForm,
	toBizlyEvent,
} from "../formSchema";
import { CopyFaded, InlineRow } from "ui";
import { createEvent, findPlaces, generateEventMetaData } from "api";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { tzMoment } from "utils/moment";
import fontFns from "fontFns";
import styled from "styled-components";
import moment from "moment";

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const FormTextFieldLabel = styled(InlineRow)`
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-bottom: 0.5em;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
    margin-left: 5px;
`;

export const Step1Schema = z.object({
    templateId: z.number().optional(),
    name: z.string().min(1, { message: 'Event name is required' }).max(255, { message: 'Event name is too long' }),
    location: z.string().min(1, { message: 'City location is required' }),
    eventDates: z.object({
        start: z.custom<moment.Moment>((value) => moment.isMoment(value), {
            message: 'Start date must be a valid moment object',
        }),
        end: z.custom<moment.Moment>((value) => moment.isMoment(value), {
            message: 'End date must be a valid moment object',
        }),
    }).optional(),
});

export const Step2Schema = z.object({
    cventId: z.string().min(1, { message: 'Cvent ID is required' }).optional(),
    costCenter: z.string().min(1, { message: 'Cost center is required' }).optional(),
    department: z.string().min(1, { message: 'Department is required' }).optional(),
    budget: z.string().optional(),
    type: z.enum(['', 'Internal', 'External']).optional(),
});

const AppleEventCreateStep1 = ({
    formData,
    handleCancel,
    handleContinue,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    formData: any;
    handleCancel?: () => void;
    handleContinue: (data: any) => void;
    options: object[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: zodResolver(Step1Schema),
        defaultValues: formData,
    });

    const handleStep1Submit: SubmitHandler<any> = (data) => {
        handleContinue(data);
    };

    return (
        <Box>
            <H2Headline>{i18n.meetingsPage.eventDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link
                        onClick={e => setAnchorEl(e.currentTarget)}
                        style={{ cursor: 'pointer' }}
                    >
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}
            <form onSubmit={handleSubmit(handleStep1Submit)}>
                <Box mb={2}>
                    <Controller
                        name="templateField"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>{i18n.homepage.createMeetingModal.playbook}</FormTextFieldLabel>
                                <AugmentedTemplatesField
                                    field="templateField"
                                    value={value}
                                    onChange={({ value }) => onChange(value)}
                                    options={options}
                                    playbookDescription={playbookDescription}
                                    onViewTemplate={onViewTemplate}
                                />
                            </>
                        )}
                    />
                </Box>
                
                <Box mb={2}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.homepage.createMeetingModal.eventName}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.name}
                                />
                            </>
                        )}
                    />
                </Box>
                
                <Box mb={2}>
                    <Controller
                        name="location"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.homepage.createMeetingModal.location}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <CityField
                                    field="location"
                                    onChange={({ value }) => {
                                        onChange({ 
                                            location: value.location ?? formData.location, 
                                            googlePlaceId: value.googlePlaceId ?? formData.googlePlaceId 
                                        })}
                                    }
                                    defaultValue={value || { location: '', googlePlaceId: '' }}
                                    error={null}
                                />
                            </>
                        )}
                    />
                </Box>
                
                <Box mb={2}>
                    <Controller
                        name="eventDates"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.communication.eventDates}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <EventDatesField
                                    value={value || { start: tzMoment(formData.start), end: tzMoment(formData.end) }}
                                    onChange={(value) => {
                                        onChange({
                                            ...value,
                                            start: tzMoment(value.start), 
                                            end: tzMoment(value.end),
                                        })}
                                    }
                                />
                            </>
                        )}
                    />
                </Box>
                

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" type="button" onClick={handleCancel}>
                        {i18n.button.cancel}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.continue}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

const AppleEventCreateStep2 = ({
    formData,
    onSubmit,
    handleBack
}: {
    formData: any;
    onSubmit: (data: any) => void;
    handleBack: () => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: zodResolver(Step2Schema),
        defaultValues: formData,
    });

    const handleStep2Submit: SubmitHandler<any> = (data) => {
        onSubmit(data);
    };

    return (
        <Box>
            <H2Headline>{i18n.proposalForm.proposalNotes.additionalDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link
                        onClick={e => setAnchorEl(e.currentTarget)}
                        style={{ cursor: 'pointer' }}
                    >
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}
            <form onSubmit={handleSubmit(handleStep2Submit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="cventId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.cventId}
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="recordId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.recordId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.recordId}
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="costCenter"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.costCenter}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.costCenter}
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="department"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.department}
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.department}
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="budget"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.budget}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.budget}
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingType}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <FormControl fullWidth error={!!errors.type}>
                                        <Select
                                            {...field}
                                            onChange={(e) => {
                                                formData.type = e.target.value;
                                            }}
                                            defaultValue=""
                                        >
                                            <MenuItem value="">
                                                <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>
                                            </MenuItem>
                                            <MenuItem value="Internal">
                                                {i18n.meetingDashboard.headerSection.settings.internal}
                                            </MenuItem>
                                            <MenuItem value="External">
                                                {i18n.meetingDashboard.headerSection.settings.external}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" onClick={handleBack}>
                        {i18n.button.back}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.create}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

const populateLocationData = async (
    location: string,
    setValue: React.Dispatch<React.SetStateAction<EventForm>>,
    enqueueSnackbar: Function,
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        const result = await generateEventMetaData(location);
        let locationValue: EventForm['city'];

        if (result.eventMetadata?.location) {
            const gmapsResponse = await findPlaces(result.eventMetadata.location);
            if (gmapsResponse.status === 'OK' && gmapsResponse.predictions.length > 0) {
                locationValue = {
                    location: gmapsResponse.predictions[0].description,
                    googlePlaceId: gmapsResponse.predictions[0].placeId,
                };
            }
        }

        if (result.success) {
            setValue(prev => ({
                ...prev,
                city: locationValue,
            }));
        }
    } catch (e) {
        enqueueSnackbar(String(e), { variant: 'error' });
    } finally {
        setSubmitting(false);
    }
};

const AppleEventCreate = ({
    formData,
    setFormData,
    onCreateEvent,
    handleClose,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    formData: any;
    setFormData: (data: object) => void;
    onCreateEvent?: (eventId: number) => Promise<void>;
    handleClose: () => void;
    options: object[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);

    const onSubmit = async () => {
        if (!validateAppleForm(formData, step)) {
            return enqueueSnackbar(i18n.homepage.createMeetingModal.completeRequiredFields, { variant: 'error' });
        }

        setSubmitting(true);
        try {
            const { event } = await createEvent(toBizlyEvent(formData));
            onCreateEvent && (await onCreateEvent(event.id));
            navigate(`/event/${event.id}/venue`);
            handleClose();
        } catch (e) {
            enqueueSnackbar(String(e), { variant: 'error' });
            setSubmitting(false);
        }
    };

    const validateAppleForm = (formData: EventForm, step: number) => {
        const step1RequiredFields: (keyof EventForm)[] = ['name'];
        const step2RequiredFields: (keyof EventForm)[] = ['cventId', 'costCenter', 'budget', 'type', 'recordId'];
        const requiredFields = step === 1 ? step1RequiredFields : step2RequiredFields;
    
        for (const field of requiredFields) {
            const value = formData[field];
    
            if ((typeof value === 'string' && value.trim() === '') || (value === undefined)) {
                return false;
            }            
        }
    
        return true;
    };    

    const handleContinue = async (data: any) => {
        if (!validateAppleForm(data, step)) {
            return enqueueSnackbar(i18n.homepage.createMeetingModal.completeRequiredFields, { variant: 'error' });
        }

        if (!data.eventDates) {
            data.eventDates = {
                start: formData.start,
                end: formData.end,
            };
        }

        if (data.location) {
            setSubmitting(true);
            await populateLocationData(data.location, setFormData, enqueueSnackbar, setSubmitting);
        }

        setStep(2);
    };

    const handleBack = () => setStep(1);

    return (
        <>
            {step === 1 ? (
                <AppleEventCreateStep1
                    formData={formData}
                    handleCancel={handleClose}
                    handleContinue={handleContinue}
                    options={options}
                    playbookDescription={playbookDescription}
                    onViewTemplate={onViewTemplate}
                />
            ) : (
                <AppleEventCreateStep2
                    formData={formData}
                    onSubmit={onSubmit}
                    handleBack={handleBack}
                />
            )}
            {submitting && <SpinnerOverlay />}
        </>
    );
};

export default AppleEventCreate;