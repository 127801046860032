import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, Box, Snackbar, SnackbarCloseReason, styled } from '@mui/material';
import { useUpdateListingMutation } from 'hooks/queries/BizlyOS/useListingQuery';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EColors, getColor } from 'theme';
import * as z from 'zod';
import { Button } from '../Button/Button';
import RichTextEditorField from '../InputFields/RichTextEditorField';
import DrawerActionButtons from '../SideDrawer/DrawerActionButtons';
import { SideDrawer } from '../SideDrawer/SideDrawer';
import TitledContainer from '../TitledContainer';
import { Body2 } from '../Typography/Typography';

const QuestionContainer = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    boxSizing: 'border-box',
    paddingBottom: spacing(2.5),
    marginBottom: spacing(2.5),
    borderBottom: '0.5px solid ' + getColor(EColors.bizlyOSBorder),
}));

const ExampleContainer = styled(Body2)(({ theme: { getColor, EColors, spacing, shape } }) => ({
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.bizlyOSPrimary, 0.1),
    padding: spacing(2.5),
    marginTop: spacing(2.5),
    borderRadius: shape.borderRadius,
}));

const HighlightsSideDrawerSchema = z.object({
    answer: z.string().min(8, 'Answer is required'),
});

type HighlightsSideDrawerFormData = z.infer<typeof HighlightsSideDrawerSchema>;

type HighlightsSideDrawerProps = {
    drawerOpen: boolean;
    onClose: () => void;
    selectedHighlight: Bizly.VenueHighlights;
    onSave: (anserr: string) => void;
};

function HighlightsSideDrawer({ drawerOpen, onClose, selectedHighlight, onSave }: HighlightsSideDrawerProps) {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isDirty },
        setValue,
        reset,
    } = useForm<HighlightsSideDrawerFormData>({
        resolver: zodResolver(HighlightsSideDrawerSchema),
        defaultValues: {
            answer: '',
        },
        mode: 'onChange',
    });
    const onSubmit = (data: HighlightsSideDrawerFormData) => {
        onSave(data.answer);
    };

    const onCancel = () => {
        onClose();
        reset();
    };

    useEffect(() => {
        setValue('answer', selectedHighlight?.content || '');
    }, [selectedHighlight, setValue]);

    return (
        <SideDrawer
            drawerOpen={drawerOpen}
            onClose={onCancel}
            title={selectedHighlight.name}
            footer={
                <DrawerActionButtons
                    onCancel={onCancel}
                    onSubmit={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    isValid={isValid && isDirty}
                />
            }
        >
            <Controller
                name="answer"
                control={control}
                render={({ field }) => {
                    return <RichTextEditorField {...field} error={errors.answer} />;
                }}
            />
            <ExampleContainer>
                <span style={{ fontWeight: '600' }}>Example:</span> {selectedHighlight?.example}
            </ExampleContainer>
        </SideDrawer>
    );
}

export default function ListingHighlights({ listing }: { listing: Bizly.Venue }) {
    const updateListingMutation = useUpdateListingMutation(listing.id.toString(), setSnackbarOpen);

    const [selectedHighlight, setSelectedHighlight] = useState<Bizly.VenueHighlights>();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({ type: '', message: '' });

    function handleSnackbarClose(_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function setSnackbarOpen(type?: string, message?: string) {
        setSnackbarMessage({ message: message || '', type: type || '' });
        setOpenSnackbar(true);
    }

    const onSelect = (qustion: Bizly.VenueHighlights) => {
        setSelectedHighlight(qustion);
        setDrawerOpen(true);
    };

    const onSave = (newAnswer: string) => {
        if (!selectedHighlight) return;

        const highlights = compact(
            listing.highlights.map(highlight => {
                if (highlight.id === selectedHighlight.id) {
                    return { category_id: highlight.id, content: newAnswer };
                } else if (highlight.content) {
                    return { category_id: highlight.id, content: highlight.content };
                }
                return null;
            })
        );

        updateListingMutation.mutate({
            google_place_id: listing.googlePlaceId,
            highlights: highlights,
        });
        setSelectedHighlight(undefined);
        setDrawerOpen(true);
    };

    return (
        <>
            <TitledContainer title="Highlights">
                {listing.highlights.map(highlight => {
                    const isAnswered = !!highlight.content;
                    return (
                        <QuestionContainer key={highlight.id}>
                            <Box display="flex" gap={1} alignItems="center" justifyContent="space-between">
                                <Box display="flex" gap={1} alignItems="center">
                                    <CheckCircleIcon
                                        sx={{
                                            color: isAnswered
                                                ? getColor(EColors.bizlyOSPrimary)
                                                : getColor(EColors.bizlyOSBorder),
                                            fontSize: '19px',
                                        }}
                                    />
                                    <Body2 fontWeight={500}>{highlight.name}</Body2>
                                </Box>
                                <Button variant="text" onClick={() => onSelect(highlight)}>
                                    {isAnswered ? 'Edit' : 'Answer'}
                                </Button>
                            </Box>
                            {isAnswered && (
                                <Box
                                    dangerouslySetInnerHTML={{ __html: highlight.content || '' }}
                                    sx={{ fontSize: '14px', '& > p': { margin: 0 } }}
                                />
                            )}
                        </QuestionContainer>
                    );
                })}
            </TitledContainer>
            {selectedHighlight && (
                <HighlightsSideDrawer
                    drawerOpen={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    selectedHighlight={selectedHighlight}
                    onSave={onSave}
                />
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{
                    '&.MuiSnackbar-root': { top: '65px', right: '20px' },
                }}
            >
                <Alert
                    severity="success"
                    icon={false}
                    variant="filled"
                    sx={{
                        width: '100%',
                        backgroundColor: snackbarMessage.type === 'success' ? getColor(EColors.bizlyOSPrimary) : 'red',
                    }}
                    onClose={handleSnackbarClose}
                >
                    {snackbarMessage.message}
                </Alert>
            </Snackbar>
        </>
    );
}
