import { Tooltip } from '@material-ui/core';
import { calculateBudgetStatus } from 'components/CompareModal/compareUtils';
import Button from 'components/ui/Button';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import styled from 'styled-components';
import { i18n } from 'translation';

const APPLE_EVENTS_URI = `https://meetingsandevents.apple.com/meeting-request/#/details`;

export const AcceptRejectButton = styled(Button)<{ bareStyle?: boolean }>`
    ${({ bareStyle }) =>
        bareStyle &&
        `
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    color: inherit;
    font-size: 0.875rem;
    width: initial;
  `}
    :disabled {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey2, 0.45)};
    }
`;
export const ProposalActionButton = ({
    proposal,
    expired,
    onAccept,
    eventBudget,
    showModal,
    hasRequested,
    buttonBareStyle = false,
}: {
    proposal: Bizly.VenueProposal;
    expired?: boolean;
    onAccept: () => void;
    eventBudget?: string | number;
    showModal: () => void;
    hasRequested?: boolean;
    buttonBareStyle?: boolean;
}) => {
    const { user } = useUser();
    const { event } = useEvent();

    const proposalRequest = proposal?.approvalRequests[0];

    const isRejected = Boolean(proposalRequest?.rejectedAt);
    const isApproved = Boolean(proposalRequest?.approvedBy);

    const { hasBudget, isAboveBudget } = calculateBudgetStatus(proposal, user, eventBudget?.toString());
    const eventRecordId = event.metadata?.recordId || '';

    const budgetBasedApprovalFeatureFlag = user.featureFlags?.budgetBasedApprovals;

    if (isRejected) {
        return (
            <Tooltip title={i18n.venue.inquiry.requestRejectedInfo}>
                <span>
                    <AcceptRejectButton onClick={() => {}} disabled width={150}>
                        {i18n.venue.inquiry.requestRejected}
                    </AcceptRejectButton>
                </span>
            </Tooltip>
        );
    }

    const handleRequestApproval = () => {
        if (eventRecordId) {
            window.location.href = `${APPLE_EVENTS_URI}/${eventRecordId}`;
        } else {
            showModal();
        }
    };

    if (budgetBasedApprovalFeatureFlag && hasBudget && isAboveBudget && !isApproved) {
        return (
            <AcceptRejectButton
                onClick={handleRequestApproval}
                width={hasRequested ? 160 : 150}
                disabled={hasRequested || expired}
                bareStyle={buttonBareStyle}
            >
                {hasRequested ? i18n.venue.inquiry.approvalRequested : i18n.venue.inquiry.requestApproval}
            </AcceptRejectButton>
        );
    }

    return (
        <AcceptRejectButton onClick={() => onAccept()} disabled={expired} bareStyle={buttonBareStyle}>
            {i18n.button.accept}
        </AcceptRejectButton>
    );
};
