import * as Sentry from '@sentry/react';
import iwgAxiosClient from '.';

export const searchLocations = async (keyword: string) => {
    try {
        const response = await iwgAxiosClient.get<IWG.LocationDTO[]>('/public/locations/api/v2/locations/search', {
            params: {
                'query.fullTextSearch': keyword,
                mode: 'Extended',
            },
        });
        return response.data;
    } catch (e) {
        console.error('IWG location error: ', e);
        Sentry.captureException(e, {
            tags: {
                component: 'searchLocations',
                keyword,
            },
            extra: {
                keyword,
            },
        });
    }
};
