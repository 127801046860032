import { Box, Step, Stepper, styled } from '@mui/material';
import { StepConnector, StepIcon, StepLabel } from 'components/BizlyOS/ListingsClaim/Stepper';
import { Body1, Heading1 } from 'components/BizlyOS/Typography/Typography';
import BizlyLogo from 'images/bizlyOS/bizly-logo.svg?react';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import ContactDetails from './ContactDetails';
import Successful from './Successful';
import VenueAbout from './VenueAbout';
import VenueAddress from './VenueAddress';
import VenueDataReview from './VenueDataReview';
import VenueName from './VenueName';
import VenueType from './VenueType';
import VenueWebsite from './VenueWebsite';

const STEPS = ['Your Contact Info', 'Venue Info', 'Confirm & Submit', 'Identity Verification'];

const ClaimSteps = [
    { Element: ContactDetails, stepperPositionIndex: 0 },
    { Element: VenueType, stepperPositionIndex: 1 },
    { Element: VenueName, stepperPositionIndex: 1 },
    { Element: VenueAddress, stepperPositionIndex: 1 },
    { Element: VenueWebsite, stepperPositionIndex: 1 },
    { Element: VenueAbout, stepperPositionIndex: 1 },
    { Element: VenueDataReview, stepperPositionIndex: 2 },
];

const LoginInfo = styled(Body1)({
    marginBottom: '70px',
    '& span': { fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' },
});

type ListingsCreateDataType = {
    venueAddress?: {
        name: string;
        placeId: string;
    };
    venueLocation?: {
        name: string;
        placeId: string;
    };
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    jobTitle?: string;
    department?: string;
    venueAbout?: string;
    venueName?: string;
    venueWebsite?: string;
    venueTypeId?: number;
    venueType?: string;
};

export type ListingsCreateStepProps = {
    onNextStepClicked: (data: ListingsCreateDataType) => void;
    onBackClicked: () => void;
    navigateToStep: (to?: string) => void;
    isLastStep: boolean;
    listingsCreateData: ListingsCreateDataType;
};

export default function ListingsCreate() {
    const [searchParams] = useSearchParams();
    const isVerified = searchParams.get('verified') === 'true';

    const [activeStep, setActiveStep] = React.useState(0);
    const [stepperActiveStep, setStepperActiveStep] = React.useState(0);
    const [listingsCreateData, setlistingsCreateData] = React.useState<ListingsCreateDataType>({
        venueName: searchParams.get('venue_name') || '',
        firstName: searchParams.get('firstName') || '',
        lastName: searchParams.get('lastName') || '',
        email: searchParams.get('email') || '',
        phone: searchParams.get('phone') || '',
        jobTitle: searchParams.get('jobTitle') || '',
        department: searchParams.get('department') || '',
    });

    const onNextStepClicked = (data: ListingsCreateDataType) => {
        const newData = { ...listingsCreateData, ...data };
        setlistingsCreateData(newData);
        if (activeStep < ClaimSteps.length) {
            const nextStepIndex = activeStep + 1;
            setActiveStep(nextStepIndex);
            if (nextStepIndex === ClaimSteps.length) {
                setStepperActiveStep(3);
            } else {
                setStepperActiveStep(ClaimSteps[nextStepIndex].stepperPositionIndex);
            }
        } else {
            // TODO: handel claim submit here..
        }
    };

    const onBackClicked = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const navigateToStep = (to?: string) => {
        //If 'to' is empty, this will navigate to the first step.
        let newActiveStep = 0;

        if (to === 'VenueType') {
            newActiveStep = 1;
        } else if (to === 'VenueAbout') {
            newActiveStep = 5;
        }

        setActiveStep(newActiveStep);
        setStepperActiveStep(ClaimSteps[newActiveStep].stepperPositionIndex);
    };

    useEffect(() => {
        if (isVerified) {
            setStepperActiveStep(STEPS.length);
        }
    }, [isVerified]);

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Box
                width="540px"
                boxSizing="border-box"
                height="100%"
                bgcolor={getColor(EColors.pureBlack)}
                padding={6.25}
            >
                <BizlyLogo />
                <Heading1 color={EColors.pureWhite} marginTop="40px" marginBottom="10px">
                    Apply to list on Bizly
                </Heading1>
                <Body1 color={EColors.pureWhite} marginBottom="20px">
                    <span style={{ fontWeight: 700 }}>We’re excited that you want to list your venue on Bizly.</span> We
                    just need to confirm some information about your business to make sure Bizly is the right fit for
                    you. It takes less than 5 minutes!
                </Body1>
                <LoginInfo color={EColors.pureWhite}>
                    If you already have a merchant account, <span>Log In</span>.
                </LoginInfo>
                <Stepper activeStep={stepperActiveStep} connector={<StepConnector />} orientation="vertical">
                    {STEPS.map((label, index) => (
                        <Step key={label} completed={index < stepperActiveStep}>
                            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            {isVerified ? (
                <Successful verified />
            ) : activeStep < ClaimSteps.length ? (
                React.createElement(ClaimSteps[activeStep].Element, {
                    onNextStepClicked,
                    onBackClicked,
                    isLastStep: activeStep + 1 === ClaimSteps.length,
                    listingsCreateData,
                    navigateToStep,
                })
            ) : (
                <Successful />
            )}
        </Box>
    );
}
