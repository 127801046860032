import { Box, Paper, Skeleton, Typography } from '@mui/material';
import AtmosphereIcon from 'images/icons/bizly-insights/atmosphere.svg?react';
import InsightsIcon from 'images/icons/bizly-insights/insight.svg?react';
import PricingIcon from 'images/icons/bizly-insights/pricing.svg?react';
import ReviewsIcon from 'images/icons/bizly-insights/reviews.svg?react';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { EColors, getColor } from 'theme';

export type InsightKey = 'atmosphere' | 'pricing' | 'reviews' | string;
export type PollingState = 'in_progress' | 'completed' | string | undefined;

interface RawInsightItem {
    key?: string;
    section?: string;
    content?: string;
}

interface ValidatedInsightItem {
    key: InsightKey;
    section: string;
    content: string;
}

interface DisplayedContentItem {
    type: 'p' | 'li';
    text: string;
}

interface StreamingTextProps {
    content: string;
    onComplete?: () => void;
    speed?: number;
}

interface InsightsSectionProps {
    section: string;
    content: string;
    isStreaming: boolean;
    onComplete?: () => void;
    sectionKey: InsightKey;
    speed?: number;
}

interface BizlyInsightsProps {
    pollingState?: PollingState;
    insights?: RawInsightItem[];
    speed?: number;
}

const DEFAULT_INSIGHT = {
    key: 'unknown',
    section: 'Additional Information',
    content: 'No content available',
};

const isHTML = (str: string): boolean => {
    return /<[a-z][\s\S]*>/i.test(str);
};

const validateInsight = (item: RawInsightItem): ValidatedInsightItem => {
    return {
        key: item.key || DEFAULT_INSIGHT.key,
        section: item.section || DEFAULT_INSIGHT.section,
        content: item.content || DEFAULT_INSIGHT.content,
    };
};

const getSectionIcon = (key: InsightKey): ReactElement | null => {
    const icons: Record<string, ReactElement> = {
        atmosphere: <AtmosphereIcon />,
        pricing: <PricingIcon />,
        reviews: <ReviewsIcon />,
    };

    const noIcon = <></>;

    return icons[key] || noIcon;
};

const StreamingText = ({ content, onComplete, speed = 20 }: StreamingTextProps) => {
    const [displayedContent, setDisplayedContent] = useState<DisplayedContentItem[]>([]);
    const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
    const [currentCharIndex, setCurrentCharIndex] = useState<number>(0);
    const [parsedContent, setParsedContent] = useState<DisplayedContentItem[]>([]);

    useEffect(() => {
        if (!content) return;

        if (isHTML(content)) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const items = Array.from(doc.querySelectorAll('p, li')).map(element => ({
                type: element.tagName.toLowerCase() as 'p' | 'li',
                text: element.textContent || '',
            }));
            setParsedContent(items);
        } else {
            setParsedContent([{ type: 'p', text: content }]);
        }

        setDisplayedContent([]);
        setCurrentItemIndex(0);
        setCurrentCharIndex(0);
    }, [content]);

    useEffect(() => {
        if (!parsedContent.length || currentItemIndex >= parsedContent.length) return;

        const currentItem = parsedContent[currentItemIndex];
        let isActive = true;

        const interval = setInterval(() => {
            if (!isActive) return;

            if (currentCharIndex < currentItem.text.length) {
                setCurrentCharIndex(prev => prev + 1);
                setDisplayedContent(prev => {
                    const newItems = [...prev];
                    if (currentItemIndex >= newItems.length) {
                        newItems.push({ type: currentItem.type, text: '' });
                    }
                    newItems[currentItemIndex] = {
                        type: currentItem.type,
                        text: currentItem.text.slice(0, currentCharIndex + 1),
                    };
                    return newItems;
                });
            } else {
                clearInterval(interval);
                if (currentItemIndex < parsedContent.length - 1) {
                    setCurrentItemIndex(prev => prev + 1);
                    setCurrentCharIndex(0);
                } else if (isActive) {
                    onComplete?.();
                }
            }
        }, speed);

        return () => {
            isActive = false;
            clearInterval(interval);
        };
    }, [parsedContent, currentItemIndex, currentCharIndex, onComplete, speed]);

    const renderContent = () => {
        const result: JSX.Element[] = [];
        let currentList: DisplayedContentItem[] = [];

        displayedContent.forEach((item, index) => {
            if (item.type === 'li') {
                currentList.push(item);
                if (index === displayedContent.length - 1 || displayedContent[index + 1]?.type !== 'li') {
                    result.push(
                        <ul key={`list-${index}`} style={{ marginTop: '.5rem' }}>
                            {currentList.map((listItem, listIndex) => (
                                <li key={`li-${index}-${listIndex}`}>{listItem.text}</li>
                            ))}
                        </ul>
                    );
                    currentList = [];
                }
            }
            if (item.type === 'p') {
                result.push(<p key={`p-${index}`}>{item.text}</p>);
            }
        });

        return result;
    };

    return <Box>{renderContent()}</Box>;
};

const LoadingAnimation = () => (
    <Box sx={{ mt: 2 }}>
        {[...Array(3)].map((_, i) => (
            <Skeleton
                key={i}
                animation="wave"
                height={24}
                sx={{
                    mb: 1,
                    backgroundColor: '#90caf933',
                    '&::after': {
                        background: 'linear-gradient(90deg, transparent, #90caf966, transparent)',
                    },
                }}
            />
        ))}
    </Box>
);

const InsightsSection = ({ section, content, isStreaming, onComplete, sectionKey, speed }: InsightsSectionProps) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!isStreaming && content) {
            setIsVisible(true);
            return;
        }

        if (isStreaming) {
            const timer = setTimeout(() => setIsVisible(true), 100);
            return () => clearTimeout(timer);
        }
    }, [isStreaming, content]);

    const SectionIcon = getSectionIcon(sectionKey);

    return (
        <Box sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1.5,
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 1s ease',
                }}
            >
                {SectionIcon}
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: getColor(EColors.pureBlack),
                    }}
                >
                    {section}
                </Typography>
            </Box>
            <Box>
                {isStreaming ? (
                    <StreamingText content={content} onComplete={onComplete} speed={speed} />
                ) : (
                    <Box
                        sx={{ '& > ul': { marginTop: '.5rem' } }}
                        dangerouslySetInnerHTML={{ __html: isHTML(content) ? content : `<p>${content}</p>` }}
                    />
                )}
            </Box>
        </Box>
    );
};
export const BizlyInsights = ({ pollingState, insights = [], speed }: BizlyInsightsProps) => {
    const [currentStreamingIndex, setCurrentStreamingIndex] = useState<number>(0);
    const isComplete = pollingState === 'completed';

    const validatedInsights = useMemo(() => insights.map(validateInsight), [insights]);

    const handleSectionComplete = () => {
        if (currentStreamingIndex < validatedInsights.length - 1) {
            setCurrentStreamingIndex(prev => prev + 1);
        }
    };

    return (
        <Paper
            elevation={0}
            sx={{
                p: 2,
                backgroundColor: '#3478F71A',
                borderRadius: 2,
                mb: 2,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <InsightsIcon />
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 600,
                        color: getColor(EColors.pureBlack),
                        ml: 1.5,
                    }}
                >
                    Bizly Insights
                </Typography>
            </Box>

            {!isComplete && !validatedInsights.length ? (
                <LoadingAnimation />
            ) : (
                <Box>
                    {validatedInsights.slice(0, currentStreamingIndex + 1).map((item, index) => (
                        <InsightsSection
                            key={index}
                            section={item.section}
                            content={item.content}
                            sectionKey={item.key}
                            isStreaming={index === currentStreamingIndex}
                            onComplete={handleSectionComplete}
                            speed={speed}
                        />
                    ))}
                </Box>
            )}
        </Paper>
    );
};
