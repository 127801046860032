import CastleOutlinedIcon from '@mui/icons-material/CastleOutlined';
import ChairAltIcon from '@mui/icons-material/ChairAlt';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import KingBedOutlinedIcon from '@mui/icons-material/KingBedOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SportsBarOutlinedIcon from '@mui/icons-material/SportsBarOutlined';
import { Box, styled } from '@mui/material';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import StepItemsContainer from 'components/BizlyOS/ListingsClaim/StepItemsContainer';
import { Body2 } from 'components/BizlyOS/Typography/Typography';
import { Spinner } from 'components/Spinner';
import { useGetListingMetaQuery } from 'hooks/queries/BizlyOS/useListingQuery';
import PeopleIcon from 'images/bizlyOS/icons/chart-people.svg?react';
import GrapesIcon from 'images/bizlyOS/icons/grapes.svg?react';
import RedCarpetIcon from 'images/bizlyOS/icons/red-carpet.svg?react';
import MaskIcon from 'images/bizlyOS/icons/theater-mask.svg?react';
import { useState } from 'react';
import { EColors, getColor } from 'theme';
import { ListingsCreateStepProps } from './ListingsCreate';

const VENUE_TYPES = [
    {
        Icon: KingBedOutlinedIcon,
        name: 'Hotel',
    },
    {
        Icon: RestaurantIcon,
        name: 'Restaurant',
    },
    {
        Icon: LocalActivityOutlinedIcon,
        name: 'Activity',
    },
    {
        Icon: PeopleIcon,
        name: 'Conference Center',
    },
    {
        Icon: ChairAltIcon,
        name: 'Bookable Office',
    },
    {
        Icon: LocalBarOutlinedIcon,
        name: 'Bar',
    },
    {
        Icon: SportsBarOutlinedIcon,
        name: 'Brewery',
    },
    {
        Icon: GrapesIcon,
        name: 'Winery',
    },
    {
        Icon: ColorLensOutlinedIcon,
        name: 'Museum',
    },
    {
        Icon: MaskIcon,
        name: 'Theater',
    },
    {
        Icon: CastleOutlinedIcon,
        name: 'Unique Venue',
    },
    {
        Icon: RedCarpetIcon,
        name: 'Event Venue',
    },
];

const GridContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(4, 68px)',
    gap: spacing(1.25),
}));

const GridItem = styled(Box)(({ theme: { getColor, EColors, spacing, shape }, selected = false }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid ',
    borderColor: selected ? getColor(EColors.bizlyOSPrimary) : getColor(EColors.bizlyOSBorder),
    padding: spacing(1.25),
    borderRadius: shape.borderRadius,
    cursor: 'pointer',
}));

export default function VenueType({ onNextStepClicked, listingsCreateData, ...props }: ListingsCreateStepProps) {
    const { data: venueMeta, isLoading: isListingMetaLoading } = useGetListingMetaQuery();

    const [venueId, setVenueId] = useState<number>(listingsCreateData?.venueTypeId || 0);

    const onSubmit = () => {
        const type = venueMeta?.venueTypes.find(t => t.id === venueId);
        if (type) {
            onNextStepClicked({ venueTypeId: venueId, venueType: type.name });
        }
    };

    return (
        <ClaimStepContainer {...props} onSave={onSubmit} disabled={!venueId} contentCentered>
            <StepItemsContainer
                title="What type of venue you want to list on Bizly?"
                description="This will help event planners match with your venue and services. We also use this information to determine if Bizly is right for you."
            >
                <GridContainer>
                    {!isListingMetaLoading ? (
                        VENUE_TYPES.map((type, index) => {
                            const _type = venueMeta?.venueTypes.find(t => t.name === type.name);
                            const { Icon } = type;
                            return (
                                <GridItem
                                    key={index}
                                    selected={_type?.id === venueId}
                                    onClick={() => {
                                        setVenueId(_type?.id || 0);
                                    }}
                                >
                                    <Icon sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                                    <Body2 lineHeight="16px" textAlign="center">
                                        {type.name}
                                    </Body2>
                                </GridItem>
                            );
                        })
                    ) : (
                        <Box gridColumn={2}>
                            <Spinner />
                        </Box>
                    )}
                </GridContainer>
            </StepItemsContainer>
        </ClaimStepContainer>
    );
}
