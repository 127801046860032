import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import TitledContainer from 'components/BizlyOS/TitledContainer';
import { Body1, Body2, Heading2 } from 'components/BizlyOS/Typography/Typography';
import { EColors, getColor } from 'theme';
import { ListingsCreateStepProps } from './ListingsCreate';

const ContainerWrapper = styled(Box)(({ theme: { getColor, EColors } }) => ({
    boxSizing: 'border-box',
    border: '1px solid ' + getColor(EColors.bizlyOSBorder),
    borderRadius: '12px',
    maxHeight: '100%',
    height: '100%',
    overflow: 'hidden',
}));

const Container = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    width: '600px',
    boxSizing: 'border-box',
    backgroundColor: getColor(EColors.pureWhite),
    padding: spacing(3.75),
    maxHeight: '100%',
    overflowY: 'scroll',
}));

const GridContainer = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(3, 1fr)',
    gap: spacing(1.25),
}));

function Item({ title, value, columnSpan = 1 }: { value: string; title: string; columnSpan?: number }) {
    return (
        <Box bgcolor={getColor(EColors.drWhite)} borderRadius={0.5} padding={1.25} gridColumn={'span ' + columnSpan}>
            <Body2 color={EColors.tagColor}>{title}</Body2>
            <Body2 marginTop={0.5}>{value}</Body2>
        </Box>
    );
}

export default function VenueDataReview({
    onNextStepClicked,
    listingsCreateData,
    navigateToStep,
    ...props
}: ListingsCreateStepProps) {
    const onSubmit = () => {
        onNextStepClicked(listingsCreateData);
    };

    return (
        <ClaimStepContainer {...props} onSave={onSubmit} disabled={false}>
            <ContainerWrapper>
                <Container>
                    <Heading2 marginBottom={1.25}>Great! Final review</Heading2>
                    <Body1 marginBottom={3.75}>
                        Make sure the information below is accurate before submitting your application to list your
                        venue on Bizly.
                    </Body1>
                    <Box display="flex" flexDirection="column" gap={2.5}>
                        <TitledContainer
                            title="Your Contact Details"
                            headerHeight="40px"
                            rightElement={
                                <Button
                                    sx={{ padding: 0, minWidth: 36 }}
                                    variant="text"
                                    onClick={() => navigateToStep()}
                                >
                                    Edit
                                </Button>
                            }
                        >
                            <GridContainer>
                                <Item title="Your First Name" value={listingsCreateData.firstName || ''} />
                                <Item title="Your Last Name" value={listingsCreateData.lastName || ''} />
                                <Item title="Your Email" value={listingsCreateData.email || ''} />
                                <Item title="Your Phone Number" value={listingsCreateData.phone || ''} />
                                <Item title="Your Job Title" value={listingsCreateData.jobTitle || ''} />
                                <Item title="Your Department" value={listingsCreateData.department || ''} />
                            </GridContainer>
                        </TitledContainer>

                        <TitledContainer
                            title="Venue Details"
                            headerHeight="40px"
                            rightElement={
                                <Button
                                    sx={{ padding: 0, minWidth: 36 }}
                                    variant="text"
                                    onClick={() => navigateToStep('VenueType')}
                                >
                                    Edit
                                </Button>
                            }
                        >
                            <GridContainer>
                                <Item title="Venue Name" value={listingsCreateData.venueName || ''} />
                                <Item title="Venue Type" value={listingsCreateData.venueType || ''} />
                                <Item
                                    title="Venue Address"
                                    value={listingsCreateData.venueAddress?.name || ''}
                                    columnSpan={2}
                                />
                                <Item
                                    title="Venue Website"
                                    value={listingsCreateData.venueWebsite || ''}
                                    columnSpan={2}
                                />
                            </GridContainer>
                        </TitledContainer>

                        {listingsCreateData.venueAbout && (
                            <TitledContainer
                                title="Special Notes"
                                headerHeight="40px"
                                rightElement={
                                    <Button
                                        sx={{ padding: 0, minWidth: 36 }}
                                        variant="text"
                                        onClick={() => navigateToStep('VenueAbout')}
                                    >
                                        Edit
                                    </Button>
                                }
                            >
                                <Box
                                    dangerouslySetInnerHTML={{ __html: listingsCreateData.venueAbout }}
                                    sx={{ fontSize: '14px', '& > p': { margin: 0 } }}
                                    bgcolor={getColor(EColors.drWhite)}
                                    borderRadius={0.5}
                                    padding={1.25}
                                />
                            </TitledContainer>
                        )}
                    </Box>
                </Container>
            </ContainerWrapper>
        </ClaimStepContainer>
    );
}
