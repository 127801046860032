import { useState } from 'react';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { Moment } from 'moment';
import { EFonts, getFontStyle } from 'theme';
import { i18n } from 'translation';
import { EventCreateForm } from '../formSchema';
import { Description, FieldTitle, StepContainer, Title } from './styled';

type PropType = {
    value: EventCreateForm['eventDate'];
    onChange: (value: EventCreateForm['eventDate']) => void;
};

const Step4 = ({ value, onChange }: PropType) => {
    const [isStartEndDateSet, setIsStartEndDateSet] = useState(false);

    const setDefaultStartDate = (endDate: Moment) => {
        return endDate.clone().subtract(3, 'hours');
    };

    const setDefaultEndDate = (startDate: Moment) => {
        const endDate = startDate.clone().add(3, 'hours');
        if (endDate.hour() >= 24) {
            return endDate.add(1, 'day').startOf('day');
        }
        return endDate;
    };

    const onStartDateChange = (newValue: Moment | null) => {
        if (newValue) {
            let newEndDate = value?.end;

            if (!isStartEndDateSet) {
                newEndDate = setDefaultEndDate(newValue);
                setIsStartEndDateSet(true);
            }

            onChange({
                ...value,
                start: newValue,
                end: newEndDate,
            });
        } else {
            setIsStartEndDateSet(false);
            onChange({
                ...value,
                start: null,
                end: null,
            });
        }
    };

    const onEndDateChange = (newValue: Moment | null) => {
        if (newValue) {
            if (newValue.isBefore(value?.start)) {
                return;
            }

            let newStartDate = value?.start;

            if (!isStartEndDateSet) {
                newStartDate = setDefaultStartDate(newValue);
                setIsStartEndDateSet(true);
            }

            onChange({
                ...value,
                start: newStartDate,
                end: newValue,
            });
        }
    };

    return (
        <StepContainer>
            <Title>{i18n.homepage.createMeetingModal.dateTitle}</Title>
            <Description>{i18n.homepage.createMeetingModal.youCanEditThisLater}</Description>
            <Spacer />
            <Box display="flex" gap={3}>
                <Box>
                    <FieldTitle thin>Start date and time</FieldTitle>
                    <DateTimePicker
                        value={value?.start}
                        onChange={onStartDateChange}
                        disablePast
                        slotProps={{
                            textField: {
                                InputProps: {
                                    style: { ...getFontStyle(EFonts.body) },
                                },
                            },
                        }}
                    />
                </Box>
                <Box>
                    <FieldTitle thin>End date and time</FieldTitle>
                    <DateTimePicker
                        value={value?.end}
                        onChange={onEndDateChange}
                        shouldDisableDate={(day) => value?.start ? day.isBefore(value.start) : false}
                        slotProps={{
                            textField: {
                                InputProps: {
                                    style: { ...getFontStyle(EFonts.body) },
                                },
                            },
                        }}
                    />
                </Box>
            </Box>
        </StepContainer>
    );
};

export default Step4;
