import {
    AccessTimeOutlined,
    BusinessCenterOutlined,
    CalendarTodayOutlined,
    LocationOnOutlined,
    PeopleOutlined,
} from '@mui/icons-material';
import { Box, Divider, styled } from '@mui/material';
import { TToken } from 'components/Tokenizer';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import VenueMap from 'components/VenueMap';
import NotificationsHeader from 'components/Workspace/NotificationsHeader';
import SearchDrawer from 'components/Workspace/SearchDrawer';
import SearchFiltersRow from 'components/Workspace/SearchFiltersRow';
import SearchResultsHeader from 'components/Workspace/SearchResultsHeader';
import VenueList from 'components/Workspace/VenueList';
import VenueListingModal from 'components/Workspace/VenueListingModal';
import { useState } from 'react';

export type VenueCardProps = {
    address: string;
    location: string;
    features: string[];
    rates: string;
    image: string;
};

const Container = styled(Box)({
    width: '100%',
    maxWidth: '1360px',
    margin: '0 auto',
});

const MainContent = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
});

const MainContentBox = styled(Box)({
    flex: 1,
    width: '50%',
});

const FittedVenueMap = styled(VenueMap)`
    position: relative;
    width: 100%;
    height: 100%;

    > [class*='VenueMap__MapContainer'] {
        margin: 0;
        height: 100%;
    }
`;

const resultsData = [
    { icon: <LocationOnOutlined fontSize="small" />, text: 'Miami, FL' },
    { icon: <BusinessCenterOutlined fontSize="small" />, text: 'Private Office' },
    { icon: <PeopleOutlined fontSize="small" />, text: '12' },
    { icon: <CalendarTodayOutlined fontSize="small" />, text: 'Sep 12, 2024' },
    { icon: <AccessTimeOutlined fontSize="small" />, text: '10:00 AM - 11:00 AM' },
];
const dummyVenuesData: VenueCardProps[] = [
    {
        address: 'Regus Brickell Bayview Center 1',
        location: '1221 Brickell Avenue, Suite 900, Miami, Florida, 33131',
        features: ['Parking', 'Major Transport Links', 'Disabled Facilities', 'Breakout Area'],
        rates: '120',
        image: 'https://picsum.photos/id/635/180',
    },
    {
        address: 'Regus Brickell Bayview Center 2',
        location: '1221 Brickell Avenue, Suite 900, Miami, Florida, 33131',
        features: ['Parking', 'Major Transport Links', 'Disabled Facilities', 'Breakout Area'],
        rates: '120',
        image: 'https://picsum.photos/id/635/180',
    },
    {
        address: 'Regus Brickell Bayview Center 3',
        location: '1221 Brickell Avenue, Suite 900, Miami, Florida, 33131',
        features: ['Parking', 'Major Transport Links', 'Disabled Facilities', 'Breakout Area'],
        rates: '120',
        image: 'https://picsum.photos/id/635/180',
    },
    {
        address: 'Regus Brickell Bayview Center 4',
        location: '1221 Brickell Avenue, Suite 900, Miami, Florida, 33131',
        features: ['Parking', 'Major Transport Links', 'Disabled Facilities', 'Breakout Area'],
        rates: '120',
        image: 'https://picsum.photos/id/635/180',
    },
    {
        address: 'Regus Brickell Bayview Center 5',
        location: '1221 Brickell Avenue, Suite 900, Miami, Florida, 33131',
        features: ['Parking', 'Major Transport Links', 'Disabled Facilities', 'Breakout Area'],
        rates: '120',
        image: 'https://picsum.photos/id/635/180',
    },
];
const dummyFilters: TToken[] = [
    { id: 1, label: 'Elevator' },
    { id: 2, label: 'Lounge Area' },
    { id: 3, label: 'On-Site Lunch Restaurants' },
    { id: 4, label: 'Parking' },
    { id: 5, label: '24 Hour CCTV Monitoring' },
    { id: 6, label: 'Day Care' },
    { id: 7, label: 'Showers' },
    { id: 8, label: 'Elevator' },
    { id: 9, label: 'Lounge Area' },
    { id: 10, label: 'On-Site Lunch Restaurants' },
    { id: 11, label: 'Parking' },
    { id: 12, label: '24 Hour CCTV Monitoring' },
    { id: 13, label: 'Day Care' },
    { id: 14, label: 'Showers' },
];

export default function VenueSearch() {
    //TODO should we use state, context or zustand?
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showMap, setShowMap] = useState(true);
    const [activeFilters, setActiveFilters] = useState<TToken[]>(dummyFilters);
    const [venueListingModalOpen, setVenueListingModalOpen] = useState(false);
    const [selectedVenue, setSelectedVenue] = useState<VenueCardProps | null>(null);

    const handleDeleteFilter = (filter: TToken) => {
        setActiveFilters(prevFilters => prevFilters.filter(f => f.id !== filter.id));
    };

    const handleEditSearchClick = () => {
        setDrawerOpen(true);
    };

    const handleVenueCardClick = (venue: VenueCardProps) => {
        setSelectedVenue(venue);
        setVenueListingModalOpen(true);
    };

    return (
        <Box>
            <Container>
                <NotificationsHeader />
            </Container>

            <Divider />

            <Container>
                <Spacer small />
                <SearchResultsHeader onEditSearchClick={handleEditSearchClick} resultsData={resultsData} />
                <Spacer small />
                <SearchFiltersRow
                    filters={activeFilters}
                    showMap={showMap}
                    onToggleMap={() => setShowMap(!showMap)}
                    onDeleteFilter={handleDeleteFilter}
                />
                <Spacer small />
            </Container>

            <Divider />

            <Container>
                <MainContent>
                    <MainContentBox>
                        <Spacer />
                        <VenueList venuesData={dummyVenuesData} onVenueCardClick={handleVenueCardClick} />
                    </MainContentBox>
                    <MainContentBox>
                        {showMap && <FittedVenueMap fullMapScale venues={[]} defaultZoom={15} />}
                    </MainContentBox>
                </MainContent>
            </Container>

            <SearchDrawer initialWorkspaceType="meeting_rooms" open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <VenueListingModal
                open={venueListingModalOpen}
                onClose={() => setVenueListingModalOpen(false)}
                venue={selectedVenue}
            />
        </Box>
    );
}
