import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, TextField } from '@mui/material';
import Autocomplete, { AutocompleteRenderOptionState, AutocompleteValue } from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { findCities } from 'api';
import CheckMarkSvg from 'images/icons/check-mark.svg?react';
import { i18n } from 'translation';
import { Copy } from 'ui';

const CheckMarkIcon = styled(CheckMarkSvg)`
    margin-left: 12px;
`;

const SuggestionText = styled(Copy).attrs({ regular: true })`
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
`;

export type TLocationOption = {
    description: string;
    place_id: string;
};

type TIWGLocationAutocompleteProps = {
    clearable?: boolean;
    onRemove?: () => void;
    value?: TLocationOption;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    onChange: (value: TLocationOption) => void;
};

const IWGLocationAutocomplete = (props: TIWGLocationAutocompleteProps) => {
    const { onChange, clearable, onRemove, value, disabled, placeholder, required } = props;

    const [inputQuery, setInputQuery] = useState<string>(value?.description ?? '');
    const isSelectedText = useRef(false);
    const [options, setOptions] = useState<TLocationOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [noOptionsText, setNoOptionsText] = useState(i18n.venue.noLocationsForQuery(inputQuery ?? ''));

    const resetOptions = () => {
        setLoading(false);
        setOptions([]);
    };

    // Debounce the API call to avoid excessive requests
    const debouncedFetch = useMemo(
        () =>
            debounce(async (query: string) => {
                setNoOptionsText(i18n.venue.noLocationsForQuery(query || ''));
                if (query.length > 0) {
                    setLoading(true);
                    try {
                        const response = await findCities(query);
                        if (response) {
                            setOptions([...response.predictions]);
                        } else {
                            resetOptions();
                        }
                    } catch {
                        setNoOptionsText(i18n.error.default);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    resetOptions();
                }
            }, 300),
        []
    );

    useEffect(() => {
        // Call the debounced function whenever the input value changes
        if (!isSelectedText.current) {
            debouncedFetch(inputQuery);
        }

        // Cleanup function to cancel any pending debounced calls on component unmount
        return () => {
            debouncedFetch.cancel();
        };
    }, [inputQuery, debouncedFetch]);

    const handleSelection = (newValue: TLocationOption) => {
        setInputQuery(newValue.description);
        isSelectedText.current = true; // prevent api call when the input field is updated by selection
        onChange?.(newValue);
    };

    function handleClear() {
        resetOptions();
        if (clearable) onRemove?.();
    }

    const onInputChange = (_: React.SyntheticEvent, newInputQuery: string) => {
        setInputQuery(newInputQuery);
        isSelectedText.current = false;
        if (!newInputQuery) {
            handleClear();
        }
    };

    const onSelectionChange = (
        e: React.SyntheticEvent,
        val: AutocompleteValue<TLocationOption, false, false, true>
    ) => {
        if (!e || !val || typeof val === 'string') {
            handleClear();
            return;
        }
        handleSelection(val);
    };

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TLocationOption,
        state: AutocompleteRenderOptionState
    ) => {
        return (
            <li {...props} key={option.place_id}>
                <Box display="flex" alignItems="center" justifyContent="center" flex={1} p={0.5}>
                    <SuggestionText>{option.description}</SuggestionText>
                    {state.selected && <CheckMarkIcon />}
                </Box>
            </li>
        );
    };

    return (
        <Autocomplete
            freeSolo
            disabled={disabled}
            loading={loading}
            disableClearable={!clearable}
            options={options}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            renderOption={renderOption}
            noOptionsText={noOptionsText}
            inputValue={inputQuery}
            onInputChange={onInputChange}
            value={value}
            filterOptions={x => x}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder ?? `${i18n.homepage.createMeetingModal.find} (required)`}
                    variant="outlined"
                    fullWidth
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            onChange={onSelectionChange}
        />
    );
};

export default IWGLocationAutocomplete;
