import { zodResolver } from '@hookform/resolvers/zod';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Button } from 'components/Ui-V2/Button/Button';
import TextArea from 'components/Ui-V2/InputFields/TextArea';
import TextField from 'components/Ui-V2/InputFields/TextField';
import { H2Headline } from 'components/ui/Headline';
import { Controller, useForm } from 'react-hook-form';
import { i18n } from 'translation';
import { Column } from 'ui';
import * as z from 'zod';
import { ModalContentType } from './TitleInputModal';

const WebsiteSchema = z.object({
    title: z.string().min(1, 'Title is required'),
    instruction: z.string().optional(),
});

type WebsiteData = z.infer<typeof WebsiteSchema>;

const WebsiteModalContent = ({ modalTitle, onClose, handleSubmit }: ModalContentType) => {
    const {
        control,
        handleSubmit: _handleSubmit,
        formState: { errors, isValid },
    } = useForm<WebsiteData>({
        resolver: zodResolver(WebsiteSchema),
        defaultValues: {
            title: '',
        },
        mode: 'onChange',
    });

    const onSubmit = (data: WebsiteData) => {
        handleSubmit(data.title);
    };

    return (
        <Column itemSpacing="smallish">
            <H2Headline>{modalTitle}</H2Headline>
            <Controller
                name="title"
                control={control}
                render={({ field }) => {
                    return (
                        <TextField
                            {...field}
                            error={errors.title}
                            label="Title"
                            placeholder="Example: Senior Leadership Web Page"
                            required
                        />
                    );
                }}
            />

            <Controller
                name="instruction"
                control={control}
                render={({ field }) => {
                    return (
                        <TextArea
                            {...field}
                            error={errors.instruction}
                            label="Additional Instruction"
                            placeholder="Example: Make the invite exciting and semi-casual. "
                            minRows={3}
                        />
                    );
                }}
            />

            <Column itemSpacing="xsmall">
                <Button
                    fullWidth
                    startIcon={<AutoAwesomeIcon />}
                    onClick={() => _handleSubmit(onSubmit)}
                    disabled={!isValid}
                >
                    Create Website
                </Button>
                <Button fullWidth variant="text" onClick={onClose}>
                    {i18n.button.cancel}
                </Button>
            </Column>
        </Column>
    );
};

export default WebsiteModalContent;
