import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import FiltersButton from 'components/FilterButton';
import Tokenizer from 'components/Tokenizer';
import { useVenueSearch } from 'hooks/venueSearchHooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import { usePlaybooks } from 'stores/playbooks';
import { i18n } from 'translation';
import { useUser } from '../../providers/user';
import { Copy, Switch } from '../../ui';
import { getTokens } from './TokenUtils';
import VenueSearchFilters, { TFilterValue } from './VenueSearchFilters';

const ViewMapContainer = styled(Box)`
    display: flex;
    align-items: center;
    height: 3.13rem;
`;

export const TokenizerWrapper = styled(Box)<{ hasTokens: boolean; isOverflow: boolean; hasVenues: boolean }>`
    position: relative;
    ${({ hasVenues }) =>
        hasVenues
            ? `max-width: calc(100% - 280px); // filter button 110px, map toggle 130px, 20px padding`
            : `max-width: calc(100% - 130px); // filter button 110px, map toggle 130px, 20px padding`}
    ${({ hasTokens, theme }) =>
        hasTokens &&
        `
            border-left: 1px solid ${theme.getColor(theme.EColors.grey)};
        `}
    ${({ isOverflow, theme }) =>
        isOverflow &&
        `
            border-right: 1px solid ${theme.getColor(theme.EColors.grey)};
        `}
`;

export const TokenizerContainer = styled(Box)`
    display: flex;
    width: 100%;
    overflow-x: auto;
    box-sizing: content-box;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
`;

export const ScrollButton = styled(IconButton)<{ show: boolean }>`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    cursor: pointer;
    z-index: 10;
    padding: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    &.left {
        left: 2px;
        display: ${({ show }) => (show ? 'flex' : 'none')};
    }

    &.right {
        right: 2px;
        display: ${({ show }) => (show ? 'flex' : 'none')};
    }
`;

type TFiltersRow = {
    event?: Bizly.Event;
    filters: TFilterValue;
    setFilters: (newFilters: TFilterValue) => void;
    showMap: boolean;
    onToggleMap: (e: React.ChangeEvent<HTMLInputElement>) => void;
    latLng?: Bizly.Location;
};

export default function FiltersPanel({ event, filters, setFilters, showMap, onToggleMap, latLng }: TFiltersRow) {
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);
    const [showScrollButtons, setShowScrollButtons] = useState({ left: false, right: false });
    const containerRef = useRef<HTMLDivElement>(null);

    const { user } = useUser();
    const { playbookOptions: { venueBrands = [] } = {} } = usePlaybooks();
    const { distance: distanceUnit } = useMeasurementUnits();
    const { results } = useVenueSearch(filters, event?.id, latLng);

    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);
    const brandRestrictions = useMemo(
        () =>
            event?.venueSearchParameters &&
            event.venueSearchParameters.isLocked &&
            event.venueSearchParameters.brandIds &&
            event.venueSearchParameters.brandIds.length > 0
                ? new Set(event.venueSearchParameters.brandIds)
                : undefined,
        [event]
    );

    const toggleFiltersMenu = useCallback(() => setShowFiltersMenu(prevShowFiltersMenu => !prevShowFiltersMenu), []);

    const tokens = useMemo(
        () =>
            getTokens(filters, setFilters, {
                teamName,
                unit: distanceUnit === units.meter ? units.kilometer : units.mile,
                brands: venueBrands,
                disabledBrands: !!brandRestrictions,
            }),
        [filters, setFilters, distanceUnit, venueBrands, teamName, brandRestrictions]
    );

    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const { scrollWidth, clientWidth, scrollLeft } = container;
            setShowScrollButtons({
                left: scrollLeft > 0,
                right: scrollWidth > clientWidth + scrollLeft,
            });
        }
    };

    useEffect(() => {
        handleScroll();
    }, [filters]);

    useEffect(() => {
        window.addEventListener('resize', handleScroll);
        const container = containerRef.current;
        container?.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleScroll);
            container?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                <FiltersButton toggleFiltersMenu={toggleFiltersMenu} filterCount={tokens.length} />
                <TokenizerWrapper
                    display="flex"
                    flex={1}
                    hasTokens={tokens.length > 0}
                    isOverflow={
                        containerRef.current && containerRef.current.scrollWidth > containerRef.current.clientWidth
                    }
                    hasVenues={results && results?.length > 0}
                >
                    <TokenizerContainer ref={containerRef}>
                        <Copy>
                            <Tokenizer tokens={tokens} />
                        </Copy>
                    </TokenizerContainer>
                    {showScrollButtons.left && (
                        <ScrollButton
                            variant="outlined"
                            className="left"
                            onClick={scrollLeft}
                            show={showScrollButtons.left}
                        >
                            <KeyboardArrowLeftIcon />
                        </ScrollButton>
                    )}
                    {showScrollButtons.right && (
                        <ScrollButton
                            variant="text"
                            className="right"
                            onClick={scrollRight}
                            show={showScrollButtons.right}
                        >
                            <KeyboardArrowRightIcon />
                        </ScrollButton>
                    )}
                </TokenizerWrapper>
                {results && results.length > 0 && (
                    <ViewMapContainer gap={2}>
                        <Copy style={{ minWidth: 'fit-content' }}>{i18n.venue.viewMap}</Copy>
                        <Switch checked={showMap} onChange={onToggleMap} value="showMap" />
                    </ViewMapContainer>
                )}
            </Box>
            <VenueSearchFilters
                filters={filters}
                onFilterChange={setFilters}
                open={showFiltersMenu}
                onClose={toggleFiltersMenu}
                teamName={teamName}
            />
        </>
    );
}
