import VenueProposalRenderer from 'components/VenueProposalRenderer';
import { Route, Routes, useParams } from 'react-router-dom';

const Proposal = ({
    viewVenueListing,
    venueBooked,
    setVenueBooked,
}: {
    viewVenueListing: (venueId: number) => void;
    venueBooked?: boolean;
    setVenueBooked?: (booked: boolean) => void;
}) => {
    const { proposalId } = useParams<{ proposalId?: string }>();

    const renderCommonVenueProps = {
        proposalId,
        viewVenueListing,
        venueBooked,
        setVenueBooked,
    };

    return (
        <Routes>
            <Route path={'messages'} element={<VenueProposalRenderer {...renderCommonVenueProps} withMessagesOpen />} />
            <Route path="*" element={<VenueProposalRenderer {...renderCommonVenueProps} />} />
        </Routes>
    );
};

export default Proposal;
