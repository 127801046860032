import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AgentIcon from 'images/icons/workspaces/agent.svg?react';
import CoworkingOfficeIcon from 'images/icons/workspaces/coworking-desks.svg?react';
import FurnitureIcon from 'images/icons/workspaces/furniture.svg?react';
import ScreenshareIcon from 'images/icons/workspaces/screenshare.svg?react';
import WhiteboardIcon from 'images/icons/workspaces/whiteboard.svg?react';
import WiFiIcon from 'images/icons/workspaces/wifi.svg?react';
import { Moment } from 'moment';
import React, { ChangeEvent } from 'react';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { isBefore, timeOptions } from 'utils/date_util';
import { tzMoment, userTimeZone } from 'utils/moment';
import IWGLocationAutocomplete, { TLocationOption } from '../LocationAutocomplete';

const Amenities = {
    wifi: {
        icon: WiFiIcon,
        title: i18n.workspaces.drawer.wifiDescription,
    },
    agent: {
        icon: AgentIcon,
        title: i18n.workspaces.drawer.wifiDescription,
    },
    whiteboard: {
        icon: WhiteboardIcon,
        title: i18n.workspaces.drawer.whiteboardDescription,
    },
    coworkingOffice: {
        icon: CoworkingOfficeIcon,
        title: i18n.workspaces.drawer.coworkingOfficeDescription,
    },
    furniture: {
        icon: FurnitureIcon,
        title: i18n.workspaces.drawer.furnitureDescription,
    },
    screenshare: {
        icon: ScreenshareIcon,
        title: i18n.workspaces.drawer.screenshareDescription,
    },
} as const;

type AmenityType = keyof typeof Amenities;

const WorkspaceAmenities: Record<IWG.WorkspaceType, AmenityType[]> = {
    meeting_rooms: ['wifi', 'agent', 'whiteboard'],
    coworking_desks: ['wifi', 'agent', 'coworkingOffice'],
    private_offices: ['wifi', 'agent', 'furniture'],
};

const InputLabel = styled('span')<{ weight?: string; color?: EColors }>(
    ({ theme: { getColor, EColors }, weight, color }) => ({
        color: getColor(color ?? EColors.pureBlack),
        fontSize: '0.875rem',
        fontWeight: weight ?? '500',
    })
);

const IconLabel = styled('div')(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.pureBlack),
    fontSize: '0.75rem',
    fontWeight: '400',
    textAlign: 'center',
}));

const AmenityGroup = styled(Stack)(({ theme: { getColor, EColors } }) => ({
    backgroundColor: getColor(EColors.primaryAction, 0.1),
    borderRadius: '4px',
    gap: '20px',
    padding: '20px 10px',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

export type TFormValues = {
    workspaceType?: IWG.WorkspaceType;
    minDelegates: number;
    location?: TLocationOption;
    date: Moment | null;
    startTime?: string;
    endTime?: string;
    productVariant?: number;
};

export const initialFormValues: TFormValues = {
    workspaceType: undefined,
    minDelegates: 0,
    date: null,
    startTime: '',
    endTime: '',
};

interface SearchFormProps {
    values: TFormValues;
    setValues: (newValue: TFormValues) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ values, setValues }: SearchFormProps) => {
    const onLocationChange = async (newValue: TLocationOption) => {
        setValues({
            ...values,
            location: newValue,
        });
    };

    const onClearLocation = () => {
        setValues({
            ...values,
            location: undefined,
        });
    };

    const handleWorkspaceTypeChange = (event: SelectChangeEvent<IWG.WorkspaceType>) => {
        setValues({
            ...values,
            workspaceType: event.target.value as IWG.WorkspaceType,
        });
    };

    const handleDeletagesChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            minDelegates: Number(event.target.value),
        });
    };

    const handleDateChange = (newValue: Moment | null) => {
        setValues({
            ...values,
            date: newValue,
        });
    };

    const isStartTimeValid = (startTime: string) => {
        if (!values.date) {
            return true;
        }
        const suggestedTime = tzMoment(values.date.format('YYYY-MM-DDT') + startTime, userTimeZone);
        return suggestedTime.isAfter(tzMoment(null, userTimeZone));
    };

    const handleStartTimeChange = (event: SelectChangeEvent<string>) => {
        setValues({
            ...values,
            startTime: event.target.value,
        });
    };

    const handleEndTimeChange = (event: SelectChangeEvent<string>) => {
        setValues({
            ...values,
            endTime: event.target.value,
        });
    };

    const renderAmenities = (amenities: AmenityType[]) => {
        return (
            <AmenityGroup>
                {amenities.map(amenity => {
                    const Icon = Amenities[amenity].icon;
                    return (
                        <Stack alignItems="center" key={amenity} gap={1} flex={1}>
                            <Icon />
                            <IconLabel>{Amenities[amenity].title}</IconLabel>
                        </Stack>
                    );
                })}
            </AmenityGroup>
        );
    };

    if (!values.workspaceType) {
        return null;
    }

    return (
        <form>
            <Grid container spacing={2}>
                {/* Location Input */}
                <Grid item xs={12}>
                    <Stack gap={1}>
                        <Stack gap={'0.2em'} direction="row">
                            <InputLabel>{i18n.workspaces.header.location}</InputLabel>
                            <InputLabel weight="400" color={EColors.darkGrey}>
                                ({i18n.common.required})
                            </InputLabel>
                        </Stack>
                        <IWGLocationAutocomplete
                            value={values.location}
                            onChange={onLocationChange}
                            onRemove={onClearLocation}
                            placeholder={i18n.workspaces.drawer.locationPlaceholder}
                            clearable
                        />
                    </Stack>
                </Grid>

                {/* Workspace Type Select */}
                <Grid item xs={6}>
                    <Stack gap={1}>
                        <Stack gap={'0.2em'} direction="row">
                            <InputLabel>{i18n.workspaces.header.workspaceType}</InputLabel>
                            <InputLabel weight="400" color={EColors.darkGrey}>
                                ({i18n.common.required})
                            </InputLabel>
                        </Stack>
                        <Select fullWidth value={values.workspaceType} onChange={handleWorkspaceTypeChange}>
                            <MenuItem value="meeting_rooms">{i18n.workspaces.venueTypes.meetingRooms}</MenuItem>
                            <MenuItem value="coworking_desks">{i18n.workspaces.venueTypes.coworkingDesks}</MenuItem>
                            <MenuItem value="private_offices">{i18n.workspaces.venueTypes.privateOffices}</MenuItem>
                        </Select>
                    </Stack>
                </Grid>

                {/* Number of People or Desks */}
                <Grid item xs={6}>
                    <Stack gap={1}>
                        <Stack gap={'0.2em'} direction="row">
                            <InputLabel>
                                {values.workspaceType === 'coworking_desks'
                                    ? i18n.workspaces.venueTypes.deskCount
                                    : i18n.workspaces.venueTypes.peopleCount}
                            </InputLabel>
                            <InputLabel weight="400" color={EColors.darkGrey}>
                                ({i18n.common.required})
                            </InputLabel>
                        </Stack>
                        <OutlinedInput
                            required
                            fullWidth
                            type="number"
                            value={String(values.minDelegates)}
                            onChange={handleDeletagesChange}
                            inputProps={{ min: 1 }}
                        />
                    </Stack>
                </Grid>

                {/* Date Picker */}
                <Grid item xs={4}>
                    <Stack gap={1}>
                        <Stack gap={'0.2em'} direction="row">
                            <InputLabel>{i18n.datetime.date}</InputLabel>
                            <InputLabel weight="400" color={EColors.darkGrey}>
                                ({i18n.common.required})
                            </InputLabel>
                        </Stack>
                        <DatePicker value={values.date} onChange={handleDateChange} disablePast />
                    </Stack>
                </Grid>

                {/* Time Pickers */}
                <Grid item xs={4}>
                    <Stack gap={1}>
                        <Stack gap={'0.2em'} direction="row">
                            <InputLabel>{i18n.datetime.startTime}</InputLabel>
                            <InputLabel weight="400" color={EColors.darkGrey}>
                                ({i18n.common.required})
                            </InputLabel>
                        </Stack>
                        <Select
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={handleStartTimeChange}
                            value={values.startTime}
                        >
                            {timeOptions.map(option => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={!isStartTimeValid(option.value)}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack gap={1}>
                        <Stack gap={'0.2em'} direction="row">
                            <InputLabel>{i18n.datetime.endTime}</InputLabel>
                            <InputLabel weight="400" color={EColors.darkGrey}>
                                ({i18n.common.required})
                            </InputLabel>
                        </Stack>
                        <Select IconComponent={KeyboardArrowDownIcon} onChange={handleEndTimeChange}>
                            {timeOptions.map(option => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={isBefore(option.value, values.startTime)}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Grid>
            </Grid>
            <Stack gap={1} mt={3}>
                <InputLabel>{i18n.workspaces.drawer.whatsIncluded}</InputLabel>
                {renderAmenities(WorkspaceAmenities[values.workspaceType])}
            </Stack>
        </form>
    );
};

export default SearchForm;
