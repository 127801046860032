export type ImageProp = {
    srcUrl: string;
};

export const getValidImages = async <T extends ImageProp>(images: T[]): Promise<T[]> => {
    const validatedImages = await Promise.all(
        images.map(async image => {
            try {
                const response = await fetch(image.srcUrl, { method: 'HEAD' });
                if (response.ok) {
                    return image;
                }
            } catch {
                // Image is not valid
            }
            return null;
        })
    );
    return validatedImages.filter(image => image !== null);
};
