import colorFns from 'colorFns';
import fontFns from 'fontFns';
import he from 'he';
import isEmpty from 'lodash/isEmpty';
import { useUser } from 'providers/user';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withInteractibleIconStyles } from 'shared';
import { useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { HEADER_HEIGHT } from 'utils/header';
import { tzMoment, userTimeZone } from 'utils/moment';
import { hasContract } from 'utils/venue';
import { getVenueProposal } from '../api';
import { EventHeaderIconsFill } from '../components/EventHeader';
import { MessageCardFlyout } from '../components/MessageCard';
import DocumentIcon from '../images/icons/document.svg?react';
import MessageBubbleSVG from '../images/icons/message_bubble.svg?react';
import { EventContext } from '../providers/event';
import { Column, ExternalLink, Row, SmallerCopy, SpacedRow, Spacer } from '../ui';
import { formatCurrency } from '../util';
import ProposalAcceptModal from './ProposalAcceptModal';
import ProposalRejectModal from './ProposalRejectModal';
import { SpinnerOverlay } from './Spinner';
import ThrustCarbonScore from './ThrustCarbonScore';
import { ProposalActionButton } from './VenueInquiry/ProposalActionButton';
import { ProposalExtensionButton } from './VenueInquiry/ProposalExtensionButton';
import { useProposalRequest } from './VenueInquiry/useProposalRequest';
import DayDetail from './VenueProposal/DayDetail';
import VenueProposalAccepted from './VenueProposalAccepted';
import DashboardVenueTile from './VenueTile/DashboardVenueTile';
import Button from './ui/Button';
import { LargeHeadline, PageHeadline, SmallHeadline } from './ui/Headline';
import { TAB_HEIGHT } from './ui/ScrollTabs';

const MessageBubble = withInteractibleIconStyles(MessageBubbleSVG);

const SubSectionHeader = styled.h6<{ alert?: boolean; pending?: boolean }>`
    font-weight: normal;
    font-size: 1em;
    line-height: 1.28;
    color: ${props => (props.alert ? colorFns.warningText : props.pending ? colorFns.darkGrey : colorFns.grey2)};
    margin: 0;
`;

const VenueLabel = styled('div')<{ small?: boolean; href?: string }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}

    display: flex;
    align-items: center;
`;

const ButtonsContainer = styled(SpacedRow)`
    width: auto;

    & > * {
        margin-left: 20px;
    }
`;

const MessageButton = styled(Button)`
    width: auto;
    padding: 0 16px;
`;

const SummaryContainer = styled(Column)`
    scroll-margin-top: ${HEADER_HEIGHT + TAB_HEIGHT}px;
`;

const SummaryBlock = styled(Column)`
    flex: 0.4;
`;

const ProposalSummary = styled(Column)`
    border-bottom: 2px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lighterGrey)};
`;

const VenueImageBlock = styled(Column)`
    flex: 0.6;
`;

const SummaryItemRow = styled(SpacedRow)`
    font-size: 1em;
    font-weight: 400;
    padding: 1em 0;
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lighterGrey)};

    > .label {
        flex: 0.45;
        font-weight: 500;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    }

    > .value {
        flex: 0.55;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    }

    &:last-child {
        border-bottom: none;
    }
`;

const Totals = styled(Row)`
    background: ${colorFns.softAccentedBackground};
    border-radius: 4px;
    align-items: stretch;
`;

const TotalBox = styled(Column)<{ total?: boolean }>`
    padding: 2em;
    width: 33.33%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    justify-content: space-between;

    ${({ theme: { getColor, EColors }, total }) =>
        total &&
        `
        background-color: rgba(111, 207, 151, 0.17);
        color: ${getColor(EColors.totalPrice)};
        `}

    .name {
        font-size: 1em;
        flex: 2;
        display: flex;
        align-items: flex-end;
    }

    .value {
        font-size: 1.5em;
        flex: 1;
        margin-bottom: 1.75rem;
    }
`;

const Line = styled('div')`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    height: 2px;
    width: 100%;
`;

const BudgetLabel = styled('label')<{ aboveBudget?: boolean }>`
    font-size: 15px;
    letter-spacing: -0.1px;
    color: ${({ aboveBudget, theme: { getColor, EColors } }) =>
        aboveBudget ? getColor(EColors.red) : getColor(EColors.green)};
    ${fontFns.formLabel}
    position: absolute;
    margin-top: 5.313rem;
`;

const grSubtotal = (guestroom: Bizly.VenueDayGR) => (guestroom?.quantity ? guestroom.rate * guestroom.quantity : null);
const esSubtotal = (eses: Bizly.EventSpace[], useDdr?: boolean) =>
    eses.length > 0
        ? eses.reduce((agg, cur) => {
              return agg + (useDdr ? (cur.ratePerPerson ?? 0) * (cur.minGuests ?? 0) : cur.fbMinimum + cur.rate);
          }, 0)
        : null;

const VenueProposal = ({
    proposalId,
    viewVenueListing,
    venueBooked,
    setVenueBooked,
    withMessagesOpen = false,
}: {
    proposalId?: string;
    viewVenueListing: (venueId: number) => void;
    venueBooked?: boolean;
    setVenueBooked?: (booked: boolean) => void;
    withMessagesOpen?: boolean;
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { venues } = useCurrentInquiry();
    const { user } = useUser();

    const { event, setCurrency } = useContext(EventContext);

    const [venueProposal, setVenueProposal] = useState<Bizly.VenueProposal>();
    const [contractLoading, setContractLoading] = useState<boolean>(false);

    const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
    const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

    const [venueMessagesAnchor, setVenueMessagesAnchor] = useState<HTMLDivElement | null>(null);
    const messageBubbleIconRef = useRef<HTMLDivElement | null>(null);

    const { showModal, modalShown, hasRequested, ProposalRequestModal } = useProposalRequest(venueProposal);

    const summaryRef = useRef<HTMLDivElement>(null);

    const toggleMessagesFlyout = () => {
        const currentPath = location.pathname.split('/');
        if (currentPath[currentPath.length - 1] === 'messages') {
            currentPath.pop();
        } else {
            currentPath.push('messages');
        }
        navigate(currentPath.join('/'), {
            replace: true,
        });
        setVenueMessagesAnchor(prevAnchor => (prevAnchor ? null : messageBubbleIconRef.current));
    };
    const showMessagesFlyout = () => {
        if (!withMessagesOpen) {
            navigate(location.pathname + '/messages', { replace: true });
        }
        setVenueMessagesAnchor(messageBubbleIconRef.current);
    };

    useEffect(() => {
        if (withMessagesOpen && messageBubbleIconRef.current) {
            setVenueMessagesAnchor(messageBubbleIconRef.current);
        }
    }, [messageBubbleIconRef, withMessagesOpen]);

    useEffect(() => {
        let isMounted = true;

        if (proposalId) {
            getVenueProposal(proposalId).then(res => {
                if (isMounted) {
                    if (res.proposal?.accepted) {
                        setContractLoading(true);
                    }
                    setVenueProposal(res.proposal);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [proposalId, venueBooked]);

    useEffect(() => {
        if (venues && venues.length > 0 && venueProposal) {
            const inquiredVenue = venues.find(venue => venue.proposalId === venueProposal.id);
            if (hasContract(inquiredVenue)) {
                if (!location.pathname.includes('/contracting')) {
                    // check if it is a messages url
                    let messageUrl = '';
                    const currentPath = location.pathname.split('/');
                    if (currentPath[currentPath.length - 1] === 'messages') {
                        messageUrl = '/messages';
                    }

                    navigate(`/event/${event.id}/venue/contracting${messageUrl}`, { replace: true });
                }
            }
        }
    }, [venueProposal, venues, event, navigate, location]);

    const handleExtensionRequest = () => {
        if (proposalId) {
            getVenueProposal(proposalId).then(res => setVenueProposal(res.proposal));
        }
    };

    const venueEndDate = venueProposal?.onHoldUntil ? venueProposal?.onHoldUntil.split(' ')[0] + ' 23:59:59' : '';

    const onHoldUntilDate = venueEndDate
        ? tzMoment(venueEndDate, venueProposal?.venue.timeZone)
              .tz(venueProposal?.venue.timeZone ?? userTimeZone)
              .format('L LTS zz')
        : '';

    const expired = venueProposal && new Date() > new Date(venueProposal.onHoldUntil);
    const extensionRequestDate = tzMoment(venueProposal?.extensionRequestedAt).format('L');
    const extensionRejectDate = tzMoment(venueProposal?.extensionRejectedAt).format('L');
    const hasBeenExtended = tzMoment(venueProposal?.onHoldUntil).isAfter(venueProposal?.extensionRequestedAt);

    const currencyCode = venueProposal?.currency?.code;

    const renderTotals = (proposal: Bizly.VenueProposal) => {
        const useDdr = !!proposal.useDdr;

        const hasGR = proposal.byDay.some(day => day.guestRooms[0]?.quantity);
        const hasES = proposal.byDay.some(day => day.eventSpaces.length > 0);

        const grSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (grSubtotal(day.guestRooms[0]) ?? 0), 0);
        const esSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (esSubtotal(day.eventSpaces, useDdr) ?? 0), 0);
        const totalProposal = grSubtotalVal + esSubtotalVal;
        const totalDisplay = formatCurrency(totalProposal, currencyCode);
        const totalConvertedProposal = totalProposal * Number(proposal.forexRate);

        const isEventBudget =
            user.team?.authMeetingCreateFields?.includes('budget') || user?.team?.authMeetingCreateRedirect;

        const hasBudget = isEventBudget || Boolean(user?.team?.maximumBudget);
        const maxBudget = isEventBudget ? Number(event.budget) : Number(user?.team?.maximumBudget);

        const budgetBufferPercentage = 1.1;
        const budgetWithBuffer = maxBudget ? maxBudget * budgetBufferPercentage : 0;

        const isAboveBudget = totalConvertedProposal > budgetWithBuffer;
        const isAboveMaximumBudgetDisplay = isAboveBudget
            ? i18n.venue.inquiry.aboveMaximumBudget
            : i18n.venue.inquiry.withinMaximumBudget;

        const budgetBasedApprovalFeatureFlag = user.featureFlags?.budgetBasedApprovals;

        return (
            <Column>
                <Totals justifyContent="space-between">
                    <TotalBox>
                        <div className="name">{i18n.proposalForm.sections.guestRooms}</div>
                        <Spacer small />
                        <div className="value">{hasGR ? formatCurrency(grSubtotalVal, currencyCode) : '-'}</div>
                    </TotalBox>
                    <TotalBox>
                        <div className="name">{i18n.venue.inquiry.eventSpaces}</div>
                        <Spacer small />
                        <div className="value">{hasES ? formatCurrency(esSubtotalVal, currencyCode) : '-'}</div>
                    </TotalBox>
                    <TotalBox total justifyContent="center">
                        <div className="name">{i18n.venue.total}</div>
                        <Spacer small />
                        <div className="value">{totalDisplay}</div>
                        {hasBudget && budgetBasedApprovalFeatureFlag && (
                            <BudgetLabel aboveBudget={isAboveBudget}>{isAboveMaximumBudgetDisplay}</BudgetLabel>
                        )}
                    </TotalBox>
                </Totals>
                <Spacer xsmall />
                <SmallerCopy>{i18n.venue.inquiry.amountDescription}</SmallerCopy>
            </Column>
        );
    };

    const summaryBlock = (
        <SummaryBlock>
            <Column>
                {venueProposal && venueProposal.onHoldUntil && (
                    <ProposalSummary>
                        <Column>
                            <LargeHeadline thin>{i18n.venue.inquiry.viewProposalDetails}</LargeHeadline>
                            <SmallHeadline thin $color={'darkerGrey'}>
                                {i18n.venue.proposal.roomHeldUntil}:
                            </SmallHeadline>
                            <SubSectionHeader>{onHoldUntilDate}</SubSectionHeader>
                        </Column>
                        <Spacer />

                        {venueProposal?.extensionRejectedAt ? (
                            <Column>
                                <SubSectionHeader alert>{i18n.venue.proposal.extensionRejectedOn}:</SubSectionHeader>
                                <SubSectionHeader alert>{extensionRejectDate}</SubSectionHeader>
                            </Column>
                        ) : (
                            venueProposal?.extensionRequestedAt &&
                            !hasBeenExtended && (
                                <Column>
                                    <SubSectionHeader pending>
                                        {i18n.venue.proposal.extensionRequestSentOn}:
                                    </SubSectionHeader>
                                    <SubSectionHeader pending>{extensionRequestDate}</SubSectionHeader>
                                </Column>
                            )
                        )}
                    </ProposalSummary>
                )}
                <SummaryItemRow>
                    <div className="label">{i18n.homepage.createMeetingModal.meetingName}</div>
                    <div className="value">{event.name}</div>
                </SummaryItemRow>
                <SummaryItemRow>
                    <div className="label">{i18n.meetingDashboard.headerSection.settings.meetingType}</div>
                    <div className="value">{event.type}</div>
                </SummaryItemRow>

                <SummaryItemRow>
                    <div className="label">{i18n.venue.carbonMeasurement}</div>
                    <div className="value">
                        {venueProposal?.emissionReport?.tons ? (
                            <ThrustCarbonScore
                                score={venueProposal?.emissionReport?.tons}
                                equivalents={venueProposal?.emissionReport?.equivalents}
                                noDecor
                            />
                        ) : (
                            i18n.venue.proposal.noInfo
                        )}
                    </div>
                </SummaryItemRow>
                <SummaryItemRow>
                    <div className="label">{i18n.meetingDashboard.headerSection.settings.internalReference}</div>
                    <div className="value">{event.cventId ? event.cventId : i18n.venue.proposal.noInfo}</div>
                </SummaryItemRow>
                <SummaryItemRow>
                    <div className="label">{i18n.proposalForm.modal.notes}</div>
                    <div className="value">
                        {venueProposal && !isEmpty(venueProposal.notes)
                            ? he.decode(venueProposal.notes.replace(/(<([^>]+)>)/gi, ''))
                            : i18n.venue.proposal.noInfo}
                    </div>
                </SummaryItemRow>

                {venueProposal && !isEmpty(venueProposal.datesFlexibleNotes) && (
                    <SummaryItemRow>
                        <div className="label">{i18n.venue.proposal.flexibleDates}</div>
                        <div className="value">
                            {he.decode(venueProposal.datesFlexibleNotes.replace(/(<([^>]+)>)/gi, ''))}
                        </div>
                    </SummaryItemRow>
                )}

                <SummaryItemRow>
                    <div className="label">{i18n.common.attachments}</div>
                    <div className="value">
                        {venueProposal && !isEmpty(venueProposal.attachments)
                            ? venueProposal.attachments.map(attachment => (
                                  <VenueLabel key={attachment.name} small>
                                      <DocumentIcon />
                                      <ExternalLink href={attachment.url} openInNewTab>
                                          {attachment.name}
                                      </ExternalLink>
                                  </VenueLabel>
                              ))
                            : i18n.venue.proposal.noInfo}
                    </div>
                </SummaryItemRow>
            </Column>
            <Spacer />

            {venueProposal && renderTotals(venueProposal)}
        </SummaryBlock>
    );

    const venueImage = (
        <VenueImageBlock>
            {venueProposal && venueProposal.venue && (
                <DashboardVenueTile
                    venue={venueProposal.venue}
                    format="proposal"
                    fixedRatio="calc(515 / 668 * 100%)"
                    width="100%"
                    onClick={viewVenueListing}
                    pillType="preferenceCategory"
                />
            )}
        </VenueImageBlock>
    );

    if (!venueProposal) {
        return <SpinnerOverlay />;
    }

    return (
        <>
            {proposalId && event.editable && (
                <EventHeaderIconsFill>
                    {venueMessagesAnchor && (
                        <MessageCardFlyout
                            anchor={venueMessagesAnchor}
                            onClose={toggleMessagesFlyout}
                            proposalId={proposalId}
                        />
                    )}
                </EventHeaderIconsFill>
            )}
            {venueProposal?.accepted && (
                <VenueProposalAccepted
                    proposalId={proposalId}
                    onSendMessage={showMessagesFlyout}
                    summaryRef={summaryRef}
                    onLoading={setContractLoading}
                    messageBubbleIconRef={messageBubbleIconRef}
                />
            )}
            {!contractLoading && (
                <Column fillWidth>
                    {!venueProposal.accepted && (
                        <SpacedRow>
                            <PageHeadline large>{i18n.venue.proposal.proposal}</PageHeadline>
                            <ButtonsContainer>
                                {event.editable && (
                                    <div ref={messageBubbleIconRef}>
                                        <MessageButton secondary onClick={showMessagesFlyout}>
                                            {i18n.venue.messageVenue}
                                        </MessageButton>
                                    </div>
                                )}
                                {event.editable && !venueProposal.rejected && !venueProposal.cancelled && (
                                    <>
                                        <Button onClick={() => setRejectModalOpen(true)} warning>
                                            {i18n.button.reject}
                                        </Button>

                                        <ProposalActionButton
                                            proposal={venueProposal}
                                            expired={expired}
                                            onAccept={() => setAcceptModalOpen(true)}
                                            eventBudget={event?.budget}
                                            showModal={showModal}
                                            hasRequested={hasRequested}
                                        />

                                        {expired && (
                                            <ProposalExtensionButton
                                                proposal={venueProposal}
                                                onAccept={() => setAcceptModalOpen(true)}
                                            />
                                        )}
                                    </>
                                )}
                            </ButtonsContainer>
                        </SpacedRow>
                    )}
                    <SummaryContainer ref={summaryRef}>
                        {venueProposal.accepted && <Spacer largest />}
                        <Row justifyContent="space-between" itemSpacing="default">
                            {summaryBlock}
                            {venueImage}
                        </Row>
                    </SummaryContainer>
                    <Spacer />
                    <Line />
                    <Spacer largest />
                    {venueProposal.byDay.map((day, i) => (
                        <>
                            <DayDetail dayIndex={i + 1} details={day} venueProposal={venueProposal} />
                            <Spacer />
                        </>
                    ))}
                    <Spacer largest />
                </Column>
            )}
            {setVenueBooked && !venueProposal.accepted && !venueProposal.rejected && (
                <>
                    <ProposalAcceptModal
                        open={acceptModalOpen}
                        closeModal={() => setAcceptModalOpen(false)}
                        proposal={venueProposal}
                        eventId={event.id}
                        setVenueBooked={booked => {
                            acceptModalOpen && setCurrency(venueProposal?.currency);
                            setVenueBooked(booked);
                        }}
                        onExtensionRequest={handleExtensionRequest}
                    />
                    <ProposalRejectModal
                        open={rejectModalOpen}
                        closeModal={() => setRejectModalOpen(false)}
                        proposalId={venueProposal.id}
                        eventId={event.id}
                    />
                </>
            )}
            {modalShown && ProposalRequestModal}
        </>
    );
};

export default VenueProposal;
