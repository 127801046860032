import { Box } from '@mui/material';
import { InquiryDetailsType } from 'components/InquiryDetailsModal';
import { Button } from 'components/Ui-V2/Button/Button';
import { TMenuOption, VenueCardPopover } from 'components/VenueCard';
import { TabLabels } from 'constants/venueStatus';
import styled from 'styled-components';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';
import { HEADER_HEIGHT } from 'utils/header';
import { tzMoment } from 'utils/moment';
import { BaseVenueTile, VenueTileProps } from './VenueTile';

const TileWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 8px;
    height: 100%;

    position: sticky;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    top: ${HEADER_HEIGHT}px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TileButtons = styled.div<{ rowReverse?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;

    @media (min-width: 1490px) {
        flex-direction: row;
    }

    ${({ rowReverse }) =>
        rowReverse &&
        `
            flex-direction: row-reverse;
  
        `}

    & > .MuiButton-root:only-child {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    & > .MuiButton-root {
        margin-right: 0;
        flex: 1;
        &.MuiButton-containedPrimary {
            margin-right: 0;
        }
    }
`;

const StyledPopOverContainer = styled(Button)`
    &.MuiButton-root {
        display: flex;
        &:hover {
            background-color: transparent;
        }
    }
`;

const StyledInquiryButton = styled(Button)`
    &.MuiButton-root {
        flex: 1;
    }
`;

const StyledButton = styled(Button)`
    &.MuiButton-root {
        flex: 1;
        margin-right: 0;
    }
`;

const NudgeButtonWrapper = styled(Box)`
    display: flex;
    flex: 1;
    justify-content: center;
    @media (min-width: 1490px) {
        justify-content: flex-end;
    }
`;

type TInquiryButton = { label: string; handler: () => void };

type TActions = {
    tabLabel: TabLabels;
    inquiryVenue?: BizlyAPI.Inquiry.Venue & { submittedAt?: string };
    viewInquiry: () => void;
    onNudge?: () => void;
    inquiryButton?: TInquiryButton;
    onCancelInquiry?: (venueId: number, venueInquiryId: number) => void;
    directCancelInquiry?: () => void;
    inquiryDetails?: InquiryDetailsType;
};

type InquiryVenueTileProps = VenueTileProps & TActions;

const InquiryVenueTile = (props: InquiryVenueTileProps) => {
    const { inquiryVenue, viewInquiry, onNudge, inquiryButton, onCancelInquiry, directCancelInquiry, ...tileProps } =
        props;

    let nudgeDisabled = false;
    let showNudge = false;
    let shouldRowReverse = false;
    const venueStatus = tileProps?.venue?.status;

    if (venueStatus === 'Submitted') {
        const submittedAt = tzMoment(inquiryVenue?.submittedAt);
        showNudge = submittedAt.add(24, 'h').isBefore(tzMoment()); // check if the submission time is BEFORE 24 hours ago. If yes, "showNudge" should be false.
        const lastNudgedAt = tzMoment(inquiryVenue?.nudgedAt || inquiryVenue?.submittedAt);
        nudgeDisabled = lastNudgedAt.add(24, 'h').isAfter(tzMoment()); // check if the last nudge is BEFORE 24 hours ago. If yes, "nudgeDisabled" should be true.
        shouldRowReverse = showNudge;
    }

    const renderInquiryActionButton = (nudgeDisabled: boolean) => {
        if (!inquiryVenue) return null;

        if (venueStatus === 'Submitted') {
            const menuOptions: TMenuOption[] = [
                {
                    label: i18n.venue.inquiry.nudge,
                    disabled: nudgeDisabled || !showNudge,
                    handler: () => onNudge?.(),
                },
                {
                    label: i18n.venue.inquiry.cancelInquiry,
                    handler: () => inquiryVenue.venueInquiryId && directCancelInquiry?.(),
                },
            ];

            return (
                <StyledPopOverContainer variant="text">
                    <VenueCardPopover color={EColors.pureBlack} options={menuOptions} />
                </StyledPopOverContainer>
            );
        } else {
            return (
                <StyledButton variant="contained" onClick={inquiryButton?.handler}>
                    {inquiryButton?.label}
                </StyledButton>
            );
        }
    };

    return (
        <TileWrapper className="sticky">
            <BaseVenueTile {...tileProps} hideButtons />
            <TileButtons>
                <StyledInquiryButton variant={shouldRowReverse ? 'contained' : 'outlined'} onClick={viewInquiry}>
                    {i18n.venue.inquiry.viewInquiry}
                </StyledInquiryButton>
                <NudgeButtonWrapper>{inquiryVenue && renderInquiryActionButton(nudgeDisabled)}</NudgeButtonWrapper>
            </TileButtons>
        </TileWrapper>
    );
};

export default InquiryVenueTile;
