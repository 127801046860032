import { REJECTED_STATUSES } from 'components/VenueCard';
import { useCallback, useMemo, useRef } from 'react';
import { OnlySingleLine } from 'shared';
import styled from 'styled-components';
import { Row } from 'ui';
import TileInfo from './TileInfo';
import { TPillTypes } from './VenuePill';

export type TTileFormats = 'tile' | 'thumb' | 'default' | 'proposalThumb' | 'proposal' | 'featured';

type TTileWrapperProps = {
    width?: string;
    minWidth?: string;
    padding?: string;
};
const TileWrapper = styled.div.attrs(() => ({
    'data-testid': 'venue-thumb',
}))<TTileWrapperProps>`
    border-radius: 4px;
    position: relative;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 10px;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    ${props =>
        props.width &&
        `
    width: ${props.width};
    min-width: ${props.width};
    max-width: ${props.width};
    `}
    ${props =>
        props.minWidth &&
        `
    min-width: ${props.minWidth};
    `}
    ${props =>
        props.padding &&
        `
    padding: ${props.padding};
    `}
`;

export const FixedRatio = styled.div<{ backgroundColor?: Themed.Color; fixedRatio?: string }>`
    position: relative;
    padding: 0;

    ${({ backgroundColor, theme: { getColor, EColors } }) =>
        `background-color: ${backgroundColor || getColor(EColors.softAccentedBackground)};`}
    border-radius: 4px;

    ${props =>
        props.fixedRatio
            ? `
      padding-top: ${props.fixedRatio};
      width: 100%;
    `
            : `
    `}
`;

type RoundedImageProps = {
    url?: string;
    width: string | number;
    height: string | number;
    fixedRatio: string;
    highlighted?: boolean;
    onClick?: (venueId: number) => void;
};

const RoundedImage = styled(FixedRatio)<Partial<RoundedImageProps>>`
    background-image: url(${props => props.url});
    background-size: cover;
    background-position: center;

    ${props =>
        props.onClick
            ? `
    cursor: pointer;
    `
            : ``}
`;

const TileContent = styled(Row)<{ padding?: number }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: ${props => props.padding || 0}px;
    box-sizing: border-box;

    & > * {
        height: 100%;
    }
`;

const FadedOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    opacity: 0.7;
    border-radius: 4px;
    pointer-events: none;
`;

const BasicsCaps = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;
const Subtext = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey2)};
`;
const Title = styled(Row)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
`;
/* When the title is not overflowing we want flex-end so that it looks like this:
 _________
|         |
|T̲i̲t̲l̲e̲____|

However during an overflow, flex-end causes the title to do this:
 T̲i̲t̲l̲e̲____
|that     |
|o̲v̲e̲r̲f̲l̲o̲w̲s̲|

What we need to do is have the title itself be flex-start, but have it's wrapper be flex-end, giving us:
 _________
|Title    |
|t̲h̲a̲t̲_____|
 overflows
*/
// const TitleText = ({ children }: { children: React.ReactNode }) => (
//     <Row>
//         <Title>{children}</Title>
//     </Row>
// );

const TileText = styled('div').attrs(({ format = 'default' }: { format: TTileFormats }) => ({ format }))`
    display: flex;
    flex-direction: column;

    ${({ format }) => {
        switch (format) {
            case 'tile':
                return `
                    margin-top: 0.5em;
                `;
            case 'proposalThumb':
                return `
                    margin-top: 1em;
                `;
            case 'proposal':
                return `
                    margin-top: 0.75em;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 1em;
                `;
        }
    }}

    ${Title} {
        ${({ format }) => {
            switch (format) {
                case 'tile':
                    return `
            font-size: 0.9375em;
            font-weight: bold;
            letter-spacing: -0.09px;
        `;
                case 'proposal':
                case 'featured':
                    return `
            font-size: 1.125em;
            font-weight: 400;
            line-height: 1.3;
        `;
                case 'thumb':
                case 'default':
                case 'proposalThumb':
                default:
                    return `
            font-size: 0.9375em;
            font-weight: 500;
            letter-spacing: -0.4px;
            `;
            }
        }}
    }

    ${Subtext} {
        ${({ format }) => {
            switch (format) {
                case 'tile':
                case 'proposalThumb':
                    return `
                        font-size: 0.9375em;
                        font-weight: 400;
                        letter-spacing: normal;
                        ${OnlySingleLine}
                    `;
                case 'proposal':
                    return `
                        font-size: 0.9375em;
                        line-height: 1;
                        text-align: right;
                        min-height: 0.9375em;
                    `;
                case 'featured':
                    return `
                        font-size: 1.125em;
                        font-weight: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        margin-top: 0.5em;
                        min-height: 1.125em;
                    `;
                case 'thumb':
                case 'default':
                default:
                    return ``;
            }
        }}
    }

    ${BasicsCaps} {
        ${OnlySingleLine}

        ${({ format }) => {
            switch (format) {
                case 'proposal':
                case 'featured':
                    return `
                        margin-top: 0.3em;
                        font-size: 0.9375em;
                        ${format === 'featured' ? `margin-bottom: 0.75em;` : ``}
                    `;
                case 'tile':
                case 'thumb':
                case 'default':
                case 'proposalThumb':
                default:
                    return `
            font-size: 0.9375em;
            font-weight: 400;
            letter-spacing: normal;
            margin-top: 0.25em;
        `;
            }
        }}
    }
`;

type TVenueTileEmptyProps = {
    width: string;
    minWidth: string;
    fixedRatio: string;
    backgroundColor: Themed.Color;
    padding: string;
};
export function VenueTileEmpty({
    width,
    minWidth,
    fixedRatio,
    backgroundColor,
    padding,
    ...props
}: Partial<TVenueTileEmptyProps>) {
    return (
        <TileWrapper width={width} minWidth={minWidth} padding={padding} {...props}>
            <FixedRatio fixedRatio={fixedRatio} backgroundColor={backgroundColor} />
        </TileWrapper>
    );
}

const TILE_PROPS_BY_FORMAT: {
    [key in TTileFormats]: {
        padding: number;
        imgTransform?: string;
    };
} & {
    undefined: {
        padding: number;
        imgTransform?: string;
    };
} = {
    tile: {
        padding: 10,
        imgTransform: 'w_300,q_auto:best',
    },
    thumb: {
        padding: 12,
        imgTransform: 'w_400,q_auto:best',
    },
    proposalThumb: {
        padding: 16,
        imgTransform: 'w_600,q_auto:best',
    },
    proposal: {
        padding: 16,
    },
    featured: {
        padding: 40,
    },
    default: {
        padding: 16,
        imgTransform: 'w_500,q_auto:best',
    },
    // interestingly this will work if we did tilePropsByFormat[undefined] or tilePropsByFormat.undefined
    // because key access coerces undefined to 'undefined'
    undefined: {
        padding: 16,
        imgTransform: 'w_500,q_auto:best',
    },
};

export function tileTextPropsByFormat(
    venue: Bizly.Venue,
    format?: TTileFormats
): Partial<{
    title: string;
    subtext: string;
    caps: string;
}> {
    const { name, headline, address, qualityLevel, primaryDecor, type, neighborhood } = venue;

    const decor = primaryDecor ? primaryDecor.name + ' ' : '';
    const typeName = type ? type.name + ' ' : '';

    const fullBasics =
        headline ||
        `A ${[
            ...(decor ? [decor] : []),
            ...(qualityLevel ? [qualityLevel] : []),
            ...(typeName ? [typeName] : []),
            ...(neighborhood ? ['in ', neighborhood] : []),
        ].join(' ')}`;

    const shortBasics = `${qualityLevel ? qualityLevel + ' ' : ''}${typeName}`;
    const base = { title: name };

    switch (format) {
        case 'tile':
            return {
                ...base,
                subtext: neighborhood,
                caps: shortBasics,
            };
        case 'proposal':
        case 'proposalThumb':
            return {
                ...base,
                subtext: address,
                caps: fullBasics,
            };
        case 'default':
        default:
            return {
                ...base,
                caps: fullBasics,
            };
    }
}

type TActions = {
    selected?: boolean;
    menuOptions?: { label: string; handler: () => void }[];

    hideButtons?: boolean;
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onClose?: () => void;
};

export type VenueTileProps = {
    className?: string;
    venue: Bizly.Venue;
    format?: TTileFormats;
    width?: string;
    minWidth?: string;
    fixedRatio: string;
    padding?: string;
    highlighted?: boolean;

    pillType?: TPillTypes;

    disableRejected?: boolean;
    onClick?: (venueId: number) => void;
    onMouseOver?: (venueId: number) => void;
    onMouseLeave?: () => void;
};

type TBaseVenueTile = VenueTileProps & TActions;

export default function DashboardVenueTile({
    venue,
    format,
    width,
    minWidth,
    fixedRatio,
    padding,

    disableRejected = false,
    pillType,

    onClick,
    onMouseOver,
    onMouseLeave,
}: TBaseVenueTile) {
    const tileRef = useRef<HTMLDivElement>(null);
    const { padding: contentPadding, imgTransform } = useMemo(
        () => TILE_PROPS_BY_FORMAT[format || 'undefined'],
        [format]
    );

    const { title } = useMemo(() => tileTextPropsByFormat(venue, format), [format, venue]);

    const disabled = disableRejected && REJECTED_STATUSES.has(venue.status);

    const mouseover = useCallback(
        () => !disabled && onMouseOver && onMouseOver(venue.id),
        [onMouseOver, venue.id, disabled]
    );
    const mouseleave = useCallback(() => !disabled && onMouseLeave && onMouseLeave(), [onMouseLeave, disabled]);

    return (
        <TileWrapper width={width} minWidth={minWidth} padding={padding} ref={tileRef}>
            <RoundedImage
                url={
                    imgTransform
                        ? venue.imageUrl.replace('image/upload/', `image/upload/${imgTransform}/`)
                        : venue.imageUrl
                }
                onClick={!disabled && onClick ? () => onClick(venue.id) : undefined}
                onMouseOver={mouseover}
                onMouseLeave={mouseleave}
                fixedRatio={fixedRatio}
            >
                <TileContent padding={contentPadding}>
                    <TileInfo venue={venue} format={format} pillType={disabled ? 'inquiryStatus' : pillType} />
                </TileContent>
                {REJECTED_STATUSES.has(venue.status) && <FadedOverlay />}
            </RoundedImage>
            <TileText format={format}>
                <Title>{title}</Title>
                {/* <div>
                    {title && <TitleText>{title}</TitleText>}
                    {(format === 'proposalThumb' || format === 'proposal') && <BasicsCaps>{caps}</BasicsCaps>}
                </div>
                {(format === 'proposalThumb' || format === 'proposal') && (
                    <div>{subtext && <Subtext>{subtext}</Subtext>}</div>
                )} */}
            </TileText>
        </TileWrapper>
    );
}
