import { Box, styled } from '@mui/material';
import { Button } from 'components/BizlyOS/Button/Button';
import { EColors, getColor } from 'theme';

const ClaimStepContainerFooter = styled(Box)(({ theme: { getColor, EColors, spacing } }) => ({
    padding: spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: '0px -2px 4px 0px ' + getColor(EColors.bizlyOSBorder, 0.5),
    borderTop: '1px solid ' + getColor(EColors.bizlyOSBorder, 0.5),
    gap: spacing(1.5),
}));

type ClaimStepContainerProps = {
    children: React.ReactNode;
    disabled: boolean;
    onSave: () => void;
    onBackClicked: () => void;
    isLastStep: boolean;
    contentCentered?: boolean;
};

export default function ClaimStepContainer({
    children,
    isLastStep,
    disabled,
    onSave,
    onBackClicked,
    contentCentered = false,
}: ClaimStepContainerProps) {
    return (
        <Box
            flex={1}
            boxSizing="border-box"
            height="100%"
            display="flex"
            flexDirection="column"
            bgcolor={getColor(EColors.pureWhite)}
        >
            <Box
                flex={1}
                overflow="auto"
                display="flex"
                justifyContent="center"
                alignItems={contentCentered ? 'center' : 'flex-start'}
                paddingTop={6.25}
                paddingBottom={6.25}
            >
                {children}
            </Box>
            <ClaimStepContainerFooter>
                <Button variant="text" onClick={onBackClicked}>
                    Back
                </Button>
                <Button sx={{ height: '37px', width: '150px' }} onClick={onSave} disabled={disabled}>
                    {isLastStep ? 'Submit' : 'Continue'}
                </Button>
            </ClaimStepContainerFooter>
        </Box>
    );
}
