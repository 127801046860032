import GalleryModal from 'components/GalleryModal';
import CameraSVG from 'images/icons/camera.svg?react';
import { SwipeableImageGallery } from 'pages/SwipeableImageGallery';
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { i18n } from 'translation';
import { Column, TextButton } from 'ui';
import { getValidImages } from 'utils/image';

const Container = styled.div`
    width: 100%;
`;

const CameraImg = styled(CameraSVG)`
    margin-right: 5px;
    padding-top: 2px;
    height: 17px;
    fill: none;
`;

const Left = styled.div`
    flex-basis: 0;
    height: 100%;
`;

const Right = styled(Column)`
    flex-grow: 1;
    flex-basis: 0;
    height: 100%;
    display: flex;

    & > * {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    & > *:first-child {
        margin-top: 0;
        margin-bottom: 0.25rem;
    }

    & > *:last-child {
        margin-bottom: 0;
        margin-top: 0.25rem;
    }
`;

const Gallery = styled.div<{ imageCount: number }>`
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    height: min(100vh, 28.125rem);
    max-height: min(100vh, 28.125rem);

    @media (min-resolution: 150dpi) {
        height: min(50vh, 28.125rem);
        max-height: min(50vh, 28.125rem);
    }

    & > * {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }

    & > *:first-child {
        margin-left: 0;
    }

    & > *:last-child {
        margin-right: 0;
    }

    ${Left} {
        flex-grow: ${({ imageCount }) => (imageCount > 2 ? '2' : '1')};
    }
`;
const ViewAllButton = styled(TextButton)`
    &.MuiButton-text {
        position: absolute;
        bottom: 15px;
        right: 15px;
        padding: 0.63rem;
        background-color: white;
        border-radius: 4px;
    }
`;

const fadeIn = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

const GalleryImage = styled.div<{ src: string; singleImage?: boolean }>`
    width: ${({ singleImage }) => (singleImage ? 'auto' : '100%')};
    height: 100%;
    animation: ${fadeIn} 0.05s ease-in;
    flex-grow: 1;
    background-image: url(${props => props.src || 'none'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${({ singleImage }) => (singleImage ? 'contain' : 'cover')};
    ${props => (props.onClick ? `cursor: pointer;` : ``)}
`;

type TGalleryProps = {
    images: Bizly.VenueImage[];
};

const ImageGallery = ({ images }: TGalleryProps) => {
    const [validImages, setValidImages] = React.useState<Bizly.VenueImage[]>([]);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [imgIndex, setImgIndex] = React.useState(0);

    const openModalGallery = (imgIndex = 0) => {
        setImgIndex(imgIndex);
        setModalOpen(true);
    };

    useEffect(() => {
        const validateImages = async () => {
            const validImages = await getValidImages(images);
            setValidImages(validImages);
        };

        validateImages();
    }, [images]);

    return (
        <>
            <Container>
                <Gallery imageCount={validImages.length}>
                    {validImages && validImages.length === 1 ? (
                        <GalleryImage singleImage src={validImages[0]?.srcUrl} onClick={() => openModalGallery()} />
                    ) : (
                        <Left>
                            <GalleryImage src={validImages[0]?.srcUrl} onClick={() => openModalGallery()} />
                        </Left>
                    )}
                    {validImages.length > 1 && validImages.length < 5 && (
                        <Right>
                            {validImages.slice(1).map((image, index) => (
                                <GalleryImage
                                    key={image.id}
                                    src={image?.srcUrl}
                                    onClick={() => openModalGallery(index + 1)}
                                />
                            ))}
                        </Right>
                    )}
                    {validImages.length >= 5 && (
                        <>
                            <Right>
                                {validImages.slice(1, 3).map((image, index) => (
                                    <GalleryImage
                                        key={image.id}
                                        src={image.srcUrl}
                                        onClick={() => openModalGallery(index + 1)}
                                    />
                                ))}
                            </Right>
                            <Right>
                                {validImages.slice(3, 5).map((image, index) => (
                                    <GalleryImage
                                        key={image.id}
                                        src={image.srcUrl}
                                        onClick={() => openModalGallery(index + 3)}
                                    />
                                ))}
                            </Right>
                        </>
                    )}
                </Gallery>
                {validImages.length > 5 && (
                    <ViewAllButton onClick={() => openModalGallery()}>
                        <CameraImg />
                        {i18n.venue.viewAllPhotos(validImages.length)}
                    </ViewAllButton>
                )}
            </Container>
            <GalleryModal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
                <SwipeableImageGallery images={validImages} startingIndex={imgIndex} />
            </GalleryModal>
        </>
    );
};

export default ImageGallery;
