import { zodResolver } from '@hookform/resolvers/zod';
import InputField from 'components/BizlyOS/InputFields/InputField';
import ClaimStepContainer from 'components/BizlyOS/ListingsClaim/ClaimStepContainer';
import StepItemsContainer from 'components/BizlyOS/ListingsClaim/StepItemsContainer';
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { ListingsCreateStepProps } from './ListingsCreate';

const Schema = z.object({
    venueName: z.string().min(1, 'Venue Name is required'),
});

type Data = z.infer<typeof Schema>;

export default function VenueName({ onNextStepClicked, listingsCreateData, ...props }: ListingsCreateStepProps) {
    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm<Data>({
        resolver: zodResolver(Schema),
        defaultValues: {
            venueName: listingsCreateData.venueName || '',
        },
        mode: 'onChange',
    });

    const onSubmit = (data: Data) => {
        onNextStepClicked(data);
    };

    return (
        <ClaimStepContainer
            {...props}
            onSave={handleSubmit(onSubmit)}
            disabled={!isValid || isSubmitting}
            contentCentered
        >
            <StepItemsContainer title="What’s your venue’s name?" description="This is how it will appear on Bizly.">
                <Controller
                    name="venueName"
                    control={control}
                    render={({ field }) => {
                        return <InputField {...field} error={errors.venueName} label="Venue Name" required />;
                    }}
                />
            </StepItemsContainer>
        </ClaimStepContainer>
    );
}
