import { ParcelDates } from 'components/RSVP/EventRegistrationCard';
import { i18n } from 'translation';
import { timeOptions } from 'utils/date_util';
import { tzMoment } from 'utils/moment';

export const defaultEditParcelFields = ({ parcelStartDate }: { parcelStartDate: Date | null }) => ({
    startDate: {
        prompt: i18n.datetime.startDate,
        type: 'date_outlined',
        options: {
            minDate: Date.now(),
            placeholder: i18n.datetime.date,
        },
    },
    endDate: {
        prompt: i18n.datetime.endDate,
        type: 'date_outlined',
        options: {
            minDate: parcelStartDate || Date.now(),
            placeholder: i18n.datetime.date,
        },
    },
    startTime: {
        prompt: i18n.datetime.startTime,
        type: 'select',
        optional: true,
        options: {
            options: timeOptions,
            placeholder: i18n.datetime.startTime,
            autoFocusOptionKey: '12:00:00',
        },
    },
    endTime: {
        prompt: i18n.datetime.endTime,
        type: 'select',
        optional: true,
        options: {
            options: timeOptions,
            placeholder: i18n.datetime.endTime,
            autoFocusOptionKey: '12:00:00',
        },
    },
});

export const editParcelSchema = [
    {
        key: 'startDateTime',
        fields: ['startDate', 'startTime'],
        spacing: 'medium',
    },
    {
        key: 'endDateTime',
        fields: ['endDate', 'endTime'],
        spacing: false,
    },
];

export const editParcelSchemaWithTz = [
    {
        key: 'startDateTime',
        fields: ['startDate', 'endDate'],
        spacing: 'medium',
    },
    {
        key: 'endDateTime',
        fields: ['startTime', 'endTime'],
        spacing: false,
    },
];

export const editParcelSchemaTimeOnly = [
    {
        fields: ['startTime', 'endTime'],
        spacing: false,
    },
];

export const roomTypeOptions = [
    {
        id: 'single',
        label: i18n.meetingDashboard.roomBlockSection.single,
    },
    {
        id: 'double',
        label: i18n.meetingDashboard.roomBlockSection.double,
    },
    {
        id: 'suite',
        label: i18n.meetingDashboard.roomBlockSection.suite,
    },
    {
        id: 'handicapAccessible',
        label: i18n.meetingDashboard.roomBlockSection.handicapAccessible,
    },
];

export const roomBlockFields = (datesFilled: boolean, parcelDates?: ParcelDates, notesDisabled?: boolean) => {
    const dateOptions = parcelDates
        ? {
              minDate: tzMoment(parcelDates?.startDate).subtract(3, 'day'),
              maxDate: tzMoment(parcelDates?.endDate).add(3, 'day'),
          }
        : undefined;
    return {
        checkInDate: {
            prompt: i18n.datetime.checkInDate,
            type: 'date_outlined',
            optional: true,
            options: dateOptions,
        },
        checkOutDate: {
            prompt: i18n.datetime.checkOutDate,
            type: 'date_outlined',
            optional: true,
            options: dateOptions,
        },
        preferredRoomType: {
            prompt: i18n.meetingDashboard.roomBlockSection.roomTypeLabel,
            placeholder: i18n.meetingDashboard.roomBlockSection.roomTypePlaceholder,
            type: 'select',
            options: {
                options: roomTypeOptions,
            },
            optional: !datesFilled,
        },
        rsvpComments: {
            prompt: i18n.meetingDashboard.roomBlockSection.additionalNotes,
            placeholder: i18n.meetingDashboard.roomBlockSection.notesPlaceholder,
            type: 'rich_text',
            optional: true,
            disabled: !!notesDisabled,
        },
    };
};

export const roomBlockSchema = [
    {
        key: 'checkInAndOut',
        fields: ['checkInDate', 'checkOutDate'],
        spacing: 'none',
    },
    {
        key: 'preferredRoomType',
        fields: ['preferredRoomType'],
        spacing: 'default',
    },
    {
        key: 'rsvpComments',
        fields: ['rsvpComments'],
        spacing: 'small',
    },
];

export const rsvpDueFields = {
    rsvpDueDate: {
        prompt: i18n.meetingDashboard.roomBlockSection.respondDeadlineLabel,
        type: 'date_outlined',
        options: {
            placeholder: i18n.datetime.date,
        },
        optional: true,
    },
    rsvpDueTime: {
        // Use a non-breaking space as a hack to make the prompt align with the date field
        prompt: '\u00A0',
        type: 'select',
        options: {
            placeholder: i18n.common.time,
            options: timeOptions,
            autoFocusOptionKey: '12:00:00',
        },
        optional: true,
    },
    postCutoffContact: {
        prompt: i18n.meetingDashboard.roomBlockSection.postCutoffContact,
        type: 'text',
        readonly: true,
    },
};

export const rsvpDueSchema = [
    {
        key: 'rsvpDue',
        fields: ['rsvpDueDate', 'rsvpDueTime'],
        spacing: 'none',
    },
    {
        key: 'postCutoffContact',
        fields: ['postCutoffContact'],
        spacing: 'none',
    },
];
