import { Alert, Fade, Grow, Tooltip, Typography } from '@mui/material';

import { Close } from '@material-ui/icons';
import { InfoOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { AreaUnit } from 'stores/measurement-units';
import { i18n } from 'translation';
import {
    StyledDaysContainer,
    StyledFooterContainer,
    StyledFooterDetails,
    StyledGridHeader,
    StyledGridHeaderContainer,
    StyledRemoveButtonWrapper,
    StyledTileInfo,
    StyledVenueBudgetLabel,
    StyledVenueImageDetails,
} from './CompareModalStyledComponents';
import CompareOnrivaTotals from './CompareOnrivaTotals';
import DayRows from './DayRows';
import { calculateBudgetStatus, getTotalDisplay } from './compareUtils';

type CollapsibleTableProps = {
    commonByDay: Bizly.VenueProposal['byDay'];
    proposalColumns: Bizly.VenueProposal[];
    areaUnit: AreaUnit;
    isSticky?: boolean;
    inquiryVenues: Bizly.Venue[];
    user: Bizly.User;
    eventBudget?: string;
};

function CollapsibleTable({
    commonByDay,
    proposalColumns,
    areaUnit,
    inquiryVenues,
    user,
    eventBudget,
    isSticky,
}: CollapsibleTableProps) {
    //commonByDay just gets the first proposal [0] number of days so we can create how many rows of days

    const venueObjectById = Object.fromEntries(inquiryVenues.map((venue: Bizly.Venue) => [venue?.id, venue]));
    const isOnriva = !!user.team?.sourceSite;

    const [visibleProposalColumns, setVisibleProposalColumns] = useState<boolean[]>(proposalColumns.map(() => true));

    const handleRemoveVenueColumn = (key: number) => {
        const visibleProposalsCount = visibleProposalColumns.filter(Boolean).length;
        if (visibleProposalsCount === 1) {
            return;
        }
        setVisibleProposalColumns(prev => {
            const newVisibleColumns = [...prev];
            newVisibleColumns[key] = false;
            return newVisibleColumns;
        });
    };

    const renderProposalImageHeaders = (proposalColumns: Bizly.VenueProposal[]) => {
        const visibleProposalColumnsCount = visibleProposalColumns.filter(Boolean).length;
        const showRemoveButton = visibleProposalColumnsCount > 1;

        return Array.from({ length: 4 }, (_, key) => {
            const content = (
                <StyledGridHeaderContainer key={key}>
                    {proposalColumns[key] ? (
                        <StyledVenueImageDetails>
                            {showRemoveButton && (
                                <StyledRemoveButtonWrapper onClick={() => handleRemoveVenueColumn(key)}>
                                    <Close fontSize="small" />
                                </StyledRemoveButtonWrapper>
                            )}
                            <StyledTileInfo
                                venue={venueObjectById[proposalColumns[key].venue?.id]}
                                pillType="inquiryStatus"
                            />
                            <img src={proposalColumns[key]?.venue?.imageUrl} />
                            <Typography component="p">{proposalColumns[key].venue?.name}</Typography>
                        </StyledVenueImageDetails>
                    ) : (
                        <Box /> // Empty Box for padding
                    )}
                </StyledGridHeaderContainer>
            );
            return isSticky ? (
                <Fade in={isSticky} timeout={500}>
                    {content}
                </Fade>
            ) : (
                <Grow in={!isSticky} timeout={500}>
                    {content}
                </Grow>
            );
        });
    };

    useEffect(() => {
        const preloadImages = proposalColumns.map(proposal => proposal.venue?.imageUrl).filter(Boolean);
        preloadImages.forEach(imageUrl => {
            const img = new Image();
            img.src = imageUrl;
        });
    }, [proposalColumns]);

    const getVisibleProposalColumns = (proposalColumns: Bizly.VenueProposal[], visibility: boolean[]) => {
        return proposalColumns.filter((_, index) => visibility[index]);
    };

    const ensureAtLeastOneColumn = (columns: Bizly.VenueProposal[]) => {
        if (columns.length === 0) {
            return [proposalColumns[0]];
        }
        return columns;
    };

    const visibleProposalColumnsToRender = getVisibleProposalColumns(proposalColumns, visibleProposalColumns);
    const finalProposalColumnsToRender = ensureAtLeastOneColumn(visibleProposalColumnsToRender);

    return (
        <>
            <StyledGridHeader>
                <StyledGridHeaderContainer />
                <StyledGridHeaderContainer />
                {renderProposalImageHeaders(finalProposalColumnsToRender)}
            </StyledGridHeader>
            <StyledDaysContainer>
                {commonByDay?.map((day, idx) => (
                    <DayRows
                        areaUnit={areaUnit}
                        key={idx}
                        proposalColumns={finalProposalColumnsToRender}
                        day={day}
                        dayNumber={idx + 1}
                    />
                ))}
            </StyledDaysContainer>
            <StyledFooterContainer>
                {isOnriva && <CompareOnrivaTotals proposalColumns={finalProposalColumnsToRender} />}
                <StyledFooterDetails>
                    <Typography fontSize={14} fontWeight={500}>
                        Proposal Total
                    </Typography>
                    {finalProposalColumnsToRender.map((proposal, key) => {
                        const { isAboveBudget } = calculateBudgetStatus(proposal, user, eventBudget);
                        const { totalProposal } = getTotalDisplay(proposal, isOnriva);
                        return (
                            <Box key={key}>
                                <StyledVenueBudgetLabel aboveBudget={isAboveBudget}>
                                    {totalProposal}
                                    {isAboveBudget && (
                                        <Tooltip
                                            sx={{ zIndex: 1 }}
                                            placement="top"
                                            title={i18n.venue.inquiry.aboveBudgetTooltip}
                                            arrow
                                        >
                                            <InfoOutlined fontSize="small" />
                                        </Tooltip>
                                    )}
                                </StyledVenueBudgetLabel>
                            </Box>
                        );
                    })}
                </StyledFooterDetails>
                <Box>
                    <Alert severity="info">
                        Proposal total does not calculate taxes and fees which the venue will include in their final
                        invoice.
                    </Alert>
                </Box>
            </StyledFooterContainer>
        </>
    );
}

export default CollapsibleTable;
