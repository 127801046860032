import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logOutUser } from 'api';
import { useUser } from 'providers/user';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { removeCookie } from 'utils/tokens';

const UserMenuBox = styled(Box)`
    padding: 10px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
`;

const StyledMoreIcon = styled(MoreVertIcon)`
    cursor: pointer;
`;

const StyledMenu = styled(Menu)`
    .MuiBackdrop-root {
        opacity: 0 !important;
    }
    .MuiList-root {
        padding: 8px 16px;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    &.MuiButtonBase-root {
        display: flex;
        padding: 6px 0;
        justify-content: flex-start;
    }
`;

function UserMenu({ minimized }: { minimized?: boolean }) {
    const { toggles, user, clearUser, isTeamAdmin } = useUser();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

    function handleClick(event: React.MouseEvent<SVGSVGElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    async function handleSignOut() {
        if (user?.id) {
            await logOutUser(user.id);
            removeCookie();
            clearUser();

            navigate('/sign-in');
        }
    }

    return (
        <UserMenuBox display="flex" justifyContent="space-between" alignItems="center">
            {!minimized && <span>{i18n.userMenu.settings}</span>}
            <div>
                <StyledMoreIcon
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                />
                <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableAutoFocusItem
                >
                    <StyledMenuItem onClick={() => navigate('/profile')}>{i18n.userMenu.myProfile}</StyledMenuItem>
                    {toggles.gate.integrations && (
                        <StyledMenuItem onClick={() => navigate('/integrations')}>
                            {i18n.userMenu.integrations}
                        </StyledMenuItem>
                    )}
                    <StyledMenuItem onClick={() => navigate('/approvals')}>{i18n.userMenu.approvals}</StyledMenuItem>
                    {isTeamAdmin && (
                        <StyledMenuItem onClick={() => navigate('/settings/team')}>
                            {i18n.userMenu.teamSettings}
                        </StyledMenuItem>
                    )}
                    <StyledMenuItem onClick={handleSignOut}>{i18n.userMenu.signOut}</StyledMenuItem>
                </StyledMenu>
            </div>
        </UserMenuBox>
    );
}

export default UserMenu;
